@import 'scss/variables.scss';

.share_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //z-index: 100;


  .logo2 {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }

  .share_container_content {
    width: 74vw;
    height: 36 *$rem;
    background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
    border-radius: 12px;
    border: 1px solid #5AF8AB;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      font-size: 1.5 *$rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #8EF4B1;
      margin-top: 2.7 *$rem;
    }

    .logo {
      width: 9.6 *$rem;
      height: 9.6 *$rem;
      margin-top: 1.75 *$rem;
      border-radius: 1 *$rem;
    }

    .logo1 {
      width: 100%;
      height: 100%;
    }

    .logo2 {
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }

    .content {
      width: 82%;
      font-size: 1.1 *$rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8EF4B1;
      line-height: 1.6 *$rem;
      letter-spacing: 1px;
      margin-top: 10px;
    }

    .sure_btn_father {
      width: 12 *$rem;
      height: 3 *$rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 1.5 *$rem;

      img {
        width: 11 *$rem;
        height: 3.9 *$rem;

      }

      .sure_btn_txt {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        //background-color: red;
        margin-top: -0.1 *$rem;

        span {
          font-size: 1.6 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
          color: #081C0D;
        }
      }
    }
  }
}




