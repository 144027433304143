@import 'scss/variables.scss';

.share_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .share_container_content {
    width: 74vw;
    height: 24 *$rem;
    background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
    border-radius: 12px;
    border: 1px solid #5AF8AB;
    display: flex;
    flex-direction: column;
    align-items: center;

    .img_logo {
      width: 8.6 *$rem;
      height: 8.3 *$rem;
      margin-top: 2.2 *$rem;
    }

    .little_title {
      width: 80%;
      height: 22%;
      margin-top: 1.1 *$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      //background-color: #2A2681;

      span {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        font-size: 1.1  *$rem;
        color: #8EF4B1;
        line-height: 22px;
      }
    }


    .cancel_sure_father {
      width: 80%;
      height: 3 *$rem;
      //background-color: red;
      margin-top: 2.1 *$rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .cancle_btn_father {
        width: 9 *$rem;
        height: 2.9 *$rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: yellow;

        img {

        }

        .text_btn_father {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          //background-color: red;
          margin-top: -0.1 *$rem;
        }

        .text_btn_cancel {
          font-size: 1.3 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
          color: #8EF4B1;
        }

        .text_btn_sure {
          font-size: 1.3 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
          color: #081C0D;
        }
      }
    }
  }
}




