@import 'scss/variables.scss';

.invite_friend_container {
  width: 100%;
  //background-color: red;
  height: 100%;
  overflow: hidden;
}

.list_item {
  width: 94vw;
  height: 6.5 *$rem;
  margin-left: 3vw;
  border-radius: 12px;
  background: #FFFFFF;
  //margin-top: 1 *$rem;
  display: flex;

  .list_item_left {
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 1.5 *$rem;

    .list_item_name {
      font-family: PingFangSC-Semibold;
      font-size: 1.4 *$rem;
      font-weight: normal;
      color: #333333;
    }

    .list_item_time {
      opacity: 0.5;
      font-family: PingFangSC-Regular;
      font-size: 1 *$rem;
      font-weight: normal;
      margin-top: 0.8 *$rem;
      color: #333333;
    }
  }

  .list_item_right {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 1.5 *$rem;
    justify-content: flex-end;

    .list_item_state_title {
      font-family: PingFangSC-Semibold;
      font-size: 1.5 *$rem;
      font-weight: normal;
      color: #9E45FF;
    }

    .list_item_state_des {
      font-family: PingFangSC-Regular;
      font-size: 1.2 *$rem;
      font-weight: normal;
      color: #9E45FF;
      position: absolute;
      bottom: 1 *$rem;
    }
  }
}


.cartoon_kai_mh {
  position: fixed;
  //right: 7%;
  bottom: 13vh;
  width: 100vw;
  //height: 6 *$rem;
  border-radius: 570px;
  opacity: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .cartoon_kai_mh_btn {
    width: 70%;
    height: 4 *$rem;
    border-radius: 570px;
    opacity: 1;
    background: linear-gradient(99deg, #FF7CFF 0%, #F821FB 105%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: SourceHanSansCN-Bold;
    font-size: 1.5 *$rem;
    font-weight: bold;
    color: #FFFFFF;
  }

  .cartoon_kai_mh_des {
    font-family: PingFangSC-Medium;
    font-size: 1.2 *$rem;
    font-weight: normal;
    line-height: 3 *$rem;
    color: #9E9E9E;
  }
}

