@import 'scss/variables.scss';

.cartoon_invite_rank_container {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(#AD72FF, #8934E5, #8934E5);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .cartoon_rank_list_com {
    width: 100vw;
    height: 110vw/(750/624);
    //background-color: red;
    overflow: hidden;
    position: relative;

    .cartoon_bg_img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .cartoon_rank_list_1_3_com {
      width: 80%;
      height: 11 *$rem;
      top: 10vh;
      left: 10%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      position: absolute;
      //background-color: yellow;

      .cartoon_rank_list_1_3_item {
        width: 7 *$rem;
        height: 90%;
        border-radius: 14px;
        background: #FF5EFF;
        //border: 5px solid #FF5EFF;
        padding: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        font-family: SourceHanSansCN-Bold;
        font-size: 1 *$rem;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;
        position: relative;

        img {
          border-radius: 10px;
          width: 98%;
          height: 66%;
          object-fit: contain;
          //background-color: yellow;
          margin-bottom: 0.3 *$rem;
        }

        > div {
          margin-top: 0.3 *$rem;
        }

        .list_item_img_pm_rank {
          position: absolute;
          width: 2.2 *$rem;
          height: 2 *$rem;
          object-fit: cover;
          top: 46%;
        }
      }

      .cartoon_rank_list_pm {
        position: absolute;
      }
    }

    .cartoon_rank_list_4_10_com {
      width: 56%;
      height: 11 *$rem;
      top: 19*$rem;
      left: 22%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      //z-index: 1000;
      position: absolute;
      //background-color: red;

      .cartoon_rank_list_4_10_item {
        width: 6.6 *$rem;
        height: 90%;
        border-radius: 14px;
        background: #FF5EFF;
        //border: 5px solid #FF5EFF;
        padding: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: center;
        font-family: SourceHanSansCN-Bold;
        font-size: 1 *$rem;
        font-weight: bold;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0em;
        color: #FFFFFF;

        img {
          border-radius: 10px;
          width: 98%;
          height: 60%;
          object-fit: contain;
          //background-color: yellow;
          margin-bottom: 0.3 *$rem;
        }

        > div {
          margin-top: 0.3*$rem;
        }
      }
    }
  }

  .cartoon_des {
    width: 90%;
    position: absolute;
    left: 5%;
    bottom: 0px;
    color:#FFE432;;
    font-family: SourceHanSansCN-Bold;
    font-size: 1.1 *$rem;
    font-weight: bold;
    line-height: 1.4 *$rem;
    text-align: center;
    white-space: pre-wrap;
  }

  .cartoon_list_com {
    width: 100%;
    flex: 1;
    padding-top: 0.5vh;
    margin-bottom: 10vh;
    overflow-x: hidden;
    //background-color: #9146EE;
    background: linear-gradient(#AD72FF, #8934E5, #8934E5);
    box-shadow: 0 0 3px -2px #AD72FF;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;


    .cartoon_quan {
      width: 96%;
      height: 1.4 *$rem;
    }

    .cartoon_header {
      width: 90%;
      height: 15%;
      background-color: #fff;
      position: relative;
      margin-top: -6px;

      span {
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        font-weight: normal;
        color: #000000;
        display: inline-block;
        opacity: 0.5;
      }

      .cartoon_header_pm {
        width: 4 *$rem;
        margin-left: 3 *$rem;

        margin-top: 2 *$rem;
      }

      .cartoon_header_yh {
        width: 13 *$rem;
        left: 8 *$rem;
        top: 2 *$rem;
        //background-color: red;
        position: absolute;
      }

      .cartoon_header_yq {
        width: 7 *$rem;
        position: absolute;
        right: 1 *$rem;
        top: 2 *$rem;
        text-align: center;
        //background-color: red;
      }
    }

    .list_item {
      width: 100%;
      height: 5 *$rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      //justify-content: space-around;
      position: relative;

      .list_item_img_pm {
        width: 2.2 *$rem;
        height: 2 *$rem;
        object-fit: cover;
        margin-left: 3 *$rem;
      }

      .list_item_img_pm_txt {
        width: 2.2 *$rem;
        height: 2 *$rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Oswald-Regular;
        font-size: 1.3 *$rem;
        font-weight: normal;
        color: #000000;
        margin-left: 3 *$rem;
        //background-color: red;
      }

      .img_tel_com {
        position: absolute;
        display: flex;
        align-items: center;
        //justify-content: space-between;
        flex-direction: row;
        //background-color: red;
        left: 8 *$rem;
        max-width: 15 *$rem;

        .list_item_img {
          width: 3 *$rem;
          height: 3 *$rem;
          object-fit: cover;
          border-radius: 50%;
        }

        > div {
          margin-left: 10px;
        }
      }

      .list_item_invited_num {
        width: 3 *$rem;
        font-family: SourceHanSansCN-Bold;
        font-size: 1.2 *$rem;
        font-weight: bold;
        text-align: right;
        color: #9E45FF;
        position: absolute;
        right: 3 *$rem;
        //background-color: red;
      }
    }

  }

  .cartoon_bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    //height: 8 *$rem;
    height: 10vh;
    background: linear-gradient(101deg, #650DC4 -1%, #A023FF 53%, #580DAA 100%);
    display: flex;
    flex-direction: row;
    align-items: center;


    img {
      width: 4 *$rem;
      height: 4 *$rem;
      object-fit: cover;
      border-radius: 50%;
      margin-left: 2 *$rem;
    }

    .cartoon_bottom_tel {
      display: inline-block;
      margin-left: 0.6 *$rem;
      font-size: 1.2 *$rem;
      color: white;
    }

    .cartoon_bottom_pm {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 13 *$rem;

      span {
        display: inline-block;
        color: #FFFFFF;
      }

      .cartoon_bottom_pm_count {


        font-family: SourceHanSansCN-Bold;
        font-size: 1.5 *$rem;
        font-weight: bold;
        text-align: right;
        color: #FFE432;
        padding: 4px 0;
      }
    }

    .cartoon_bottom_yq {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 2 *$rem;

      span {
        display: inline-block;
        color: #FFFFFF;
      }

      .cartoon_bottom_pm_count {
        font-family: SourceHanSansCN-Bold;
        font-size: 1.5 *$rem;
        font-weight: bold;
        text-align: right;
        color: #FFE432;
        padding: 4px 0;
      }
    }
  }
}

.header_container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background: transparent;
  padding-top: 1.8 *$rem;

  .left {
    flex: 1;
    padding-left: 1.5 *$rem;

    img {
      width: 1.7*$rem;
      height: 1.7*$rem;
    }
  }

  .middle {
    flex: 1;
    text-align: center;
    font-family: PingFangSC-Semibold;
    font-size: 1.6 *$rem;
    font-weight: normal;
    color: #FFFFFF;
  }

  .right {
    flex: 1;
    padding-right: 1.5 *$rem;
    text-align: right;
    font-size: 1.4*$rem;
    font-family: PingFangSC-Regular;
    letter-spacing: 0px;
    color: #333333;
  }
}
