@import 'scss/variables.scss';

.world-cup-tab {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2.9*$rem 0 0.45*$rem;
  width: 100%;
  font-size: 1.6*$rem;
  color: #000000;
  background: rgba(246, 246, 246, 1);
  z-index: 999;
  .tab-item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1.5*$rem;
    .active {
      position: absolute;
      top: 1.8*$rem;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 0.45*$rem;
      width: 5.3*$rem;
      height: 0.55*$rem;
      background: #3662EC;
      border-radius: 0.275*$rem;
    }
  }
}