@import 'scss/variables.scss';

.drawWraps {
  background: url('./images/lottery.png') center center no-repeat;
  background-size: contain;
  height: 791 / 20*$rem;
  width: 100%;
  margin: 80 / 20*$rem 45/20*$rem 0 45/20*$rem;
  position: relative;

  &.light {
    .circle {
      &:nth-child(even) {
        background-color: #FFDBBC;
      }

      &:nth-child(odd) {
        background-color: #F5CD44;
      }
    }
  }

  .circle {
    display: inline-block;
    box-shadow: 0 $rem*0.1 $rem*0.15 0 rgba(163, 65, 0, 0.30);
    width: $rem*1;
    height: $rem*1;
    border-radius: 50%;
    position: absolute;
    margin-top: 0.05* $rem;

    &:nth-child(odd) {
      background-color: #FFDBBC;
    }

    &:nth-child(even) {
      background-color: #F5CD44;
    }

    &:nth-child(1) {
      top: 1.6*$rem;
      left: 4.9*$rem;
    }

    &:nth-child(2) {
      top: 1.6*$rem;
      left: 4.2*$rem+3.5*$rem;
    }

    &:nth-child(3) {
      top: 1.6*$rem;
      left: 4.2*$rem+2*3.5*$rem;
      display: none;
    }

    &:nth-child(4) {
      top: 1.6*$rem;
      left: 4.2*$rem+3*3.5*$rem;
      display: none;
    }

    &:nth-child(5) {
      top: 1.6*$rem;
      left: 4.2*$rem+4*3.5*$rem;
      display: none;
    }

    &:nth-child(6) {
      top: 1.6*$rem;
      left: 4.2*$rem+5*3.5*$rem;
      display: none;
    }

    &:nth-child(7) {
      top: 1.6*$rem;
      left: 4.2*$rem+6*3.5*$rem;
      display: none;
    }

    &:nth-child(8) {
      top: 1.6*$rem;
      left: 4.2*$rem+7*3.5*$rem;
    }

    &:nth-child(9) {
      top: 1.6*$rem;
      left: 4.2*$rem+8*3.5*$rem;
    }

    &:nth-child(10) {
      top: 4.2*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(11) {
      top: 4.2*$rem+3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(12) {
      top: 4.2*$rem+2*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(13) {
      top: 4.2*$rem+3*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(14) {
      top: 4.2*$rem+4*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(15) {
      top: 4.2*$rem+5*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(16) {
      top: 4.2*$rem+6*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(17) {
      top: 4.2*$rem+7*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(18) {
      top: 4.2*$rem+8*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(19) {
      top: 4.2*$rem+9*3.5*$rem;
      right: 2.5*$rem;
    }

    &:nth-child(20) {
      top: 38.2*$rem;
      left: 4.2*$rem+8*3.5*$rem;
    }

    &:nth-child(21) {
      top: 38.2*$rem;
      left: 4.2*$rem+7*3.5*$rem;
    }

    &:nth-child(22) {
      top: 38.2*$rem;
      left: 4.2*$rem+6*3.5*$rem;
    }

    &:nth-child(23) {
      top: 38.2*$rem;
      left: 4.2*$rem+5*3.5*$rem;
    }

    &:nth-child(24) {
      top: 38.2*$rem;
      left: 4.2*$rem+4*3.5*$rem;
    }

    &:nth-child(25) {
      top: 38.2*$rem;
      left: 4.2*$rem+3*3.5*$rem;
    }

    &:nth-child(26) {
      top: 38.2*$rem;
      left: 4.2*$rem+2*3.5*$rem;
    }

    &:nth-child(27) {
      top: 38.2*$rem;
      left: 4.2*$rem+1*3.5*$rem;
    }

    &:nth-child(28) {
      top: 38.2*$rem;
      left: 4.2*$rem+0*3.5*$rem;
    }

    &:nth-child(29) {
      top: 4.2*$rem+9*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(30) {
      top: 4.2*$rem+8*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(31) {
      top: 4.2*$rem+7*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(32) {
      top: 4.2*$rem+6*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(33) {
      top: 4.2*$rem+5*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(34) {
      top: 4.2*$rem+4*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(35) {
      top: 4.2*$rem+3*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(36) {
      top: 4.2*$rem+2*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(37) {
      top: 4.2*$rem+1*3.5*$rem;
      left: 2.5*$rem;
    }

    &:nth-child(38) {
      top: 4.2*$rem+0*3.5*$rem;
      left: 2.5*$rem;
    }
  }

  .drawHeader {
    height: 91 / 20*$rem;
    margin: 72 / 20*$rem 90/ 20*$rem 0 90/ 20*$rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    .drawTitle {
      text-align: center;
      line-height: 43 / 20*$rem;
      font-size: 30 / 20*$rem;
      color: #960002;
      font-weight: 700;
      margin-top: 8 / 20*$rem;

      span {
        color: #FF0003;
      }
    }

    .drawSubTitle {
      text-align: center;
      line-height: 34 / 20*$rem;
      font-size: 24 / 20*$rem;
      color: #960002;
      font-weight: 600;
      margin-top: 1 / 20*$rem;

      span {
        color: #FF0003;
      }
    }
  }


  .drawBorder {
    margin: 16 / 20*$rem 76/ 20*$rem 0 76/ 20*$rem;
    padding: 0 16 / 20*$rem 0 16 / 20*$rem;
    display: flex;
    flex-wrap: wrap;
    align-items: space-between;
    justify-content: space-between;
    position: relative;



    .drawMask {
      position: absolute;
      top: 0;
      left: 16 / 20*$rem;
      right: 16 / 20*$rem;
      bottom: 12 / 20*$rem;
      background: rgba(0, 0, 0, 0.5);
      z-index: 3;
      border-radius: $rem;
    }

    .drawStart {
      width: 180 / 20*$rem;
      height: 180 / 20*$rem;
      margin-bottom: 12 / 20*$rem;
      background-color: #D07C00;
      border-radius: $rem;

      .drawStartImg {
        background-color: #FFC500;
        padding: 30 / 20*$rem;
        height: 90%;
        width: 100%;
        border-radius: $rem;
      }
    }

    .drawButton {
      width: 180 / 20*$rem;
      height: 180 / 20*$rem;
      margin-bottom: 12 / 20*$rem;
      background-color: #F0D1C5;
      border-radius: $rem;

      &.active {
        z-index: 4;
      }

      .drawButtonC {
        background-color: #FBF3EF;
        border-radius: $rem;
        height: 90%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;

        .drawButtonImg {
          margin-top: 12 / 20*$rem;
          width: 130 / 20*$rem;
          height: 110 / 20*$rem;
          object-fit: contain;
        }

        .drawButtonTitle {
          margin-top: 8 / 20*$rem;
          color: #960002;
          font-size: 24 / 20*$rem;
          text-align: center;
        }
      }

    }
  }
}