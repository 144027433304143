@import 'scss/variables.scss';

.invite_record_container {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #F1F0F5;

  .invite_record_header {
    width: 100%;
    //height: 7 *$rem;
    height: 10vh;
    //left: 15%;
    padding-left: 15%;
    padding-right: 15%;
    top: 0;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #F1F0F5;
    //background-color: purple;
    justify-content: space-between;
    z-index: 1;


    .heder_title_com {
      font-family: PingFangSC-Semibold;
      font-size: 1.8 *$rem;
      font-weight: normal;
      text-align: center;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .header_title_sel {
        font-size: 1.8 *$rem;
        font-weight: normal;
        text-align: center;
        color: #333333;
        opacity: 1;
      }

      .header_title_dis {
        font-size: 1.5 *$rem;
        font-weight: normal;
        text-align: center;
        color: #333333;
        opacity: 0.4;
      }

      .heder_title_line {
        width: 80%;
        height: 2px;
        border: 2px solid #9E45FF;
        border-radius: 1px;
        margin-top: 4px;
      }
    }
  }

  .cartoon_record_com {
    width: 100%;
    //height: 50 *$rem;
    //padding-top: 7 *$rem;
    //background-color: #00b578;
    position: relative;

    .cartoon_record_com_ph{
      width: 100%;
      //height: 7 *$rem;
      height: 10vh;

    }
  }
}
