@import 'scss/variables.scss';

.share_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .share_container_content {
    width: 74vw;
    min-height: 16 *$rem;
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      font-size: 1.5 *$rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-top: 2.7 *$rem;
    }

    .content {
      width: 86%;
      font-size: 1.4 *$rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 1.8 *$rem;
      opacity: 0.7;
      margin-top: 13px;
    }

    .sure_btn_txt {
      width: 12 *$rem;
      height: 3 *$rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 1.5 *$rem;
      border-radius: 408px;
      background: linear-gradient(90deg, #FEEED6 1%, #FBD093 100%);

      span {
        font-size: 1.5 *$rem;
        font-weight: 400;
        color: #960002;
      }
    }
  }
}