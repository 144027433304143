@import 'scss/variables.scss';

.activity_page {
  height: 100%;


  .imageContent {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      // position: absolute;
      // top: 0;
      // left: 0;
      // right: 0;
    }

    .btn_box {
      position:absolute;
      margin-top: 150  / 20*$rem;
      height: 90 / 20*$rem;
      width: 55%;
      background-color: #FFC500;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Medium;
      font-size: 2 *$rem;
      font-weight: normal;
      letter-spacing: 0em;
      text-align: center;
      border-radius: 62.5px;
      color: #960002;
    }
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
  }
}