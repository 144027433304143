@import 'scss/variables.scss';
.container{
  width: 100%;
  background: linear-gradient(180deg, #FF951F 0%, #FF4F2F 15%, #FF9238 32%, #FFAB1A 84%, #FFAC1A 100%);
  .textView{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 10 * $rem;
    width: 100%;
    .title{
      font-size: 1.8 * $rem;
      color: #FFF9E7;
      line-height: 2.5 * $rem;
    }
    .title_one{
      font-size: 1.2 * $rem;
      font-weight: 400;
      color: #FFF9E7;
      line-height: 1.5 * $rem;
      margin-top: 0.5 * $rem;
      opacity: 0.6;
    }
  }
  .tips{
    display: inline-flex;
    position: absolute;
    flex-direction: row;
    top: $rem;
    right: 0;
    width: 7.5 * $rem;
    height: 2 * $rem;
    background-color: rgba(255, 248, 229, 0.29);
    border-radius: $rem 0 0 $rem;
    p{
      font-size: 1.3 * $rem;
      font-weight: 400;
      color: #FFFFFF;
      margin: auto auto;
    }
  }
  .img{
    width: 100%;
  }
  .content{
    width: 100%;
    padding-left: 1 * $rem;
    padding-right: 1 * $rem;
    position: absolute;
    left: 0;
    top: 38.6 * $rem;
  }
  .column_one {
    width: 100%;
    background: #FFF9EA;
    box-shadow: 0 0.2 * $rem 1 * $rem 0 rgba(255, 84, 0, 0.6), 0 0 1.5 * $rem 0 rgba(255, 121, 0, 0.5);
    border-radius: 1 * $rem;
    margin-top: $rem;
    img{
      width: 100%;
      margin-top: 2 * $rem;
      margin-bottom: 2 * $rem;
    }
  }
  .slogan{
    margin-top: 6.5 * $rem;
  }
  .bottom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 7 * $rem;
    left: 0;
    bottom: 0;
    position: fixed;
    background-color: white;
    .button{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 32.5 * $rem;
      height: 4.5 * $rem;
      background: linear-gradient(180deg, #FFCD7A 0%, #FF9931 100%);
      box-shadow: 0px 0.3 * $rem 0.8 * $rem 0 #F2832A;
      border-radius: 2.25 * $rem;
      p{
        font-size: 1.8 * $rem;;
        font-weight: 600;
        color: #4C290A;
        line-height: 2.5 * $rem;
        letter-spacing: 0.1 * $rem;
        text-align: center;
      }
    }

  }
}
