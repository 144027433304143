@import 'scss/variables.scss';

.world-cup-price-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  
  .world-cup-price-modal {
    position: relative;
    background: #FFFFFF;
    width: 34*$rem;
    height: 54*$rem;
    border-radius: 2.2*$rem;
    .price-modal-title {
      padding: 2.3*$rem 0 4.75*$rem;
      font-size: 1.75*$rem;
      color: #3D3D3D;
      font-weight: bold;
      text-align: center;
      img {
        position: absolute;
        top: 1.7*$rem;
        right: 2*$rem;
        width: 3*$rem;
        height: 3*$rem;
      }
    }
    .price-modal-content {
      .price-first {
        .price-points {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          font-size: 1.2*$rem;
          color: #7B6201;
          width: 7.9*$rem;
          height: 2.5*$rem;
          background: #FFE68F;
          border-radius: 1.25*$rem;
          img {
            position: absolute;
            top: -3*$rem;
            left: 50%;
            transform: translateX(-50%);
            width: 5.3*$rem;
            height: 3.65*$rem;
          }
        }
        .first-price-content {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 0.5*$rem;
          font-size: 1.1*$rem;
          font-weight: bold;
          color: #767676;
          .img-bg  {
            width: 6*$rem;
            height: 6*$rem;
            margin-top: 1*$rem;
            background: #FFE68F;
            border-radius: 50%;
          }
          img {
            position: absolute;
            top: -2.5*$rem;
            left: 50%;
            transform: translateX(-50%);
            width: 14*$rem;
            height: 13*$rem;
          }
        }
      }
      .price-second-third {
        display: flex;
        justify-content: space-around;
        &.fouth-fifth {
          margin-top: 5*$rem;
        }
        .price-left {
          flex: 1;
          .price-left-top {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            color: #000000;
            background: #CDCDCD;
            font-size: 1.2*$rem;
            width: 7.9*$rem;
            height: 2.5*$rem;
            border-radius: 1.25*$rem;
            &.price-tag {
              background: #FCBA71;
              color: #633A0D;
            }
            img {
              position: absolute;
              top: -3*$rem;
              left: 50%;
              transform: translateX(-50%);
              width: 5.3*$rem;
              height: 3.65*$rem;
            }
          }
          .price-left-bottom {
            padding: 0 1.5*$rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.3*$rem;
            font-size: 0.9*$rem;
            color: #9E9E9E;
            span {
              text-align: center;
              padding: 0.3*$rem 0;
              line-height: 1.3*$rem;
            }
            .img-bg  {
              position: relative;
              width: 5*$rem;
              height: 5*$rem;
              margin-top: 1*$rem;
              background: #D8D8D8;
              border-radius: 50%;
              &.last-img-bg {
                background: #FADAB6;
              }
              img {
                position: absolute;
                left: 0;
                top: -1*$rem;
                width: 5*$rem;
                height: 5*$rem;
              }
            }
          }
        }
        .price-right {
          flex: 1;
          .price-right-top {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            color: #633A0D;
            background: #E8C79B;
            font-size: 1.2*$rem;
            width: 7.9*$rem;
            height: 2.5*$rem;
            border-radius: 1.25*$rem;
            &.price-tag {
              background: #FCBA71;
              color: #633A0D;
            }
            img {
              position: absolute;
              top: -3*$rem;
              left: 50%;
              transform: translateX(-50%);
              width: 5.3*$rem;
              height: 3.65*$rem;
            }
          }
          .price-right-bottom {
            padding: 0 1.5*$rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 0.3*$rem;
            font-size: 0.9*$rem;
            color: #9E9E9E;
            span {
              text-align: center;
              padding: 0.3*$rem 0;
              line-height: 1.3*$rem;
            }
            .last-price {
              margin-bottom: 1.3*$rem;
            }
            .img-bg  {
              position: relative;
              width: 5*$rem;
              height: 5*$rem;
              margin-top: 1*$rem;
              background: #E8C79B;
              border-radius: 50%;
              &.last-img-bg {
                background: #FADAB6;
              }
              img {
                position: absolute;
                left: 0;
                top: -1*$rem;
                width: 5*$rem;
                height: 5*$rem;
              }
            }
          }
        }
      }
    }
  }
}