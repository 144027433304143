@import 'scss/variables.scss';

$midBlod: 600;

.container {
  width: 100%;
  height: 100vh;
  position: relative;

  .back_btn {
    position: fixed;
    width: 1.75*$rem;
    height: 1.75*$rem;
    left: 2.4*$rem;
    top: 3.8*$rem;
    background: url(./img/goback.png) no-repeat;
    background-size: 100%;
    z-index: 10;
  }


  .pic_father {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
  }

  .pic {
    //position: absolute;
    //top: 0;
  }

  .top_father {
    position: absolute;
    width: 100%;
    height: 3.0*$rem;
    //background: yellow;
    top: 8.2*$rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;

    .top_father_left_rule {
      width: 8*$rem;
      height: 2.6*$rem;
      background: url(./img/icon_rule.png) no-repeat;
      //border-radius: 0px 27px 27px 0px;
      background-size: 100%;
    }


    .top_father_right_renzhen {
      //width: 8*$rem;
      padding: 0.5*$rem 0.6*$rem;
      height: 2.3*$rem;
      background: linear-gradient(317deg, #B478FF 0%, #BCC2FF 100%);
      border-radius: 27px 0px 0px 27px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      img {
        width: 2*$rem;
        height: 2*$rem;
      }

      span {
        color: white;
        font-size: 1.2*$rem;
        margin-left: 0.2*$rem;
      }
    }
  }

  .button_father_daojishi {
    position: fixed;
    overflow: hidden;
    left: 9%;
    width: 82%;
    height: 7*$rem;
    bottom: 2*$rem;
    background: url(./img/waic-daojishi.png) no-repeat;
    background-size: 100%;

    .button_father_daojishi1 {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      span {
        color: white;
        font-size: 2.0*$rem;
        font-weight: bold;
      }
    }
  }

  .button_father {
    position: fixed;
    overflow: hidden;
    left: 9%;
    width: 82%;
    bottom: 2*$rem;
  }

  .mask_rule {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;

    .content {
      width: 75.7%;
      background-color: #4E52FF;
      border-radius: 1.2*$rem;
      padding: 1.5*$rem;

      .title {
        font-size: 2.2*$rem;
        font-weight: bold;
        color: white;
        text-align: center;
        margin-bottom: 1.5*$rem;
      }

      .text {
        height: 34vh;
        overflow: scroll;
        font-size: 1.1*$rem;
        color: #FFFFFF;
        line-height: 1.6*$rem;
        white-space: pre-wrap;
      }

      .close {
        width: 65%;
        height: 4*$rem;
        margin: 2*$rem auto 0;
        background: url(./img/icon_kown.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .mask_rule_kown {
          font-size: 2.0*$rem;
          color: white;
          font-weight: bold;
        }
      }
    }
  }


}
