@import 'scss/variables.scss';

.container {
  background: #EBEBEB;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.container_content {
  margin: 16px;
  background-color: white;
  border-radius: 12px;
}

.inputGroup {
  padding: 0 2*$rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  height: 6 *$rem;
  position: relative;


  label {
    font-size: 1.4*$rem;
    color: $deepGray;
    line-height: 100%;
    display: inline-block;
  }

  .inputLine {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    margin-left: 1 *$rem;
    font-size: 1.4*$rem;

    input[type='text'],
    input[type='tel'] {
      //font-weight: bold;
      color: #3D3D3D;
      flex: 1;
      width: 1px;
      position: relative;
      z-index: 1;
      opacity: 1;

      &::placeholder {
        color: #3D3D3D;
        opacity: 0.5;
      }
    }
  }

  .line {
    width: 90%;
    height: 0.1 *$rem;
    background-color: #D8D8D8;
    position: absolute;
    opacity: 0.6;
    left: 5%;
    bottom: 0;
    //z-index: 1100;
  }
}

.adress_button_father {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3*$rem;
}

.adress_button {
  width: $rem*33.5;
  height: $rem*4.5;
  border-radius: $rem*2.25;
  font-size: 1.8*$rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #EBEBEB 1%, #CECECE 100%);
  color: #737373;
}

.adress_button_enable {
  width: $rem*33.5;
  height: $rem*4.5;
  border-radius: $rem*2.25;
  font-size: 1.8*$rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, #FEEED6 1%, #FBD093 100%);
  color: #960002;
}