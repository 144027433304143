@import 'scss/variables.scss';

.root_content {
  width: 100vw;
  height: 100vh;
}

.item {
  position: relative;
  margin-top: 1 *$rem;
}

.time {
  margin-left: 1.5 *$rem;
  padding-top: 1*$rem;
  font-family: PingFangSC-Medium;
  font-size: 2 *$rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #3D3D3D;
}

.item_box {
  position: relative;
  padding-bottom: 1*$rem;
}
.item_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.5 *$rem;
  margin-top: 20px;
}

.item_egg_time_icon {
  width: 1.6 *$rem;
  height: 1.6 *$rem;
}

.item_time {
  font-family: PingFangSC-Medium;
  font-size: 1.4 *$rem;
  font-weight: normal;
  letter-spacing: 0em;
  margin-left: 0.8 *$rem;
  color: #CAA846;
}

.item_container {
  width: 85vw;
  margin-left: 10vw;
  margin-top: 10px;
  padding-bottom: 1.2 *$rem;
  border-radius: 2.3 *$rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}

.item_logo {
  width: 100%;
  border-top-left-radius: 2.3 *$rem;
  border-top-right-radius: 2.3 *$rem;

}

.tag_father_view {
  //width: 90vw;

  height: 4 *$rem;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.tag {
  width: 9 *$rem;
  height: 2 *$rem;
  border-radius: 20px;
  background: #FCE9B0;
  font-family: PingFangSC-Regular;
  font-size: 1.1 *$rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #5E4713;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.title_0 {
  margin-top: 0.6 *$rem;
  margin-left: 10px;
  font-family: PingFangSC-Medium;
  font-size: 1.8 *$rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: rgba(61, 61, 61, 0.85);
}

.btn_state_father {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_state {
  width: 90%;
  height: 50px;
  margin-top: 1 *$rem;
  border-radius: 442px;
  background: #E9E9E9;
  font-family: PingFangSC-Medium;
  font-size: 1.8 *$rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #9B9B9B;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn_state0 { ///* 矩形 17 */
  width: 90%;
  height: 50px;
  margin-top: 1 *$rem;
  border-radius: 442px;
  background: #E9E9E9;
  font-family: PingFangSC-Medium;
  font-size: 1.8 *$rem;
  font-weight: normal;
  letter-spacing: 0em;
  color: #6F5B20;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(278deg, #FFEAB4 -1%, #D3BB74 99%);
}

.logo_father {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.3 *$rem;
  padding-bottom: 3 *$rem;
}

.logo {
  width: 15 *$rem;
  height: 2.5 *$rem;
}

.line {
  position: absolute;
  left: 6vw;
  width: 1px;
  top: 2%;
  height: 100%;
}
