@import 'scss/variables.scss';

.goddess-rules-modal {
  .goddess-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.7*$rem 2.4*$rem;
    width: 32*$rem;
    height: 40*$rem;
    background: linear-gradient(180deg, #FCF1DC 3%, #FFF7E8 97%);
    border-radius: 1*$rem;
    display: flex;
    flex-direction: column;

    .goddess-modal-title {
      padding-bottom: 1.65*$rem;
      color: #793802;
      font-size: 1.75*$rem;
      font-weight: bold;
      text-align: center;
      position: relative;
    }

    .goddess-modal-icon {
      position: absolute;
      top: 0;
      right: -1*$rem;
    }

    .goddess-modal-content {
      box-sizing: border-box;
      border-top: 1px dashed #793802;
      padding-top: 1.65*$rem;
      color: #793802;
      flex: 1;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow-y: auto;
      white-space: pre-line;
      line-height: 1.8*$rem;
      >.goddess-modal-outer{
        padding:1*$rem 0;
      }
      >div:not(:last-child){
        width: 25*$rem;
        margin:0 auto;
        border-bottom: 0.05*$rem solid #793802;

      }
      .goddess-modal-record {
        width: 25*$rem;
        text-align: center;
        font-size: 1.7*$rem;
        // font-weight: 600;
        margin-bottom: 1*$rem;

      }
      .goddess-modal-record-number {
        width: 25*$rem;
        margin: 0 auto;
        font-family: PingFang SC;
        font-size: 1.7*$rem;
        font-weight: normal;
        color: #793802;
        text-shadow: 0px 0px 8px rgba(255, 185, 174, 0.59);
        display: flex;
        flex-wrap: wrap;
        row-gap: 1.5*$rem;
        margin-bottom: 1*$rem;
        >div{
          width: 33%;
        }
      }
    }

    .goddess-rules-btn {
      margin: 1*$rem auto 0;
      width: 15.65*$rem;
      height: 4.1*$rem;
      border-radius: 4.1*$rem;
      background: #ED3B70;
      color: #FFFFFF;
      font-size: 1.8*$rem;
      line-height: 4.1*$rem;
      text-align: center;
    }
  }
}