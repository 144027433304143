@import 'scss/variables.scss';

.share_container {
  width: 100%;
  height: 200%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .share_body {
    width: 80vw;
    border-radius: 1 *$rem;
    margin-top: 10 *$rem;
    overflow: hidden;
    position: relative;

    .share_content {
      width: 100%;


      .share_avatar {
        overflow: hidden;
        padding: 0;
        background-color: red;

        img {
          //display: block;
          width: 100%;
          height: (80vw / (660/817));
          //height: 80vh;
        }
      }

      .share_detail {
        position: absolute;
        right: 5%;
        bottom: 3%;

        svg {
          background-color: #ffffff;
          border: 6px solid #fff;
          border-radius: 8px;
          float: right;
          width: 9 *$rem;
          height: 9 *$rem;
        }
      }
    }
  }

  .opt {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80vw;
    margin-top: 3.4 *$rem;
    //margin-bottom: 4vh;

    img {
      width: 4.6 *$rem;
      height: 4.6 *$rem;
    }


    .wechat,
    .share,
    .copy,
    .download {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.2 *$rem;
      color: #fff;

      span {
        margin-top: 0.8 *$rem;
      }

      //text-align: center;
    }

  }
}


.cancel_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 5 *$rem;
  overflow: hidden;
  margin-top: 5 *$rem;
  border-radius: 2.5 *$rem;
  //margin-bottom: 5vh;
  background-color: #262626;
  ;
  font-size: 1.6 *$rem;
  font-weight: bold;
  color: #C1C1C1;

}