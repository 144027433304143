@import 'scss/variables.scss';

.header {
    display: flex;
    align-items: center;
    height: 4.4*$rem;
    width: 100%;
}

.backToTop {
    position: fixed;
    width: $rem*5;
    height: $rem*5;
    right: 1.5*$rem;
    bottom: $rem*10;
    transition: opacity 0.5s;
    z-index: 50;
}

.navigator {
    position: fixed;
    top: 0;
    width: 100%;
    background: white;
    z-index: 100;
}

.container {
    // overflow-x: hidden;
    width: 100%;
    height: auto;
    background: #E8DEC7 url(./images/background@2x.png) repeat;


    .navigator {
        display: flex;
        align-items: center;

        .left {
            width: $rem*5;
            padding: 0 1.5*$rem;

            img {
                width: 0.7*$rem;
            }
        }

        .title {
            flex: 1;
            color: $deepGray;
            font-size: 1.9*$rem;
            font-weight: bold;
            text-align: center;
        }

        .right {
            padding: 0 1.65*$rem;

            img {
                width: 1.6*$rem;
            }
        }
    }

    .topBackground {
        background: url(./images/kv.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        width: 100%;
        height: 75 * $rem;
    }

    .topicShow {
        width: 100%;
        height: 68.6 * $rem;
        overflow: hidden;
        position: relative;
        z-index: 3;
    }

    .rulesButton {
        width: 100%;
        text-align: right;
        position: relative;
        z-index: 3;
    }

    .rulesImg {
        height: 2.5*$rem;
        width: 6.6*$rem;
        margin-top: $rem;
    }

    .contentHeader {
        margin-top: 2.7* $rem;
        text-align: center;
    }

    .contentHeadTitle {
        width: 27.9* $rem;
        height: 3.5 * $rem;
    }

    .subTitle {
        display: block;
        height: 2* $rem;
        margin-top: 1.1* $rem;
        line-height: 2*$rem;
        font-size: 1.7* $rem;
        color: #7E5B23;
        font-weight: bold;
        background: linear-gradient(0deg, #C61100 0%, #FB8241 70%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .tipText {
        display: block;
        color: #510B0B;
        line-height: 2.5* $rem;
        font-size: 1.2*$rem;
        font-weight: 400;
        margin-top: 0.9* $rem;
    }

    .contentHeader span:last-child {
        margin-top: 0;
    }

    .awardContent {
        width: 31.45* $rem;
        height: 26.6* $rem;
        margin-left: 3* $rem;
        background: url(./images/prizes.png) no-repeat;
        background-size: contain;
    }

    .activityTimeBox {
        font-weight: 400;
        color: #510B0B;
        line-height: 1.5* $rem;
        font-size: 1*$rem;
        width: 30* $rem;
        margin: 1.1* $rem auto 0;

        div {
            line-height: 2.5* $rem;
        }
    }

    .activityTime {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .bigBase {
        height: 53.4*$rem;
        width: 34.5* $rem;
        background-color: #FFFCF3;
        margin-left: 1.5* $rem;
        border: 0.1 * $rem solid #C6000D;
        border-radius: 0.4* $rem;
        position: relative;
    }

    .bigBaseHead {
        width: 100%;
        height: 6.5* $rem;
        background: url(./images/dajijian.png) no-repeat;
        background-size: 100% 100%;
        margin-top: -5* $rem;
    }

    .bigBaseFoot {
        display: flex;
        flex-direction: column;
        margin-left: $rem;
    }

    .bigBaseImage {
        height: 16* $rem;
        width: 32.5*$rem;
        margin-top: $rem;
    }

    .baseIntro {
        color: #510B0B;
        line-height: 2.1* $rem;
        letter-spacing: 2px;
        font-size: 1.4* $rem;
        margin-right: $rem;
        margin-top: $rem;
    }

    .GUIntro {
        height: 58.75*$rem;
        width: 34.5* $rem;
        background-color: #FFFCF3;
        margin-left: 1.5* $rem;
        border: 0.1 * $rem solid #C6000D;
        border-radius: 0.4* $rem;
        margin-top: 6.9* $rem;
    }

    .GUHead {
        width: 100%;
        height: 6.5* $rem;
        background: url(./images/lvdiGyou.png) no-repeat;
        background-size: 100% 100%;
        margin-top: -5* $rem;
    }

    .GUImage {
        height: 17* $rem;
        width: 32.5*$rem;
        margin-top: $rem;
    }

    .videoWrap {
        width: 34.5*$rem;
        margin: 2*$rem auto 1.25*$rem;
        height: $rem*19.4;

        video {
            width: 100%;
            height: 100%;
        }
    }

    .partyHistory {
        width: 100%;
        height: 115* $rem;
        overflow-x: hidden;
        background: url(./images/historyBg.png) no-repeat;
        background-size: 100% 100%;
    }

    .firstStageWrap {
        margin-left: 15.4* $rem;
        color: #C11916;
        letter-spacing: 0.1* $rem;
        font-size: 1.4* $rem;
        transform: rotate(3deg);

        div {
            line-height: 2.1* $rem;
        }
    }

    .firstStageWrap div:first-child {
        padding-top: 15.6*$rem;
        margin-left: 1.1* $rem;
    }

    .firstStageWrap div:last-child {
        padding-top: 1.1*$rem;
    }

    .firstStageOne {
        margin-left: 15.5* $rem;
        color: #510B0B;
        font-size: 1.3* $rem;
        width: 20* $rem;
    }

    .firstStageOne div:first-child {
        margin-bottom: 1.5*$rem;
        font-weight: bold;
        margin-top: $rem;
    }

    .firstStageOne div:last-child {
        line-height: 2* $rem;
    }

    .firstStageBlock {
        margin-top: 2.7* $rem;
    }

    .twoStageWrap {
        margin-left: 6.65* $rem;
        color: #C11916;
        letter-spacing: 0.1* $rem;
        font-size: 1.4* $rem;
        transform: rotate(-6deg);

        div {
            line-height: 2.1* $rem;
        }
    }

    .twoStageWrap div:first-child {
        padding-top: 0.7*$rem;
        margin-left: 2.1* $rem;
    }

    .twoStageWrap div:last-child {
        padding-top: 1*$rem;
    }

    .twoStageOne {
        margin-left: 2.65* $rem;
        color: #510B0B;
        font-size: 1.3* $rem;
        width: 20* $rem;
        margin-top: 2.7* $rem;

        div {
            line-height: 2* $rem;
        }
    }

    .twoStageOne div:first-child {
        margin-bottom: 1*$rem;
        font-weight: bold;
        margin-top: $rem;
    }

    .twoStageBlock {
        margin-top: 4.5* $rem;
    }

    .threeStageWrap {
        margin-left: 6.65* $rem;
        color: #C11916;
        letter-spacing: 0.1* $rem;
        font-size: 1.4* $rem;
        transform: rotate(6deg);
        margin-top: 3.1*$rem;

        div {
            line-height: 2.1* $rem;
        }
    }

    .threeStageWrap div:first-child {
        padding-top: 0.7*$rem;
        margin-left: 2.1* $rem;
    }

    .threeStageWrap div:last-child {
        padding-top: 1*$rem;
    }

    .threeStageOne {
        margin-left: 15.5* $rem;
        color: #510B0B;
        font-size: 1.3* $rem;
        width: 20* $rem;
        margin-top: 1.1* $rem;

        div {
            line-height: 2* $rem;
        }
    }

    .threeStageOne div:first-child {
        margin-bottom: 1*$rem;
        font-weight: bold;
        margin-top: $rem;
    }

    .threeStageBlock {
        margin-top: 2.5* $rem;
    }

}


//活动规则
.rulesContainer {
    height: 100%;
    overflow-y: auto;
    background: #E8DEC7 url(./images/background@2x.png) repeat;

    .ruleContent {
        padding: 1.5* $rem 1.5* $rem 16.1* $rem 1.5* $rem;
        font-size: 1.3* $rem;
        font-weight: 400;
        color: #000;
    }

    .ruleContent p {
        line-height: 2 * $rem;
        margin-bottom: 0.7*$rem;
    }
}

.bottom {
    .finalPrizeImage {
        width: $rem*22;
        margin: 2.4*$rem 0 -2*$rem $rem*12;
    }

    h2 {
        text-align: center;
        line-height: 3*$rem;
        font-size: 2*$rem;
        font-weight: bold;
        color: #7E5B23;
        margin-bottom: $rem*2;
    }
}

.blessWrapper {
    padding: 0 1.5*$rem;
    margin-top: 4*$rem;


    .blessHead {
        width: 100%;
        height: 6.5* $rem;
        background: url(./images/lvdiGyou@2x.png) no-repeat;
        background-size: 100% 100%;
        margin-top: -6.1* $rem;
    }

    .select,
    input[type='text'] {
        border: 0.1 * $rem solid #C6000D;
        border-radius: 0.4* $rem;
        padding: $rem;
        width: 34.5* $rem;
        display: block;
        margin-top: $rem;
        position: relative;
        color: #510B0B;
        font-size: 1.3*$rem;
        background: transparent;


        &::placeholder {
            color: #999999;
        }
    }

    textarea {
        line-height: $rem*2.1;
        color: #510B0B;
        font-size: 1.3*$rem;
        display: block;
        flex: 1;

        &::placeholder {
            color: #999999;
        }
    }

    .select {
        position: relative;

        &:after {
            content: '';
            display: block;
            position: absolute;
            right: 0.2*$rem;
            bottom: 0.4*$rem;
            width: $rem*2.5;
            height: 2.5*$rem;
            background: url(./images/icon-down-arr.png) no-repeat;
            background-size: contain;
        }
    }


    .toSend {
        border: 0.1 * $rem solid #C6000D;
        border-radius: 0.4* $rem;
        padding: $rem;
        width: 34.5* $rem;
        display: block;
        margin-top: $rem;
        background: transparent;
        display: flex;
        flex-direction: row;
    }

    .flowerWrap {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .sendFlower {
        width: 2* 2.5 * $rem;
        height: 2* 2.5 * $rem;
        background: url(./images/flower@2x.png) no-repeat;
        background-position-x: 0;
        background-position-y: center;
        background-size: 2* 72.5* $rem 2* 2.5* $rem;
        cursor: pointer;
        transition: background-position 1s steps(28);
        transition-duration: 0s;
    }

    .sendFlower.isActive {
        transition-duration: 1s;
        background-position: 2* -70* $rem center;
    }

    .flowerButtonGray {
        filter: Gray;
        -webkit-filter: grayscale(100%);
    }

    .flowerText {
        line-height: 1.5* $rem;
        color: #4C290A;
    }

    .inputWrap {
        margin-top: $rem;
        margin-bottom: 2*$rem;
        width: 34.5*$rem;
        height: 3.5*$rem;
        color: #4C290A;
        font-size: 1.6*$rem;
        border-radius: 2*$rem;
        background: linear-gradient(180deg, #E9D1A2 0%, #C49350 100%);
    }

    .blessArea {
        border: 0.1 * $rem solid #C6000D;
        border-radius: 0.4* $rem;
        padding: $rem;

        .blessCount {
            color: #510B0B;
            line-height: 2*$rem;
            font-weight: bold;
            font-size: 1.4*$rem;
        }

        .blessList {
            position: relative;
            margin-top: 2*$rem;
        }


        .blessingBox {
            display: block;
            color: #510B0B;
            font-size: 1.3*$rem;
            height: $rem*18;


            .blessingText {
                margin-bottom: $rem;
                width: 32.5*$rem;
                line-height: 2*$rem;
                max-height: $rem*4;
                text-overflow: ellipsis;
                word-break: break-all;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .empty {
                height: $rem;
            }
        }
    }
}

// index.module.scss
.marquee-container {
    width: 100%;



    .marquee-wrapper {
        height: $rem*18;
        overflow: hidden;

        .marquee-content {
            transform: translateY(0);
            transition: transform 2s;

            &.animate {
                transition: all .8s;
            }

            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin: 0;


            .marquee-content-itm {
                padding-bottom: $rem;

                p {
                    color: #510B0B;
                    font-size: 1.3*$rem;
                    width: 32.5*$rem;
                    line-height: 2*$rem;
                    max-height: $rem*4;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}
