@import 'scss/variables.scss';

.world-cup-double-rules-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  .world-cup-rules-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #FFFFFF;
    border-radius: 2.2*$rem;
    width: 34*$rem;
    // height: 50*$rem;
    padding: 0 2*$rem 1.7*$rem;
    .world-cup-rules-title {
      padding: 2.3*$rem 0 1.4*$rem;
      font-size: 1.75*$rem;
      color: #000000;
      text-align: center;
    }
    .world-cup-rules-content {
      margin-bottom: 1.7*$rem;
      height: 36*$rem;
      border-radius: 1*$rem;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.3);
      .rules-table-title {
        display: flex;
        align-items: center;
        height: 3*$rem;
        font-size: 1.5*$rem;
        color: #3D3D3D;
        background: #E6F0FE;
        span {
          text-align: center;
          line-height: 3*$rem;
          height: 100%;
          &:nth-child(1) {
            flex: 1;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            border-right: 1px solid rgba(0, 0, 0, 0.3);
          }
          &:nth-child(2) {
            flex: 1.5;
            border-bottom: 1px solid rgba(0, 0, 0, 0.3);
          }
        }
      }
      .rules-table-content {
        &:nth-child(2n + 1) {
          background: #F7F7F7;
        }
        .rules-table-item {
          display: flex;
          align-items: center;
          height: 3*$rem;
          
          span {
            text-align: center;
            line-height: 3*$rem;
            height: 100%;
            &:nth-child(1) {
              flex: 1;
              border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              border-right: 1px solid rgba(0, 0, 0, 0.3);
            }
            &:nth-child(2) {
              flex: 1.5;
              border-bottom: 1px solid rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }
    .rules-modal-btn {
      margin: 0 auto;
      width: 20*$rem;
      height: 4*$rem;
      text-align: center;
      line-height: 4*$rem;
      background: #3662EC;
      border-radius: 2*$rem;
      color: #FFFFFF;
      font-size: 1.8*$rem;
    }
  }
}