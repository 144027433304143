@import 'scss/variables.scss';

.current-guess-item {
  margin-top: 1.5*$rem;
  padding: 1.5*$rem 2.4*$rem;
  background: url('../images/pic_qiuchang.png') no-repeat;
  background-size: contain;
  width: 100%;
  .current-field-time {
    display: flex;
    justify-content: space-between;
    font-size: 1.2*$rem;
    color: #FFFFFF;
    font-weight: bold;
  }
  .current-fields-info {
    margin: 1.3*$rem 0 1*$rem;
    display: flex;
    align-items: center;
    .left-rank {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .rank-avatar {
        margin-right: 0.85*$rem;
        width: 5.5*$rem;
        height: 5.5*$rem;
        border-radius: 50%;
      }
      .rank-name {
        text-align: center;
        font-size: 1.5*$rem;
        color: #FFFFFF;
      }
    }
    .battle {
      margin: 0 2*$rem;
      img {
        width: 3*$rem;
        height: 4.5*$rem;
      }
    }
    .right-rank {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .rank-avatar {
        margin-left: 0.85*$rem;
        width: 5.5*$rem;
        height: 5.5*$rem;
        border-radius: 50%;
      }
      .rank-name {
        text-align: center;
        font-size: 1.5*$rem;
        color: #FFFFFF;
      }
    }
  }
  .guess-btn {
    margin: 3*$rem auto 0;
    width: 24*$rem;
    height: 3.9*$rem;
    background: #FF5100;
    border-radius: 1.95*$rem;
    font-size: 1.5*$rem;
    color: #FFFFFF;
    text-align: center;
    line-height: 3.9*$rem;
    &.not-jion {
      background: #767676;
    }
  }
}