@import 'scss/variables.scss';


.registerSuccess {
  display: 'flex';
  flex: 1;
  background: #fff;

  

  .successHead {
    padding-top: 10*$rem;
    padding-bottom: 1.1*$rem;
    text-align: center;
  }

  .headMain {
    width: 8.3*$rem;
    height: 8.3*$rem;
  }

  .headMainTit {
    color: #333;
    line-height: 4.9*$rem;
    font-size: 1.6*$rem;
    font-weight: bold;
  }

  .downloadBtn {
    display: block;
    width: 30*$rem;
    line-height: 4.4*$rem;
    background-image: linear-gradient(120deg, #D4BA77 0%, #FFEAB4 100%);
    text-align: center;
    margin: 4*$rem auto;
    color: #624C16;
    font-size: 1.8*$rem;
    border-radius: 2.2*$rem;
  }

  .successContent {
    text-align: center;
    margin: auto;
  }

  .title {
    color: #999;
  }

  .titleBold {
    color: #333;
    line-height: 3.5*$rem;
    font-size: 1.6*$rem;
    font-weight: bold;
  }

  .tip {
    color: #999;
    line-height: 1.7*$rem;
    font-size: 1.2*$rem;
    margin-bottom: 2*$rem;
  }

  .QRCodeImg {
    height: 9*$rem;
    width: 9*$rem;
  }

  .QRCodeTit {
    line-height: 3.5*$rem;
    font-size: 1.6*$rem;
    font-weight: bold;
    color: #333;
  }

}