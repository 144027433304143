@import 'scss/variables.scss';

.aptmt_record_container {
  width: 100%;
  min-height: 100%;
  background-color: #F7F7F7;

  .online_aptmt_record_des_container {
    width: 92%;
    min-height: 6 *$rem;
    border-radius: 10px;
    margin-left: 4%;
    margin-top: 1.5 *$rem;
    border: 0.1*$rem solid white;
    background-color: white;
    padding-bottom: 1.5 *$rem;

    .online_aptmt_record_des_group {
      display: flex;
      flex-direction: row;
      width: 100%;
      //height: 3 *$rem;
      align-items: center;
      //background-color: red;
      margin-top: 2 *$rem;

      span {
        display: inline-block;
        margin-left: 0.2 *$rem;
        font-family: PingFang SC;
        font-size: 1.2 *$rem;
        font-weight: normal;
        letter-spacing: 0em;
      }

      :nth-child(1) {
        width: 8 *$rem;
        text-align: right;
        color: #9E9E9E;
      }
    }

    .line {
      width: 92%;
      margin-left: 4%;
      margin-top: 1.8 *$rem;
      height: 0.8px;
      opacity: 0.7;
      background: #D8D8D8;
    }

    .online_aptmt_record_list_item_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 90%;
      margin-left: 5%;
      height: 3.4 *$rem;
      border-radius: 45px;
      margin-top: 2 *$rem;
      opacity: 1;
      background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
      color: #614D1A;
      font-size: 1.4 *$rem;
    }
  }
}

.dialog_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .dialog_title {
    opacity: 1;
    font-family: PingFangSC-Regular;
    font-size: 1.4 *$rem;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
  }

  .dialog_column_line {
    width: 96%;
    height: 1px;
    opacity: 0.7;
    margin-top: 2 *$rem;
    background: #D8D8D8;
  }
  .dialog_btns_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 4 *$rem;
    margin-top: 0.5 *$rem;
    //background-color: red;
    .dialog_row_cancel_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      font-size: 1.5 *$rem;
    }
    .dialog_row_line{
      width: 1px;
      height: 80%;
      background-color: #D8D8D8;
    }
  }
}
