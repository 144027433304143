@import 'scss/variables.scss';

.mask_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_container {
    display: flex;
    flex-direction: column;
    padding: 1.5*$rem 1.5*$rem 0.9*$rem;
    width: 27.9*$rem;
    background: #FFFFFF;
    border-radius: 1*$rem;
    .title {
      margin-bottom: 1.5*$rem;
      font-weight: 600;
      font-size: 1.8*$rem;
      text-align: center;
    }
    .content {
      padding-top: 0.8*$rem;
      height: 5*$rem;
      text-align: center;
      font-size: 1.4*$rem;
      color: #333333;
      border-bottom: 0.05*$rem solid #D8D8D8;
    }
    .btn_box {
      display: flex;
      margin-top: 0.9*$rem;
      font-size: 1.5*$rem;
      .cancel_btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3*$rem;
        color: #999999;
        border-right: 0.05*$rem solid #D8D8D8;
      }
      .confirm_btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3*$rem;
        color: #CAA846;
      }
    }
  }
}