@import 'scss/variables.scss';
.container{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.hide {
    visibility: hidden;
  }

  .modalWrap {
    background: #ffffff;
    border-radius: $rem;
    width: $rem*30.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.4*$rem 3.2*$rem 2*$rem;
    .title {
      font-size: 1.6*$rem;
      font-weight: bold;
      color: #333333;
      line-height: 2.25*$rem;
      margin-bottom: 2.5*$rem;
    }
    .modalContent {
      color: #333333;
      line-height: 2.1*$rem;
      font-size: 1.4*$rem;
    }

    .modalButton {
      width: $rem*30.3;
      margin-top: 3.2*$rem;
      display: flex;
      padding: 0 3.2*$rem;
      .cancel {
        flex:1;
        height: 3.5*$rem;
        background: #D8D8D8;
        font-size: 1.5 * $rem;
        color: #808080;
        border-radius: 2 * $rem;
        font-weight: bold;
      }
      .btnMargin {
        margin-right: 2.4*$rem;
      }
      .confirm {
        flex:1;
        height: 3.5*$rem;
        font-size: 1.5 * $rem;
        color: #7E5B23;
        font-weight: bold;
        background: linear-gradient(100deg, #D4BA77 4%, #FFEAB4 98%);
        border-radius: 2*$rem;
      }
    }
  }
}
