@import 'scss/variables.scss';
.helpDetail {
    background-color: #fff;
    width: 100%;
    min-height: 100%;

    .helpItem {
        line-height: 4*$rem;
        border-bottom: 1px solid #eee;
        padding-left: 2*$rem;
        font-size: 1.4*$rem;
        color: #333;
    }

    .itemIcon {
        height: .5*$rem;
        width: .5*$rem;
        background-color: #ccc;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1*$rem;
    }

    .helpItemText {
        line-height: 2*$rem;
        border-bottom: 1px solid #eee;
        padding: 1*$rem 2*$rem;
        font-size: 1.4*$rem;
        color: #666;
        text-indent: 2.8*$rem;
        display: none;

        p {
            line-height: 2*$rem;
        }
    }

    .show {
        display: block;
    }
}