@import 'scss/variables.scss';

.activity_page {
  height: 100%;

  .imageContent {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }

    .btn_box {
      position:absolute;
      height: 3.9 *$rem;
      width: 80%;
      background: linear-gradient(98deg, #FF7CFF 0%, #F821FB 105%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Medium;
      text-align: center;
      border-radius: 62.5px;
      font-size: 1.5 *$rem;
      font-weight: bold;
      text-align: center;
      color: #FFF;
    }
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
  }
}
