@import 'scss/variables.scss';

.container {
  display: flex;
  position: fixed;
  flex-direction: column;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  touch-action: none;
  user-select: none;
  z-index: 1000;

  .bottomScroll {
    background: #fff;
    border-top-left-radius: 1.2 * $rem;
    border-top-right-radius: 1.2 * $rem;
  }

  .head {
    height: 6 * $rem;
    text-align: center;
    padding-top: 2* $rem;
    font-size: 1.6*$rem;
    color: #333333;

    img {
      width: 1.6*$rem;
      height: 1.6*$rem;
    }
  }

  .wrapHeight {
    height: 32 * $rem;
    overflow: hidden;
  }

  .bigword {
    position: relative;

    >span {
      position: absolute;
      right: 3*$rem;
    }
  }

  .smallword {
    font-size: 1.2*$rem;
    color: #999999;
    padding-top: 1*$rem;
  }

  .modal {
    flex: 1;
    background: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    align-items: center;
    display: flex;
    position: relative;

    .loading {
      width: $rem*10;
      height: $rem*4;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 0.5*$rem;
      font-size: 1.4*$rem;
      margin: 0 auto;
    }

    .childBack {
      flex: 1;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .close {
        width: 2.2*$rem;
        height: 2.2*$rem;
        position: absolute;
        top: 1*$rem;
        right: 1*$rem;
      }

      .verticalMiddle {
        position: relative;
        border-radius: 1*$rem;
        overflow: hidden;
      }
    }

    .shareContainer {
      background: #fff;
      width: 33.7*$rem;
      height: 46.9*$rem;
      position: relative;
      border-bottom-left-radius: 1*$rem;
      border-bottom-right-radius: 1*$rem;

      .shareImage {
        width: 100%;
        height: 37.25*$rem;
        display: inline-block;
        position: relative;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }

        .shareWord {
          position: absolute;
          bottom: 4.3*$rem;
          width: 100%;
          text-align: center;

          div:first-child {
            font-size: 1.3*$rem;
            color: #fff;
          }
        }
      }

      .erCode {
        text-align: center;
        flex-direction: row;
        align-items: center;
        display: flex;
        height: 9*$rem;
        width: 100%;
        padding-left: 2*$rem;
        .div {
          margin-left: 2*$rem;
          display: flex;
          flex-direction: column;

          .title {
            font-size: 1.65*$rem;
            font-weight: bold;
            color: #333333;
            margin-bottom: $rem;
            text-align: left;
          }
          .span {
            text-align: left;
            font-size: 1.2*$rem;
            color: #333333;
          }
        }
      }

    }

    .middleWord {
      color: #fff;
      margin-top: 2*$rem;
      text-align: center;
    }

    .bottomShare {
      width: 100%;
      min-height: 6.6*$rem;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      background: #fff;
      bottom: 0;

      .share {
        width: 22*$rem;
        height: 4.4*$rem;
        margin: 1.1*$rem auto 1*$rem auto;
        border-radius: 4*$rem;
        text-align: center;
        vertical-align: middle;
        font-size: 1.8*$rem;
        color: #fff;
        line-height: 4.4*$rem;
        background-image: -webkit-linear-gradient(top, #FFC159, #FF7228);
      }
    }
  }

  .shareMask {
    position: fixed;
    bottom: 0;

    width: 100%;
    height: 100%;
    z-index: 101;
    display: flex;
    flex-direction: column;

    .mask {
      flex: 1;
    }

    .shareBox {
      width: 100%;
      background: #F8F8F8;
      display: flex;
      flex-direction: column;

      .shareTitle {
        width: 100%;
        height: $rem*4;
        line-height: $rem*4;
        text-align: center;
        font-size: 1.6*$rem;
        color: $deepGray;
      }

      .shareInfo {
        padding: 0 0.5*$rem;
        height: 11*$rem;
        display: flex;
        justify-content: space-between;

        button {
          flex: 1;

          img {
            width: $rem*4.5;
          }

          p {
            margin-top: $rem;
            color: $deepGray;
            font-size: 1.2*$rem;
          }
        }
      }

      .buttonBg {
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        background: #fff;
        box-shadow: 0 0.1*$rem 0.1*$rem #D8D8D8;

        button.shareCancel {
          height: $rem*5;
          background: #FFF;
          width: 100%;
          font-size: 1.6*$rem;
          color: $deepGray;
        }
      }

    }
  }

}
