@import 'scss/variables.scss';

.share_download_app_page {
  position: relative;

  .download_btn {
    position: absolute;
    top: 56.5%;
    left: 50%;
    transform: translateX(-50%);
    width: 75%;
    height: 4.4*$rem;
    background: linear-gradient(97deg, #F16680 -9%, #EE5773 109%);
    font-family: 思源黑体;
    font-size: 1.5 *$rem;
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    border-radius: 570px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.5);
  }
}
