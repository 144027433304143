@import 'scss/variables.scss';

.aptmt_acty_selection_container {
  width: 100%;
  height: 100%;
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.acty_header {
  position: fixed;
  width: 80%;
  height: 9vh;
  padding: 2 *$rem 0 0.2*$rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: #333;
  font-size: 1.6 *$rem;
  z-index: 1;

  .acty_header_item {
    padding: 1 *$rem 1 *$rem 0 1 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //background: yellow;

    .acty_header_item_line {
      width: 60%;
      height: 6px;
      margin-top: 0.6 *$rem;
      border-radius: 3px;
      background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
    }
  }
}
