@import 'scss/variables.scss';

.settlement-item {
  margin: 2.2*$rem 1.6*$rem 0;
  padding: 1.6*$rem 1.5*$rem;
  background: #FFFFFF;
  border-radius: 1*$rem;
  .common-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .item-top {
    margin: 0 0.2*$rem 0.5*$rem;
    padding-bottom: 1.6*$rem;
    border-bottom: 1px solid #D8D8D8;
    span {
      &:nth-child(1) {
        font-size: 1.2*$rem;
        color: #999999;
      }
      &:nth-child(2) {
        font-size: 1.8*$rem;
        color: #000000;
      }
    }
  }
  .points-detail-box {
    padding-bottom: 0.5*$rem;
    border-bottom: 1px solid #D8D8D8;
  }
  .item-bottom {
    align-items: center;
    padding-top: 1.5*$rem;
    font-size: 1.2*$rem;
    color: #000000;
    i {
      color: #FF5100;
    }
  }
  .field-points {
    padding: 1*$rem 0;
    font-size: 1.2*$rem;
    color: #000000;
    .field-points-title {
      &-right {
        display: flex;
        align-items: center;
        .close-arrow-img {
          margin-left: 1.1*$rem;
          height: 0.85*$rem;
          width: 0.45*$rem;
          transform: rotateZ(90deg);
        }
        .open-arrow-img {
          margin-left: 1.1*$rem;
          width: 0.85*$rem;
          height: 0.45*$rem;
          transform: rotateZ(-180deg);
        }
      }
    }
    .field-points-detail {
      font-size: 1.2*$rem;
      color: #9E9E9E;
      &-item {
        padding: 1*$rem 1.9*$rem 0 1.6*$rem;
      }
      &.points-detail-show {
        display: block;
      }
      &.points-detail-hide {
        display: none;
      }
    }
  }
}