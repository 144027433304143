@import 'scss/variables.scss';
.container{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.hide {
    visibility: hidden;
  }

  .modalWrap {
    background: #ffffff;
    border-radius: $rem;
    width: $rem*30.3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3.2*$rem 3.2*$rem 2*$rem;

    .modalContent {
      color: $deepGray;
      line-height: 2.1*$rem;
      font-size: 1.6*$rem;
    }

    .modalButton {
      margin-top: 3.2*$rem;
      display: flex;
      .cancel {
        width: 10.8 *$rem;
        height: 4*$rem;
        border: 0.1 * $rem solid #641F6D;
        background: #ffffff;
        font-size: 1.4 * $rem;
        color: #641F6D;
        margin-right: 2.4*$rem;
        border-radius: 2 * $rem;
      }
      .confirm {
        height: 4*$rem;
        width: 10.8 *$rem;
        background: #641F6D;
        font-size: 1.4 * $rem;
        color: #ffffff;
        border-radius: 2 * $rem;
      }
    }
  }
}
