@import 'scss/variables.scss';

.goddess-rules-modal {
  .goddess-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1.7*$rem 2.4*$rem;
    width: 32*$rem;
    height: 40*$rem;
    background: linear-gradient(180deg, #FCF1DC 3%, #FFF7E8 97%);    border-radius: 1*$rem;
    display: flex;
    flex-direction: column;
    .goddess-modal-title {
      padding-bottom: 1.65*$rem;
      color: #793802;
      font-size: 1.75*$rem;
      font-weight: bold;
      text-align: center;
      position: relative;
    }
    .goddess-modal-icon{
      position: absolute;
      top:0;
      right:-1*$rem;
    }
    .goddess-modal-content {
      box-sizing: border-box;
      border-top: 1px dashed #793802;
      padding-top: 1.65*$rem;
      color: #793802;
      flex: 1;
      word-break: break-all;
      text-overflow: ellipsis;
      overflow-y: auto;
      white-space: pre-line;
      line-height: 1.8*$rem;
    }
    .goddess-rules-btn {
      margin: 1*$rem auto 0;
      width: 15.65*$rem;
      height: 4.1*$rem;
      border-radius: 4.1*$rem;
      background: #ED3B70;
      color: #FFFFFF;
      font-size: 1.8*$rem;
      line-height: 4.1*$rem;
      text-align: center;
    }
  }
}