@import 'scss/variables.scss';

.cartoon_container {
  width: 100%;
  min-height: 100%;
  background-color: #5909B0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 3 *$rem;

  .header_container {
    background-color: #FFF2C5;
    position: relative;

    .img_bg {
      height: 100%;
      width: 100%;
    }

    .back_share_father {
      position: fixed;
      top: 3.8*$rem;
      width: 100%;
      height: 3 *$rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      .back_btn {
        width: 1.75*$rem;
        height: 1.75*$rem;
        background: url(../../../images/icon/back@2x.png) no-repeat;
        background-size: 100%;
        margin-left: 1.5 *$rem;
      }

      .share {
        width: 2.2*$rem;
        height: 2.2*$rem;
        background: url(../../../images/egg_bt_fenxiang.png) no-repeat;
        background-size: 100%;
        margin-right: 1.8 *$rem;
      }
    }

    .rule_shiming_father {
      position: absolute;
      top: 7*$rem;
      width: 100%;
      height: 4 *$rem;
      //background-color: purple;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .huodong_father {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7 *$rem;
        height: 100%;
        position: relative;
        color: #fff;
        img {
          width: 100%;
          height: 3 *$rem;
        }

        .title {
          position: absolute;
          width: 100%;
          height: 3 *$rem;
          line-height: 3 *$rem;
          font-size: 1.2 *$rem;
          text-align: center;
          margin-left: -0.3 *$rem;
          margin-top: 0.1 *$rem;
          vertical-align: center;
          font-weight: bold;

        }

        .title_shiming {
          position: absolute;
          width: 100%;
          font-weight: bold;
          height: 3 *$rem;
          line-height: 3 *$rem;
          font-size: 1.2 *$rem;
          text-align: center;
          margin-left: 0.3 *$rem;
          margin-top: 0.1 *$rem;
          vertical-align: center;
        }
      }
    }

    .yaoxin_pm_father {
      width: 100%;
      min-height: 12 *$rem;
      //background-color: yellow;
      opacity: 1;
      position: absolute;
      bottom: 0 *$rem;
      left: 0;
      display: flex;
      //align-items: center;
      justify-content: center;

      .header_yaoxin {
        //background-color: red;
        width: 60vw;
        height: 60vw/(530/203);
        border-radius: 100px;
        //margin-left: 0.8 *$rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        .header_yaoxin_des_father {
          //background-color: yellow;
          position: absolute;

          .lijiyx {
            font-family: SourceHanSansCN-Bold;
            font-size: 1.8 *$rem;
            font-weight: bold;
            text-align: center;
            margin-top: -0.5 *$rem;
            color: #431179;
          }

          .yijinyx {
            font-family: SourceHanSansCN-Regular;
            font-size: 1.2 *$rem;
            font-weight: normal;
            text-align: center;
            margin-top: 0.5 *$rem;
            letter-spacing: 0em;
            color: #9E45FF;

          }
        }
      }

      .header_pl_jl {
        //background-color: purple;
        width: 42vw;
        height: 42vw/(304/115);
        position: absolute;
        left: 0 *$rem;
        bottom: 1.0 *$rem;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .header_pl_jl_des_father {
          position: absolute;
          //background-color: red;
          .yaoxinjl {
            font-family: SourceHanSansCN-Bold;
            font-size: 1.3 *$rem;
            font-weight: bold;
            line-height: 1.6 *$rem;
            text-align: center;
            letter-spacing: 0em;
            color: #431179;
          }

          .jianglixx {
            font-family: SourceHanSansCN-Regular;
            font-size: 1.1 *$rem;
            font-weight: normal;
            line-height: 1.6 *$rem;
            text-align: center;
            color: #9E45FF;
          }
        }
      }

      .header_pl_jl_right {
        //background-color: purple;
        width: 42vw;
        height: 42vw/(304/115);
        position: absolute;
        right: 0 *$rem;
        bottom: 1.0 *$rem;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .header_pl_jl_right_des_father {
          position: absolute;
          //background-color: red;
          .yaoxinpm {
            font-family: SourceHanSansCN-Bold;
            font-size: 1.3 *$rem;
            font-weight: bold;
            line-height: 1.6 *$rem;
            text-align: center;
            letter-spacing: 0em;
            color: #431179;
          }

          .jiangping {
            font-family: SourceHanSansCN-Regular;
            font-size: 1.1 *$rem;
            font-weight: normal;
            line-height: 1.6 *$rem;
            text-align: center;
            color: #9E45FF;
          }
        }
      }
    }
  }

  .cartoon_mid_container {
    width: 100%;

    .buy_get_component {
      width: 94vw;
      margin-left: 3vw;
      height: 94vw/(710/422);
      position: relative;

      .buy_get_component_img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .buy_get_component_content {
        position: absolute;
        left: 0;
        top: 5*$rem;
        min-height: 100px;
        width: 100%;
        //background-color: red;
        display: flex;
        flex-direction: row;

        .pro_img {
          width: 13.5 *$rem;
          height: 13.5 *$rem;
          object-fit: cover;
          border-radius: 8px;
          margin-left: 1 *$rem;
          background-color: white;
          padding: 1.5 *$rem 1 *$rem;
        }

        .pro_component {
          margin-left: 1 *$rem;
          margin-top: 1 *$rem;

          .pro_component_title {
            font-family: PingFangSC-Regular;
            font-size: 1.5 *$rem;
            font-weight: bold;
            color: #FFFFFF;
          }

          .pro_component_des {
            font-family: PingFangSC-Regular;
            font-size: 1.1 *$rem;
            font-weight: normal;
            margin-top: 3px;
            line-height: 1.3 *$rem;
            letter-spacing: 0.02em;
            color: #FFFFFF;
            white-space: pre-line;
          }

          .pro_component_dl_money_com {

            position: absolute;
            bottom: 0;
            display: flex;
            align-items: flex-end;
            //background-color: purple;


            .pro_component_dl {
              font-family: PingFangSC-Semibold;
              font-size: 2 *$rem;
              font-weight: 600;
              color: #FFE432;
              display: inline-block;
              margin-left: 0.3 *$rem;
              margin-bottom: 0.4 *$rem;
              //line-height: 4 *$rem;
            }

            .pro_component_money {
              display: inline-block;
              font-size: 4*$rem;
              font-weight: 500;
              color: #FFE432;
              //font-family: PingFangSC-Semibold;
              //margin-left: 0.5 *$rem;
              letter-spacing: -0.06em;
            }

            .pro_component_gobuy {
              display: inline-block;
              width: 10 *$rem;
              height: 3.4 *$rem;
              border-radius: 570px;
              opacity: 1;
              background: linear-gradient(108deg, #FF7CFF 2%, #F821FB 105%);
              box-shadow: 0px 10px 16px 0px rgba(67, 17, 121, 0.3);
              font-family: SourceHanSansCN-Bold;
              font-size: 1.5 *$rem;
              line-height: 3.4 *$rem;
              font-weight: bold;
              text-align: center;
              color: #FFFFFF;
              vertical-align: center;
              margin-left: 3.6 *$rem;
              margin-bottom: 0.4 *$rem;
            }
          }
        }
      }
    }

    .conn_container {
      width: 70%;
      margin-left: 15%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: -1.2 *$rem;
      //background-color: red;

      img {
        width: 1.2 *$rem;
        height: 3.6 *$rem;
        object-fit: cover;
        z-index: 1;
      }
    }

    .mh_com {
      width: 94vw;
      margin-left: 3vw;
      margin-top: -1.2 *$rem;
      min-height: 20 *$rem;
      border-radius: 10px;
      overflow: hidden;
      background: linear-gradient(145deg, #650DC4 4%, #A023FF 57%, #580DAA 102%);

      .mh_des_com {
        font-family: SourceHanSansCN-Bold;
        font-size: 1.5 *$rem;
        font-weight: bold;
        letter-spacing: 0em;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 2 *$rem;
      }

      .mh_des0_com {
        margin-top: 2 *$rem;
        display: flex;
        align-items: center;
        //justify-content: center;
      }

      //.mh_des0_com_0 {
      //  margin-left: 2vw;
      //  width: 35vw;
      //  height: 8.6 *$rem;
      //  border-radius: 10px;
      //  opacity: 1;
      //  box-sizing: border-box;
      //  border: 1px dashed #40027B;
      //  display: flex;
      //  align-items: center;
      //  justify-content: center;
      //
      //  .mh_des0_com_0_content {
      //    width: 90%;
      //    height: 90%;
      //    border-radius: 10px;
      //    background: rgba(67, 17, 121, 0.5);
      //    color: #fff;
      //    position: relative;
      //
      //    > div {
      //      width: 100%;
      //      font-size: 1.1 *$rem;
      //      line-height: 1.5 *$rem;
      //      //background-color: red;
      //      padding: 1 *$rem 0.6 *$rem 0;
      //    }
      //
      //    .mh_des0_com_0_content_des {
      //      position: absolute;
      //      bottom: 7px;
      //      right: 0.4 *$rem;
      //      font-size: 0.8 *$rem;
      //    }
      //  }
      //}

      .mh_des0_com_1 {
        //margin-left: 3vw;
        //width: 51vw;
        width: 89vw;
        margin-left:2vw;
        //background-color: red;
        height: 8 *$rem;
        border-radius: 10px;
        opacity: 1;
        box-sizing: border-box;
        border: 1px dashed #40027B;
        display: flex;
        align-items: center;
        justify-content: center;

        .mh_des0_com_1_content {
          width: 96%;
          height: 90%;
          border-radius: 10px;
          background: rgba(67, 17, 121, 0.5);
          color: #fff;
          position: relative;

          > div {
            width: 100%;
            font-size: 1.1 *$rem;
            line-height: 1.6 *$rem;
            //background-color: red;
            padding: 1.7 *$rem 3vw 0;
            //margin-left: 3vw;
            //margin-top: 1.7 *$rem;
          }

          .mh_des0_com_1_content_des {
            position: absolute;
            bottom: 2 *$rem;
            right: 3vw;
            font-size: 0.8 *$rem;
          }
        }

      }

      .list_component {
        margin-left: 2vw;
        margin-top: 1 *$rem;
        width: 89vw;
        height: 8 *$rem;
        box-sizing: border-box;
        border-radius: 10px;
        border: 1px dashed #40027B;
        display: flex;
        align-items: center;
        justify-content: center;

        .list_component_container {
          width: 96%;
          height: 90%;
          border-radius: 10px;
          background: rgba(67, 17, 121, 0.5);
          color: #fff;
          position: relative;
          //background-color: red;

          .list_component_title {
            font-family: PingFangSC-Regular;
            font-size: 1.3 *$rem;
            font-weight: bold;
            display: inline-block;
            color: #FFFFFF;
            margin-left: 3vw;
            margin-top: 1.7 *$rem;
          }

          .list_component_des {
            font-family: PingFangSC-Regular;
            font-size: 0.9 *$rem;
            font-weight: normal;
            line-height: 1.2 *$rem;
            color: #FFFFFF;
            margin-left: 3vw;
            margin-top: 0.5 *$rem;
            width: 100%;
            white-space: pre-line;
          }

          .list_component_look_detail {
            position: absolute;
            right: 1 *$rem;
            bottom: 2.5 *$rem;
            width: 8 *$rem;
            height: 2.5 *$rem;
            border-radius: 570px;
            background: linear-gradient(106deg, #FF7CFF -3%, #F821FB 105%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 1.2 *$rem;
            font-weight: bold;
            font-family: SourceHanSansCN-Bold;
          }
        }
      }

      .mh_des_bottom {
        width: 100%;
        height: 2 *$rem;
        font-family: SourceHanSansCN-Regular;
        font-size: 1.4 *$rem;
        font-weight: normal;
        text-align: center;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3 *$rem;
        padding-bottom: 3 *$rem;
      }
    }
  }

  .cartoon_grade_container {
    width: 94vw;
    margin-left: 3vw;
    margin-top: 3 *$rem;


    .cartoon_grade_content {
      width: 100%;
      //min-height: 40 *$rem;
      background: linear-gradient(154deg, #650DC4 5%, #A023FF 58%, #580DAA 103%);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      padding-bottom: 4 *$rem;

      .cartoon_list_com {
        display: inline-block;
        width: 27vw;
        height: 13 *$rem;
        margin-left: 3vw;
        //background-color: red;
        margin-top: 2 *$rem;
        position: relative;

        img {
          width: 100%;
          height: 10 *$rem;
          object-fit: cover;
          border-radius: 12px;
          //filter: grayscale(80%);
        }

        .cartoon_list_title {
          font-family: PingFangSC-Regular;
          font-size: 1.1 *$rem;
          margin-top: 0.5 *$rem;
          white-space: pre-line;
          color: #fff;
        }

        span {
          display: inline-block;
          font-size: 1.1 *$rem;
          letter-spacing: 0em;
          margin-top: 0.2 *$rem;
          color: #fff;
        }
        .cartoon_list_has_com{
          width: 100%;
          position: absolute;
          height: 2 *$rem;
          left: 0;
          bottom: 3.5 *$rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 1.1 *$rem;
          background: linear-gradient(270deg, rgba(67,17,121,0.00) 0%, rgba(67,17,121,0.80) 49%, rgba(67,17,121,0.00) 100%);
        }
      }
    }

    .cartoon_grade_com_sj {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .cartoon_grade_sj {
        width: 70%;
        height: 4 *$rem;
        border-radius: 570px;
        background: linear-gradient(98deg, #FF7CFF 0%, #F821FB 105%);
        box-shadow: 0px 10px 16px 0px rgba(67, 17, 121, 0.38);
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: SourceHanSansCN-Bold;
        font-size: 1.5 *$rem;
        font-weight: bold;
        margin-top: 3 *$rem;
        color: #FFFFFF;
      }
    }

    .cartoon_grade_sj_des_com {
      width: 100%;
      margin-top: 2 *$rem;
      //background-color: red;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 60%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cartoon_grade_dz_des_com{ //打折
      width: 94%;
      height: 20 *$rem;
      margin-left: 3%;
      margin-top: 2.5 *$rem;
      border-radius: 10px;
      opacity: 1;
      display: flex;
      align-items: center;
      //justify-content: center;
      flex-direction: column;
      background: #431179;
      .cartoon_grade_dz_title_com{
        font-family: SourceHanSansCN-Bold;
        font-size: 1.5 *$rem;
        font-weight: bold;
        color: #fff;
        margin-top: 2.5 *$rem;
        span{
          color: #FFE432;
        }
      }
      .cartoon_grade_dz_content_com{
        width: 90%;
        font-family: SourceHanSansCN-Bold;
        font-size: 1.2 *$rem;
        margin-top: 2.0 *$rem;
        font-weight: bold;
        line-height: 1.5 *$rem;
        text-align: center;
        color: #FFE432;
      }
      .cartoon_grade_dz_content0_com{
        font-family: SourceHanSansCN-Regular;
        font-size: 1.1 *$rem;
        font-weight: normal;
        color: #FFFFFF;
        margin-top: 1.8 *$rem;
      }


    }
  }

  .logo_father {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6.7 *$rem;
  }

  .egg_logo {
    width: 15 *$rem;
    height: 2.5 *$rem;
  }

}
