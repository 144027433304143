@import './../../scss/variables.scss';
.container{
    display: flex;
    width: 100%;
    flex-direction: column;
    .topImage{
        width: 100%;
        height: 18 * $rem;
    }
    .tabs{
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        display: flex;
        width: 100%;
        height: 5 * $rem;
        padding-top: 1.6 * $rem;
        padding-bottom: 1.6 * $rem;
        align-items: center;
        justify-content: center;
        background-color: white;
        .text{
            font-size: 2 * $rem;
            color: #333333;
            line-height: 2.8 * $rem;
        }
        .gap{
            margin-left: 5 * $rem;
            margin-right: 5 * $rem;
        }
        .selected{
            font-weight: 600;
            font-size: 2 * $rem;
        }
        .unSelected{
            color: #333333;
            font-weight: 400;
            font-size: 1.6 * $rem;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        padding-left: 1.5 * $rem;
        padding-right: 1.5 * $rem;
    }
    .meetingView{
        display: flex;
        flex-direction: column;
        .topImage{
            width: 100%;
            height: 17.25 * $rem;
        }
        p{
            font-size: 1.4 * $rem;
            color: #33363A;
            line-height: 2.1 * $rem;
            letter-spacing: 0.1 * $rem
        }
        .gap{
            margin-top: 2 * $rem;
            margin-bottom: 2.85 * $rem;
        }
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            p{
                font-size: 1.8 * $rem;
                font-weight: 600;
                color: #222326;
                line-height: 1.8 * $rem;
                letter-spacing: 0.1 * $rem;
                margin-left: 1.55 * $rem;
                margin-right: 1.55 * $rem;
            }

        }
        .icon{
            width: 2.5 * $rem;
            height: 2.5 * $rem;
        }
        .transfer{
            transform:rotate(90deg);
        }
        .picTwo{
            width: 100%;
            height:  19.4 * $rem;
            margin-top: 2.35 * $rem;
            margin-bottom: $rem;
        }
        .title_one{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            p{
                font-size: 1.7 * $rem;
                font-weight: 600;
                color: #222326;
            }
        }
        .gap_one{
            margin-top: $rem;
        }
    }
    .apartmentView{
        display: flex;
        flex-direction: column;
        .topImage{
            width: 100%;
            height: 17.25 * $rem;
        }
        p{
            font-size: 1.4 * $rem;
            color: #33363A;
            line-height: 2.1 * $rem;
            letter-spacing: 0.1 * $rem
        }
        .gap{
            margin-top: 2 * $rem;
            margin-bottom: 1.5 * $rem;
        }
        .cardViews{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .cardView{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                &.gap_tt{
                    margin-left: 0.5 * $rem;
                }
                &.gat_tt_top{
                    margin-top: 1.6 * $rem;
                }
                .icon {
                    width: 17 * $rem;
                    height: 10 * $rem;
                }
                .titleText{
                    font-size: 1.2 * $rem;
                    color: #333333;
                    line-height: 1.65 * $rem;
                    margin-top: 0.45 * $rem;
                }
                .descText{
                    width: 17 * $rem;
                    font-size: 1.2 * $rem;
                    color: #666666;
                    line-height: 1.8 * $rem;
                    text-align: center;
                    margin-top: 0.5 * $rem;
                }
            }
        }
        .titleApartment{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 2.5 * $rem;
            p{
                font-size: 1.8 * $rem;
                font-weight: 600;
                color: #222326;
                line-height: 1.8 * $rem;
                letter-spacing: 0.1 * $rem;
                margin-left: 1.55 * $rem;
                margin-right: 1.55 * $rem;
            }
            .icon{
                width: 2.5 * $rem;
                height: 2.5 * $rem;
            }
            .transfer{
                transform:rotate(90deg);
            }
        }
        .pic_two{
            width: 100%;
            height: 19.45 * $rem;
            margin-top: 2.35 * $rem;
        }
        .title_one{
            font-size: 1.8 * $rem;
            color: #333333;
            line-height: 2.5 * $rem;
            margin-top: $rem;
        }
        .title_two{
            margin-top: $rem;
        }
    }
    .hotelView{
        display: flex;
        flex-direction: column;
        .topImage{
            width: 100%;
            height: 17.25 * $rem;
        }
        p{
            font-size: 1.4 * $rem;
            color: #33363A;
            line-height: 2.1 * $rem;
            letter-spacing: 0.1 * $rem
        }
        .gap{
            margin-top: 2 * $rem;
            margin-bottom: 2 * $rem;
        }
        .image_one{
            width: 100%;
            height: 31.35 * $rem;
        }
        .hotelApartment{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 2.5 * $rem;
            p{
                font-size: 1.8 * $rem;
                font-weight: 600;
                color: #222326;
                line-height: 1.8 * $rem;
                letter-spacing: 0.1 * $rem;
                margin-left: 1.55 * $rem;
                margin-right: 1.55 * $rem;
            }
            .icon{
                width: 2.5 * $rem;
                height: 2.5 * $rem;
            }
            .transfer{
                transform:rotate(90deg);
            }
        }
        .image_two{
            width: 100%;
            height: 17.5 * $rem;
            margin-top: 2.35 * $rem;
            margin-bottom: $rem;
        }
        .title_service{
            font-size: 1.8 * $rem;
            color: #333333;
            line-height: 2.5 * $rem;
            font-weight: 600;
        }
        .item{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 1.5 * $rem;
            p{
                font-size: 1.4 * $rem;
                color: #B3906A;
                line-height: 2.1 * $rem;
                letter-spacing: 0.1 * $rem;
            }
            img{
                width: 100%;
                height: 6.2 * $rem;
            }
        }
        .image_three{
            width: 100%;
            height: 18 * $rem;
            margin-top: 2 * $rem;
        }
        .gap_service{
            margin-top: $rem;
        }
        .gap_service_one{
            margin-top: 0.5 * $rem;
        }
    }
}
