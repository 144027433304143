@import 'scss/variables.scss';

.integral_draw_wrap {
  //flex: 1;
  height: 100vh;
  background: url('./img/background.png') bottom center no-repeat;
  background-size: cover;

  .title {
    //margin-top: 1 *$rem;
    //margin-top: $rem*5;
    height: $rem*6.75;
    //text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 2.75 *$rem;
    }
  }

  .draw_wrap {
    background: url('./img/Integral_draw-box.png') center center no-repeat;
    background-size: contain;
    width: $rem*33.5;
    height: $rem*36.6;
    margin: $rem*0.21 auto 0;

    .draw_title {
      text-align: center;
      line-height: $rem*1.9;
      font-size: $rem*1.3;
      color: #FA6400;
      height: $rem*3.3;
      padding-top: 0.7*$rem;
    }

    .draw_border {

      display: flex;
      flex-wrap: wrap;
      padding: $rem*2.2;
      position: relative;
      align-items: space-between;
      justify-content: space-between;

      &.light {
        .circle {
          &:nth-child(even) {
            background-image: linear-gradient(255deg, #DA4503 2%, #FF924A 100%);
          }

          &:nth-child(odd) {
            background-image: linear-gradient(265deg, #FFDE7F 0%, #FFF3D3 100%);
          }
        }
      }

      .circle {
        display: inline-block;
        box-shadow: 0 $rem*0.1 $rem*0.15 0 rgba(163, 65, 0, 0.30);
        width: $rem*0.8;
        height: $rem*0.8;
        border-radius: 50%;
        position: absolute;

        &:nth-child(odd) {
          background-image: linear-gradient(255deg, #DA4503 2%, #FF924A 100%);
        }

        &:nth-child(even) {
          background-image: linear-gradient(265deg, #FFDE7F 0%, #FFF3D3 100%);
        }

        &:nth-child(1) {
          top: 0.5*$rem;
          left: 2.35*$rem;
        }

        &:nth-child(2) {
          top: 0.5*$rem;
          left: 2.35*$rem+3.5*$rem;
        }

        &:nth-child(3) {
          top: 0.5*$rem;
          left: 2.35*$rem+2*3.5*$rem;
        }

        &:nth-child(4) {
          top: 0.5*$rem;
          left: 2.35*$rem+3*3.5*$rem;
        }

        &:nth-child(5) {
          top: 0.5*$rem;
          left: 2.35*$rem+4*3.5*$rem;
        }

        &:nth-child(6) {
          top: 0.5*$rem;
          left: 2.35*$rem+5*3.5*$rem;
        }

        &:nth-child(7) {
          top: 0.5*$rem;
          left: 2.35*$rem+6*3.5*$rem;
        }

        &:nth-child(8) {
          top: 0.5*$rem;
          left: 2.35*$rem+7*3.5*$rem;
        }

        &:nth-child(9) {
          top: 0.5*$rem;
          left: 2.35*$rem+8*3.5*$rem;
        }

        &:nth-child(10) {
          top: 2.6*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(11) {
          top: 2.6*$rem+3.5*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(12) {
          top: 2.6*$rem+2*3.5*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(13) {
          top: 2.6*$rem+3*3.5*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(14) {
          top: 2.6*$rem+4*3.5*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(15) {
          top: 2.6*$rem+5*3.5*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(16) {
          top: 2.6*$rem+6*3.5*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(17) {
          top: 2.6*$rem+7*3.5*$rem;
          left: 32.2*$rem;
        }

        &:nth-child(18) {
          top: 29.2*$rem;
          left: 2.35*$rem+8*3.5*$rem;
        }

        &:nth-child(19) {
          top: 29.2*$rem;
          left: 2.35*$rem+7*3.5*$rem;
        }

        &:nth-child(20) {
          top: 29.2*$rem;
          left: 2.35*$rem+6*3.5*$rem;
        }

        &:nth-child(21) {
          top: 29.2*$rem;
          left: 2.35*$rem+5*3.5*$rem;
        }

        &:nth-child(22) {
          top: 29.2*$rem;
          left: 2.35*$rem+4*3.5*$rem;
        }

        &:nth-child(23) {
          top: 29.2*$rem;
          left: 2.35*$rem+3*3.5*$rem;
        }

        &:nth-child(24) {
          top: 29.2*$rem;
          left: 2.35*$rem+2*3.5*$rem;
        }

        &:nth-child(25) {
          top: 29.2*$rem;
          left: 2.35*$rem+1*3.5*$rem;
        }

        &:nth-child(26) {
          top: 29.2*$rem;
          left: 2.35*$rem+0*3.5*$rem;
        }

        &:nth-child(34) {
          top: 2.6*$rem;
          left: 0.5*$rem;
        }

        &:nth-child(33) {
          top: 2.6*$rem+3.5*$rem;
          left: 0.5*$rem;
        }

        &:nth-child(32) {
          top: 2.6*$rem+2*3.5*$rem;
          left: 0.5*$rem;
        }

        &:nth-child(31) {
          top: 2.6*$rem+3*3.5*$rem;
          left: 0.5*$rem;
        }

        &:nth-child(30) {
          top: 2.6*$rem+4*3.5*$rem;
          left: 0.5*$rem;
        }

        &:nth-child(29) {
          top: 2.6*$rem+5*3.5*$rem;
          left: 0.5*$rem;
        }

        &:nth-child(28) {
          top: 2.6*$rem+6*3.5*$rem;
          left: 0.5*$rem;
        }

        &:nth-child(27) {
          top: 2.6*$rem+7*3.5*$rem;
          left: 0.5*$rem;
        }
      }

      .draw_mask {
        position: absolute;
        top: $rem*1.8;
        left: $rem*1.8;
        right: $rem*1.8;
        bottom: $rem*2;
        background: rgba(0, 0, 0, 0.5);
        z-index: 3;
        border-radius: $rem;
      }

      .draw_start {
        width: $rem*9.5;
        height: $rem*8;
        display: block;
        background-image: linear-gradient(180deg, #FFAD79 0%, #FF8356 88%);
        box-shadow: 0 0.5*$rem 0 0 #EE7141,
        inset 0 0 0.3*$rem 0 rgba(255, 242, 210, 0.30);
        border-radius: $rem;
        margin-bottom: $rem*0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;

        h2 {
          font-size: 1.8*$rem;
          color: #5F350F;
          line-height: 2.1*$rem;

        }

        p {
          font-size: 1.3*$rem;
          color: #C56027;
          letter-spacing: 0;
          text-align: center;
          line-height: 2*$rem;
          background: rgba(255, 255, 255, 0.2);
          border-radius: $rem;
          width: $rem*6;
          height: $rem*2;
          margin-top: $rem*0.5;
        }
      }

      .draw_button {
        width: $rem*9.5;
        height: $rem*8;
        display: block;
        background: #FFF5D9;
        box-shadow: 0 0.5*$rem 0 0 #EBCC75;
        border-radius: $rem;
        flex-shrink: 1;
        margin-bottom: $rem*0.8;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &.active {
          z-index: 4;
        }

        p {
          font-size: 1.2*$rem;
          color: #C56027;
          //height: 1.9*$rem;
          margin-top: $rem*0.905;
          //background-color: red;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: center;
          display: inline-block;
          width: 100%;
        }

        img {
          width: $rem*4.4;
          height: $rem*4.4;
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 0 3.7*$rem;

    button {
      width: 13*$rem;
      height: 4*$rem;
      line-height: 4*$rem;
      background-image: linear-gradient(180deg, #FFD166 0%, #FFAC1E 100%);
      box-shadow: inset 0 -0.3*$rem 0.8*$rem 0 #F2832A;
      border-radius: 2*$rem;
      font-size: 1.6*$rem;
      color: #FA6400;
    }
  }
}

.dialog_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .dialog_title {
    width: 100%;
    height: 2 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Semibold;
    font-size: 1.5 *$rem;
    color: #333333;
    font-weight: bold;

  }

  .first_title {
    width: 100%;
    font-family: PingFangSC-Regular;
    font-size: 1 *$rem;
    margin-top: 0.5 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    color: #666666;
  }

  img {
    width: 6.6 *$rem;
    height: 6.6 *$rem;
    object-fit: contain;
    //background: #D8D8D8;
    margin-top: 1 *$rem;
  }

  .second_title {
    width: 100%;
    margin-top: 1.5 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-family: PingFangSC-Regular;
    font-size: 1 *$rem;
    line-height: 1.5 *$rem;
    text-align: center;
    white-space: pre-line;
    color: #333333;
  }

  .btn_container {
    width: 90%;
    height: 4 *$rem;
    margin-top: 2 *$rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left_btn {
      width: 40%;
      height: 3 *$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Regular;
      font-size: 1.2 *$rem;
      font-weight: normal;
      color: #BEA35C;
      border-radius: 16px;
      opacity: 1;
      border: 1px solid #D9C07E;


    }

    .right_btn {
      width: 40%;
      height: 3 *$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 22px;
      background: linear-gradient(100deg, #D4BA77 4%, #FFEAB4 98%);
      font-size: 1.2 *$rem;
      color: #624C16;
    }
  }
}
