@import 'scss/variables.scss';
.container{
  width: 100%;
  min-height: 100vh;
  background: #ECEEF0;
  display: flex;
  flex-direction: column;
  padding:1.5*$rem 2*$rem 2*$rem;
  .title {
    font-size: 1.6 * $rem;
    font-weight: 500;
    color: #333333;
    line-height: 2.25 * $rem;
    margin-bottom: 0.5 * $rem;
  }
  .calendar {
    width: 33.5 * $rem;
    height: 35 * $rem;
    background: #FFFFFF;
    border-radius: 0.5 *$rem;
  }

  .title2 {
    font-size: 1.6 * $rem;
    font-weight: 500;
    color: #333333;
    line-height: 2.25 * $rem;
    margin-bottom: 1.25 * $rem;
    margin-top: 2 * $rem;
  }

  .selectWrap {
    .label {
      font-size: 1.3 * $rem;
      color: #333333;
      line-height: 1.85 * $rem;
      margin-bottom: 0.5 * $rem;
    }
    .timeZone {
      display: flex;
      width: 33.5 * $rem;
      flex-wrap: wrap;
      justify-content: space-between;

      .btnItem {
        width: 15.9 * $rem;
        height: 4 * $rem;
        background: #ffffff;
        border-radius: 0.5 *$rem;
        box-shadow: 0 0.1*$rem 1.5*$rem 0 #E5E7EF;
        color: #333333;
        font-size: 1.4 * $rem;
        margin-bottom: 2 * $rem;
        &.active {
          border: 0.1 * $rem solid #641F6D;
          color: #641F6D;
        }
        &.gray {
          background: #D8D8D8;
          color: #A0A0A0;
        }
      }
    }
  }

  .commit {
    bottom: $rem;
    width: 33.6 * $rem;
    height: 4.4 * $rem;
    background: #641F6D;
    border-radius: 0.5 * $rem;
    opacity: 0.6;
    color: #fff;
    font-size: 1.6 * $rem;
    &.active {
      opacity: 1;
    }
  }
}
