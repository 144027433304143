@import 'scss/variables.scss';

.world-cup-rules-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  .world-cup-rules-modal {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #FFFFFF;
    border-radius: 2.2*$rem;
    width: 34*$rem;
    height: 50*$rem;
    padding: 0 2.4*$rem 1.7*$rem;
    .world-cup-rules-title {
      padding: 2.3*$rem 0 1.4*$rem;
      font-size: 1.75*$rem;
      color: #000000;
      text-align: center;
    }
    .world-cup-rules-content {
      flex: 1;
      padding: 0 0 1.5*$rem;
      .rules-iframe {
        height: 100%;
      }
    }
    .rules-modal-btn {
      margin: 0 auto;
      width: 20*$rem;
      height: 4*$rem;
      text-align: center;
      line-height: 4*$rem;
      background: #3662EC;
      border-radius: 2*$rem;
      color: #FFFFFF;
      font-size: 1.8*$rem;
    }
  }
}