@import 'scss/variables.scss';
.container{
  width: 100%;
  background: #FFF9EA;
  box-shadow: 0 0.2 * $rem 1 * $rem 0 rgba(255, 84, 0, 0.6), 0 0 1.5 * $rem 0 rgba(255, 121, 0, 0.5);
  border-radius: 1 * $rem;
  padding-bottom:$rem;
  .content{
    display: flex;
    flex-direction: column;
    width: 100%;
    .column_one{
      display: flex;
      flex-direction: row;
      margin-top: 2 * $rem;
      justify-content: space-between;
      align-items: center;
      .icon_view{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        :last-child{
          width: 11.9 * $rem;
          text-align: center;
        }
      }
      .icon{
        width: 2.5 * $rem;
        height: 3 * $rem;
      }
      .icon_one{
        width: 3 * $rem;
        height: 3 * $rem;
      }
      .icon_two{
        width: 4.15 * $rem;
        height: 3 * $rem;
      }
      .icon_three{
        width: 5 * $rem;
        height: 3 * $rem;
      }
      .text{
        font-size: 1.3 * $rem;
        font-weight: 400;
        color: #4C290A;
        line-height: 2 * $rem;
        margin-top: 0.5 * $rem;
      }
      .arrow_image{
        width: 10.5 * $rem;
        height: 2 * $rem;
      }
    }
    .column_two{
      padding-left: 1.5 * $rem;
      padding-right: 1.5 * $rem;
      margin-top: 1.5 * $rem;
      p{
        font-size: 1.3 * $rem;
        font-weight: 400;
        color: #4C290A;
        line-height: 2 * $rem;
      }
    }
    .column_three{
      padding-left: 1.5 * $rem;
      padding-right: 1.5 * $rem;
      margin-top: 1.5 * $rem;
      p{
        font-size: 1.3 * $rem;
        font-weight: 400;
        color: #4C290A;
        line-height: 2 * $rem;
      }
      p:only-child
      {
        padding-bottom: $rem;
      }
      .button{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 4.5 * $rem;
        background: linear-gradient(180deg, #FFCD7A 0%, #FF9931 100%);
        box-shadow: 0px 0.3 * $rem 0.8 * $rem 0 #F2832A;
        border-radius: 2.25* $rem;
        margin-top: 2.25* $rem;
        margin-bottom: 2 * $rem;
        p{
          font-size: 1.8 * $rem;
          font-weight: 600;
          color: #4C290A;
          text-align: center;
          letter-spacing: 0.1 * $rem;
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
