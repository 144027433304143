@import 'scss/variables.scss';

.container{
  width: 100%;
  height: 100vh;
}

.div_1{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: aqua;
  transition: all 3s;
  transition-timing-function: steps(3000,start);
}
.div_1:hover{
  background-color: rgb(21, 255, 0);
  width: 400px;
  height: 400px;
  border-radius: 0%;
}
