@import 'scss/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0,0,0,0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  .contentBg {
    width: 37.5 * $rem;
    background: #ffffff;
    border-radius: 1.2*$rem 1.2*$rem 0 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    .contentOpen {
      width: 37.5 * $rem;
      height: 24 * $rem;
      .text {
        font-size: 1.8 * $rem;
        font-weight: 600;
        color: #333333;
        line-height: 2.5 * $rem;
        margin: 3 * $rem 1.5 * $rem 0.55 * $rem;
      }
      .text2 {
        margin-left: 1.5 * $rem;
        margin-bottom: 3.35 * $rem;
        font-size: 1.4 * $rem;
        font-weight: 400;
        color: #9B9B9B;
        line-height: 1.6 *$rem;
      }

      .checkBg {
        width: 100%;
        height: 1.6 * $rem;
        display: flex;
        padding:0 2 * $rem;
        .img {
          width: 1.6 * $rem;
          height: 1.6 * $rem;
        }
        .tip {
          margin-left: 0.8 * $rem;
          font-size: 1.4 * $rem;
          font-weight: 400;
          color: #333333;
          line-height: 1.6 * $rem;
        }
      }

      .btns {
        margin-top: 3.65 * $rem;
        width: 100%;
        height: 4 * $rem;
        display: flex;
        padding:0 2 * $rem;
        justify-content: space-between;
        .btn {
          width: 15.5 * $rem;
          height: 4 * $rem;
          background: #BBBBBB;
          border-radius: 2 * $rem;
          font-size: 1.6 * $rem;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 4 * $rem;
          text-align: center;
          &.active {
            background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
            box-shadow: 0.05 * $rem 0.15 * $rem 0.4 * $rem 0 #FFBE92;
          }
        }
      }

    }
    .contentClose {
      width: 37.5 * $rem;
      height: 24 * $rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      .img {
        margin-top: 0.9 * $rem;
        width: 6.05 * $rem;
        height: 6.1 * $rem;
      }
      .text {
        font-size: 1.8 * $rem;
        font-weight: 600;
        color: #333333;
        line-height: 2.5 * $rem;
        margin-top: $rem;
        margin-bottom: 0.55 * $rem;
      }
      .text2 {
        font-size: 1.4 * $rem;
        font-weight: 400;
        color: #9B9B9B;
        line-height: 1.6 * $rem;
      }
      .btn {
        margin-top: 2 * $rem;
        margin-bottom: 1.2 * $rem;
        width: 34.5 * $rem;
        height: 4 * $rem;
        background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
        box-shadow: 0.05 * $rem 0.15 * $rem 0.4 * $rem 0 #FFBE92;
        border-radius: 2.5 * $rem;
        font-size: 1.6 * $rem;
        font-weight: 600;
        line-height: 4*$rem;
        color: #FFFFFF;
        text-align: center;
      }
    }
  }
}