@import 'scss/variables.scss';

.guess_count-down-component {

  text-align: center;

  .count {
    display: inline-block;
    text-align: center;
    color: #157500;
    font-size: 0.9*$rem;
    &.disable{
      opacity: 0.5;
    }
  }
}
