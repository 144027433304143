@import 'scss/variables.scss';

.container {
  width: 100%;
  min-height: 100%;
  background-color: #092010;
  padding-bottom: 3 *$rem;
  //box-sizing: border-box;


  .header_component {
    background-color: #FFF2C5;
    position: relative;

    .img_bg {
      height: 100%;
      width: 100%;
    }

    .back_share_father {
      position: fixed;
      top: 3.8*$rem;
      width: 100%;
      height: 3 *$rem;
      //background-color: #2A2681;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      .back_btn {
        width: 1.75*$rem;
        height: 1.75*$rem;
        background: url(../../../images/icon/back@2x.png) no-repeat;
        background-size: 100%;
        margin-left: 1.5 *$rem;
      }

      .share {
        width: 2.2*$rem;
        height: 2.2*$rem;
        background: url(../../../images/egg_bt_fenxiang.png) no-repeat;
        background-size: 100%;
        margin-right: 1.8 *$rem;
      }
    }

    .rule_shiming_father {
      position: absolute;
      top: 7.3*$rem;
      width: 100%;
      height: 4 *$rem;
      //background-color: purple;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .huodong_father {
        //background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7 *$rem;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 3 *$rem;
        }

        .title {
          position: absolute;
          width: 100%;
          height: 3 *$rem;
          line-height: 3 *$rem;
          font-size: 1.2 *$rem;
          text-align: center;
          margin-left: -0.3 *$rem;
          margin-top: 0.1 *$rem;
          vertical-align: center;

        }
        .title_shiming{
          position: absolute;
          width: 100%;
          height: 3 *$rem;
          line-height: 3 *$rem;
          font-size: 1.2 *$rem;
          text-align: center;
          margin-left: 0.3 *$rem;
          margin-top: 0.1 *$rem;
          vertical-align: center;
        }
      }
    }

    .bottom_detail_jieduan_daojishi_father {
      position: absolute;
      top: 38.5*$rem;
      width: 100%;
      //bottom: 0;
      //background-color: red;
      display: flex;
      flex-direction: column;
      align-items: center;

      .look_detail_father {
        width: 9.6 *$rem;
        height: 2.6 *$rem;
        border-radius: 1.2 *$rem;
        border: 1px solid #00FDA4;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        span {
          /* 查看详情 */

          font-family: PingFangSC-Regular;
          font-size: 1.5 *$rem;
          font-weight: normal;
          color: #5AF8AB;
        }

        img {
          width: 0.6 *$rem;
          height: 1.1 *$rem;
          margin-left: 0.3 *$rem;
        }
      }


      .activity_jieduan_father {
        display: flex;
        margin-top: 1 *$rem;
        width: 88%;
        height: 5.1 *$rem;
        //background-color: orange;
        flex-direction: column;
        //justify-content: center;
        align-items: center;

        .yuanquan_father {
          width: 86%;
          height: 2 *$rem;
          //background-color: purple;
          display: flex;
          align-items: center;
          justify-content: space-around;

          .yuanquan_span {
            display: inline-block;
            width: 1 *$rem;
            height: 1 *$rem;
            border: 1px solid #5AF8AB;
            border-radius: 0.5 *$rem;
            overflow: hidden;
          }

          .yuanquan_span_shixing {
            display: inline-block;
            width: 1 *$rem;
            height: 1 *$rem;
            background-color: #5AF8AB;
            border-radius: 0.5 *$rem;
            overflow: hidden;
          }

          .yuanquan_line {
            display: inline-block;
            width: 6.2  *$rem;
            height: 0.06 *$rem;
            background-color: #5AF8AB;
            overflow: hidden;
          }
        }

        .jieduan_title_father {
          width: 96%;
          height: 3 *$rem;
          margin-left: 10px;
          //background-color: rebeccapurple;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title_time_father {
            height: 100%;
            //background-color: cornflowerblue;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .title {
              margin-top: 0.2 *$rem;
              color: #8EF4B1;
              font-size: 1.2 *$rem;
            }

            .time {
              margin-top: 0.3 *$rem;
              color: #8EF4B1;
              font-size: 1 *$rem;
            }
          }
        }
      }

      .gongkai_father {
        width: 100%;
        //height: 47 *$rem; //36
        margin-top: 0 *$rem;
        //margin-top: 2.9 *$rem;
        background-color: #092010;
        //background-color: red;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding-bottom: 20px;

        img {
          width: 100%;
          //height: 45 *$rem;
        }

        .content {
          position: absolute;
          width: 80%;
          height: 3.8 *$rem;
          top: 31 *$rem;
          display: flex;
          align-items: center;
          justify-content: center;
          //background-color: purple;

          .goumai_btn {
            height: 3.8 *$rem;
          }

          .btn_title {
            position: absolute;
            width: 80%;
            height: 3.2 *$rem;
            display: flex;
            justify-content: center;
            align-items: center;
            //background-color: yellow;
            .btn_0 {
              font-size: 1.7 *$rem;
              font-family: RuiZiZhenYan;
              font-weight: 400;
              color: #081C0D;
              letter-spacing: 10px;
            }

            .btn_0_hui {
              font-size: 1.7 *$rem;
              font-family: RuiZiZhenYan;
              font-weight: 400;
              color: #55745F;
              letter-spacing: 10px;
            }
          }
        }
      }
    }

  }

  .zhuli_father {
    width: 92%;
    margin-left: 4%;
    margin-top: 2.5 *$rem;
    height: 17 *$rem;
    background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
    border-radius: 17px;
    border: 1px solid #5AF8AB;
    position: relative;

    .zhuli_title_span {
      font-size: 1.5 *$rem;
      margin-left: 2 *$rem;
      margin-top: 1.3 *$rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #8EF4B1;
      line-height: 40px;
    }

    .zhuli_title1_span {
      display: inline-block;
      font-size: 1.1 *$rem;
      margin-left: 2 *$rem;
      color: #8EF4B1;
    }

    .zhuli_rule_span {
      position: absolute;
      font-size: 1.1 *$rem;
      right: 2 *$rem;
      top: 1.3 *$rem;
      color: #8EF4B1;
    }
  }

  .friends_father {
    display: flex;
    width: 90%;
    height: 3.1 *$rem;
    margin-left: 5%;
    margin-top: 1.5 *$rem;
    display: flex;
    align-items: center;
    //justify-content: center;
    .icon_header_pic {
      width: 3.0 *$rem;
      height: 3.0 *$rem;
      object-fit: cover;
      border-radius: 1.5 *$rem;
    }
  }


  .btn_baoming_father0 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .btn_baoming_father1 {
      width: 27.5 *$rem;
      height: 4.45 *$rem;
      margin-top: 1.6 *$rem;
      position: relative;

      .btn_baoming_title0 {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: red;
        margin-top: -0.1 *$rem;

        .span0 {
          font-size: 1.7 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
          letter-spacing: 10px;
          color: #081C0D;
        }

        .span0_hui {
          color: #55745F;
          letter-spacing: 10px;
          font-size: 1.7 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
        }
      }
    }
  }

  .duihuan_father {
    margin-top: 1 *$rem;

    .duihuan_father_top {
      display: inline-block;
      position: relative;
      margin-left: 4%;
      width: 44%;
      height: 12 *$rem;
      background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
      border-radius: 17px;
      border: 1px solid #5AF8AB;

      .span_des {
        display: inline-block;
        margin-left: 1 *$rem;
        margin-top: 1.2 *$rem;
        font-size: 1.5 *$rem;
        font-weight: 600;
        color: #8EF4B1;
        vertical-align: top;
        letter-spacing: 1px;
      }

      .span_second_des {
        display: inline-block;
        font-size: 1.1 *$rem;
        color: #8EF4B1;
        margin-left: 1 *$rem;
        margin-top: 0.6 *$rem;
        letter-spacing: 1px;
        vertical-align: top;
        white-space: pre-line;
        line-height: 16px;
      }

      .btn_duihuan_father {
        width: 9 *$rem;
        height: 3 *$rem;
        position: absolute;
        right: 1 *$rem;
        bottom: 1.8 *$rem;
        display: flex;
        justify-content: center;
        align-items: center;


        .btn_duihuan__des_father {
          width: 100%;
          height: 100%;
          position: absolute;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          //background-color: red;
          margin-top: -0.11 *$rem;

          .span0 {
            font-size: 1.5 *$rem;
            font-family: RuiZiZhenYan;
            font-weight: 400;
            color: #081C0D;
            line-height: 36px;
          }

          .span0_hui {
            font-size: 1.5 *$rem;
            font-family: RuiZiZhenYan;
            font-weight: 400;
            color: #55745F;
            line-height: 36px;
          }
        }


        span {
          font-size: 1.2 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
          color: #081C0D;
          line-height: 36px;
        }
      }
    }
  }

  .choujuanfanbei_father {
    width: 84%;
    margin-left: 8%;
    margin-top: 3.8 *$rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .span_title {
      display: inline-block;
      font-size: 2 *$rem;
      font-family: RuiZiZhenYan;
      font-weight: 400;
      color: #8EF4B1;
      letter-spacing: 2px;
    }

    .span_des {
      display: inline-block;
      margin-top: 1 *$rem;
      font-size: 1.2 *$rem;
      font-family: AppleSystemUIFont;
      color: #8EF4B1;
      letter-spacing: 1px;
    }
  }

  .choujuanfanbei_list_father {

    .choujuanfanbei_list {
      width: 92%;
      height: 7 *$rem;
      margin-left: 4%;
      margin-top: 1 *$rem;

      border-radius: 17px;
      position: relative;

      .choujuanfanbei_list_content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        //background-color: skyblue;
        display: flex;
        flex-direction: row;

        .choujuanfanbei_list_content_left {
          display: flex;
          width: 16%;
          height: 100%;
          //background-color: yellow;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .span_des {
            display: block;
            font-size: 2.1 *$rem;
            font-family: RuiZiZhenYan;
            font-weight: 400;
            color: #001D0B;
          }

          .span_des1 {
            display: block;
            font-size: 2.1 *$rem;
            font-family: RuiZiZhenYan;
            font-weight: 400;
            color: #001D0B;
          }
        }

        .span_title_father {
          //background-color: #2A2681;
          display: flex;
          flex-direction: column;


          .span_title {
            display: inline-block;
            margin-top: 1.5 *$rem;
            margin-left: 0.5 *$rem;
            font-size: 1.5 *$rem;
            font-weight: 600;
            color: #8EF4B1;
            //line-height: 42px;
          }

          .span_title1 {
            display: inline-block;
            margin-top: 0.6 *$rem;
            margin-left: 0.5 *$rem;
            font-size: 1.2 *$rem;
            color: #8EF4B1;
          }
        }

        .btn_father {
          width: 9 *$rem;
          height: 100%;
          position: absolute;
          top: 0;
          right: 1.5 *$rem;
          //background-color: red;
          display: flex;
          align-items: center;
          justify-content: center;

          .btn_txt_father {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            //background-color: red;
            margin-top: -0.1 *$rem;

            .span0 {
              font-size: 1.5 *$rem;
              font-family: RuiZiZhenYan;
              font-weight: 400;
              color: #081C0D;
            }

            .span0_hui {
              font-size: 1.5 *$rem;
              font-family: RuiZiZhenYan;
              font-weight: 400;
              color: #55745F;
            }
          }
        }
      }
    }
  }

  .span_fuli_title {
    width: 100%;
    font-size: 2 *$rem;
    font-family: RuiZiZhenYan;
    font-weight: 400;
    color: #8EF4B1;
    margin-top: 3.5 *$rem;
    letter-spacing: 3px;
    text-align: center;
  }

  .fuli_list_father {
    width: 92%;
    height: 7 *$rem;
    margin-left: 4%;
    margin-top: 1.8*$rem;
    border-radius: 17px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .fuli_list_content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: row;


      .span_title_father {
        width: 66%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        //background-color: red;
        margin-left: 2 *$rem;

        .span_title {
          display: inline-block;
          font-size: 1.5 *$rem;
          font-weight: 600;
          color: #8EF4B1;
        }

        .span_title1 {
          display: inline-block;
          font-size: 1.1 *$rem;
          margin-top: 0.3 *$rem;
          color: #8EF4B1;
          line-height: 15px;
          white-space: pre-wrap;
        }
      }

      .btn_father {
        width: 9 *$rem;
        height: 100%;
        position: absolute;
        top: 0;
        right: 1.1 *$rem;
        //background-color: orange;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn_txt_father {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          //background-color: red;
          margin-top: -0.1 *$rem;

          .span0 {
            font-size: 1.5 *$rem;
            font-family: RuiZiZhenYan;
            font-weight: 400;
            color: #081C0D;
          }

          .span0_hui {
            font-size: 1.5 *$rem;
            font-family: RuiZiZhenYan;
            font-weight: 400;
            color: #55745F;
          }
        }
      }
    }
  }

  .logo_father {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6.7 *$rem;
  }

  .egg_logo {
    width: 15 *$rem;
    height: 2.5 *$rem;
  }
}


