@import 'scss/variables.scss';
.wrap {
  .top {
    overflow: hidden;
    position: relative;
    img {
      float: left;
      width: 100%;
    }
    .rule_button {
      position: absolute;
      right: 0;
      top: 7.4%;
      width: 17%;
    }
  }
  .bottom {
    position: relative;
    overflow: hidden;
    width: 100%;
    .bg {
      float: left;
      width: 100%;
    }
    .title {
      position: absolute;
      top: 6%;
      left: 50%;
      width: 68.5%;
      transform: translateX(-50%);
    }
    .logo {
      position: absolute;
      bottom: 2.6*$rem;
      left: 50%;
      width: 39.6%;
      transform: translateX(-50%);
    }
    .content {
      position: absolute;
      top: 18.1%;
      width: 100%;
      .show_wrap {
        width: 91.5%;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          float: left;
        }
        .text_wrap {
          width: 89.9%;
          height: 81.5%;
          position: absolute;
          background: white;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba(244, 251, 251, 1);
          border-radius: 17px;
          padding: 0px 28px ;
          color: #092AB4;
          overflow: hidden;
          font-size: 1.3*$rem;
          display: flex;
          // justify-content: center;
          align-items: center;
        }
      }
      .bts_wrap {
        width: 82.4%;
        margin: 2.3*$rem auto;
        height: 100px;
        display: flex;
        justify-content: space-around;
        font-weight: 500;
        font-size: 1.5*$rem;
        .bt {
          background: linear-gradient(242deg, #FF40FB 0%, #FFFED3 100%);
          border-radius: 2.15*$rem;
          height: 4.3*$rem;
          line-height: 4.3*$rem;
          padding: 0 3*$rem;
          color: #092AB4;
        }
        .disabled {
          background: #969696;
          border-radius: 2.15*$rem;
          height: 4.3*$rem;
          line-height: 4.3*$rem;
          padding: 0 3*$rem;
          color: #ffffff;
        }
      }
    }
  }
}
.mask {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  .content {
    width: 71.7%;
    background-color: #ffffff;
    border-radius: 1.2*$rem;
    padding: 1.5*$rem;
    .title {
      font-size: 2.2*$rem;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-bottom: 1.5*$rem;
    }
    .text {
      height: 34vh;
      overflow: scroll;
      font-size: 1.1*$rem;
      color: #8A8A8A;
      line-height: 1.6*$rem;
      white-space: pre-wrap;
    }
    .close {
      width: 58.2%;
      margin: 0 auto;
      background: linear-gradient(242deg, #FF40FB 0%, #FFFED3 100%);
      border-radius: 2.15*$rem;
      height: 4.3*$rem;
      line-height: 4.3*$rem;
      padding: 0 3*$rem;
      color: #092AB4;
      text-align: center;
      font-weight: 500;
      font-size: 1.5*$rem;
      margin-top: 2*$rem;
    }
  }
  
}
