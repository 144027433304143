@import 'scss/variables.scss';

.share_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .share_container_content {
    width: 80vw;
    height: 36 *$rem;
    background: white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      margin-top: 1.8 *$rem;
      font-size: 1.8 *$rem;
      font-family: SourceHanSansCN-Bold;
      font-weight: bold;
      color: #000000;
    }

    .content {
      width: 82%;
      height: 23 *$rem;
      margin-top: 10px;
      font-size: 1.2 *$rem;
      line-height: 1.6 *$rem;
      letter-spacing: 1px;
      white-space: pre-line;
      overflow: auto;
      font-family: PingFangSC-Regular;
      font-weight: normal;
      color: #3D3D3D;
      &::-webkit-scrollbar {
        display: flex;
      }
    }

    .sure_btn {
      width: 50%;
      height: 4 *$rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 1.5 *$rem;
      border-radius: 298px;
      opacity: 1;
      background: linear-gradient(104deg, #FF7CFF 0%, #F821FB 105%);
      font-family: PingFangSC-Medium;
      font-size: 1.8 *$rem;
      font-weight: normal;
      color: #FFF;
    }
  }
}




