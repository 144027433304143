@import 'scss/variables.scss';

.online_aptmt_succeed_container {
  display: flex;
  align-items: center;
  background-color: #F7F7F7;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  padding-bottom: 9 *$rem;

  .online_aptmt_succeed_logo {
    width: 6 *$rem;
    height: 6 *$rem;
    margin-top: 5 *$rem;
  }

  .online_aptmt_succeed_des {
    height: 44.8px;
    opacity: 0.8;
    font-family: PingFangSC-Semibold;
    font-size: 1.8 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 1.5 *$rem;
  }
  .online_aptmt_succeed_des1{

    opacity: 0.6;
    font-family: PingFang SC;
    font-size: 1.2 *$rem;
    font-weight: normal;
    line-height:1.0 *$rem;
    white-space: pre-line;
    text-align: center;
    color: #000000;
  }

  .online_aptmt_succeed_des_container {
    width: 92%;
    min-height: 6 *$rem;
    border-radius: 6px;
    border: 0.1*$rem solid white;
    background-color: white;
    padding-bottom: 2 *$rem;
    margin-top: 2 *$rem;
  }
}

.online_aptmt_succeed_des_group {
  display: flex;
  flex-direction: row;
  width: 100%;
  //height: 3 *$rem;
  align-items: center;
  //background-color: red;
  margin-top: 2 *$rem;

  span {
    display: inline-block;
    margin-left: 0.2 *$rem;
    font-family: PingFang SC;
    font-size: 1.2 *$rem;
    font-weight: normal;
    letter-spacing: 0em;
  }

  :nth-child(1) {
    width: 8 *$rem;
    text-align: right;
    color: #9E9E9E;
  }
}

.line {
  width: 92%;
  margin-left: 4%;
  margin-top: 1.8 *$rem;
  height: 0.8px;
  opacity: 0.7;
  background: #D8D8D8;
}
.online_aptmt_bottom_container {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 9 *$rem;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;

  .online_aptmt_submit {
    width: 90%;
    height: 4.5 *$rem;
    border-radius: 45px;
    opacity: 1;
    margin-left: 5%;
    margin-top: 1 *$rem;
    background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Medium;
    font-size: 1.6 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #614D1A;
  }
}
.dialog_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .dialog_title {
    opacity: 1;
    font-family: PingFangSC-Regular;
    font-size: 1.4 *$rem;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
  }

  .dialog_column_line {
    width: 96%;
    height: 1px;
    opacity: 0.7;
    margin-top: 2 *$rem;
    background: #D8D8D8;
  }
  .dialog_btns_container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 4 *$rem;
    margin-top: 0.5 *$rem;
    //background-color: red;
    .dialog_row_cancel_btn{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #999999;
      font-size: 1.5 *$rem;
    }
    .dialog_row_line{
      width: 1px;
      height: 80%;
      background-color: #D8D8D8;
    }
  }
}
