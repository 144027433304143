@import 'scss/variables.scss';

.activityContainer {
    user-select: none;
}

.banner {
    width: 100%;
    background-image: url('./images/banner@2x.png');
    background-size: 100% 100%;
}

.getMoreTimes {
    width: 32 * $rem;
    height: 4.1 *$rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('./images/record-icon@2x.png');
    background-size: 100% 100%;
    font-size: 1.6*$rem;
    color: #FFEBC4;
    position: absolute;
    left: 0;
    right: 0;
    top: 83.3 * $rem;
    margin: 0 auto;
}

.ruleContainer {
    background-color: #FFF9EA;
    position: absolute;
    width: 100%;
    height: 100%;
    //height: 66.7*$rem;
    overflow-y: auto;

    .content {
        width: 34.5*$rem;
        height: 38.5*$rem;
        margin: 1.5*$rem;

        .text_mark {
            color: #000;
            font-size: 1.3*$rem;
            line-height: 2*$rem;
            font-weight: bold;
        }

        .text {
            color: #000;
            font-size: 1.3*$rem;
            line-height: 2*$rem;
            //margin-bottom: 0.5*$rem;
        }

        .text_gap {
            padding-bottom: 2* $rem;
        }
    }
}

.drawWrap {
    position: relative;
    height: 88.9*$rem;
    width: 100%;
    background: url('./images/draw-box@2x.png') no-repeat;
    background-size: 100% 100%;

    // opacity: 0.3;
}

.drawLine {
    width: 100%;
    position: absolute;
    top: 76*$rem;
}

.back_rule {
    top: 0;
    position: fixed;
    z-index: 20;
    width: 100%;
}

.backWrap {
    height: 4.4*$rem;
    display: flex;
    align-items: center;
}

.remainBox {
    display: flex;

    .remainItem {
        flex: 1;
        // border: 1px solid red;
        // height: 80px;
        margin-top: 0.7 * $rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3*$rem;
        font-weight: 400;
        color: #4C290A;
        line-height: 2*$rem;
    }

    .itemInfo {
        font-weight: 600;
    }
}

.activietyRules {
    font-size: 1.6* $rem;
    color: #FFF9E2;
    // padding-top: 2* $rem;
    margin-left: 24.8* $rem;
}

.scoreWrap {
    padding: 0.75 * $rem;
    background: linear-gradient(339deg, #AE41D3 0%, #EE7779 36%, #FF8562 48%, #FA7567 59%, #E0247F 100%);
}

.picWrap {
    text-align: center;
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-top: 2.6 *$rem;

    .my_icon {
        width: 28.6* $rem;
        height: 4.3* $rem;
        ;
    }
}

.seizePosition {
    position: relative;
    top: 71.8*$rem;
}

.inviteNewWrap {
    height: 18.8 * $rem;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px 3px rgba(221, 77, 10, 0.48);
    border-radius: 0.4* $rem;
    text-align: center;

    .inviteTitle {
        font-size: 1.5* $rem;
        font-weight: 500;
        color: #4C290A;
        opacity: 1 !important;
    }

    .inviteTitleWrap {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        width: 15 * $rem;
        height: 3 * $rem;
        background: rgba(255, 185, 61, 0.39);
        border-radius: 0 0 $rem $rem;
    }
}

.inviteButton {
    margin-top: 1.6 * $rem;
    display: flex;

    // width: 7.8 *$rem;
    img {
        width: 4.8 * $rem;
        height: 4 * $rem;
    }

    .inviteInfo {
        flex: 1;
        align-items: center;
        justify-content: center;
        font-size: 1.3* $rem;
        font-weight: 500;
        color: #A75711;
        line-height: 1.85 * $rem;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}



.footLog {
    text-align: center;

    .footerImage {
        margin: 6.6*$rem 0 8.2*$rem 0;
        width: 13.45 * $rem;
        height: 2.7 * $rem;
    }
}

// modal
.colsePic {
    img {
        width: 2.6* $rem;
        height: 2.6* $rem;
    }
}

.modal {
    flex: 1;
    background: rgba(0, 0, 0, 0.8);
    padding: 1.95*$rem 1.8*$rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    position: fixed;
    height: 100vh;
    z-index: 9999;
    overflow: hidden;

    .modalContent {
        // width: 375px;
        max-width: 100%;
        overflow: hidden;
        position: relative;
        min-height: $rem*10;
        text-align: center;
    }
}



.listHeader {
    width: 100%;
    height: 4.5 * $rem;
    background: #ffff;
    text-align: center;
    font-size: 1.6 * $rem;
    font-weight: 600;
    color: #5F350F;
    line-height: 4.5 * $rem;
    margin-top: 0.75 *$rem;
    border-radius: 0.4 * $rem;
}

.exchangeItemWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.productWrap:nth-child(even) {
    margin-left: 0.8 *$rem;
}

.productWrap {
    margin-top: 0.8 *$rem;
    width: 17.5*$rem;
    height: 23.9*$rem;
    background: white;
    border-radius: 0.4 * $rem;
    overflow: hidden;

    .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #EFEBE6;
        height: 17.5*$rem;
        text-align: center;
        position: relative;

        .soldOut {
            position: absolute;
            top: 0;
            left: 0;
            width: $rem*5.5;
            height: $rem*5.5;
        }

        .img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .ItemContent {
        padding: $rem;

        .title {
            color: $deepGray;
            font-size: 1.4*$rem !important;
            font-weight: 500;
            line-height: 1.7*$rem;
            width: 15.5 * $rem;
            height: 1.7 * $rem;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .bottomView {
            display: flex;
            align-items: center;
            margin-top: $rem;
            height: 1.7*$rem;

            .text1 {
                font-size: 1.4*$rem;
                font-weight: bold;
                color: #FA6400;
            }

            .unit {
                font-size: $rem;
                margin-left: 0.1*$rem;
                color: #FA6400;
            }

            .text2 {
                margin-left: 0.5*$rem;
                font-size: 1.4*$rem;
                color: #999999;
                text-decoration: line-through;
            }
        }
    }
}

.back_icon {
    margin-left: 0.9 * $rem;
    width: 2 * $rem;
    height: 2 * $rem;
}

.back_area {
    width: 5* $rem;
    height: 100%;
    display: flex;
    align-items: center;
}

.introRegular {
    font-size: 1.3 * $rem;
    font-weight: 400;
    color: #DA4706;
    margin-top: 2.2 * $rem;
}

.button_area {
    display: flex;
    height: 4.85*$rem;
    text-align: center;
    font-size: 1.6* $rem;
    font-weight: 500;
    color: #4C290A;
    position: absolute;
    top: 70* $rem;
    z-index: 500;
    flex-direction: row;
    width: 100%;
}

// 目前有点问题
.button_item {
    flex: 1;
}




// 抽奖部分
.integralDrawWrap {
    position: absolute;
    top: 39.81*$rem;
    left: 0;
    right: 0;
    // z-index: 1;
    // flex: 1;
    // background: url('./img/background.png') bottom center no-repeat;
    background-size: cover;

    .title {
        margin-top: $rem*5;
        height: $rem*2.75;
        text-align: center;

        img {
            max-height: 100%;
        }
    }

    .drawWraps {
        // background: url('./img/Integral_draw-box.png') center center no-repeat;
        background-size: contain;
        width: $rem*33.5;
        height: $rem*36.6;
        margin: $rem*0.21 auto 0;

        .drawTitle {
            text-align: center;
            line-height: $rem*1.9;
            font-size: $rem*1.3;
            color: #FA6400;
            height: $rem*3.3;
            padding-top: 0.7*$rem;
        }

        .drawBorder {
            display: flex;
            flex-wrap: wrap;
            padding: $rem*2.2;
            position: relative;
            align-items: space-between;
            justify-content: space-between;

            &.light {
                .circle {
                    &:nth-child(even) {
                        background-image: linear-gradient(255deg, #DA4503 2%, #FF924A 100%);
                    }

                    &:nth-child(odd) {
                        background-image: linear-gradient(265deg, #FFDE7F 0%, #FFF3D3 100%);
                    }
                }
            }

            .circle {
                display: inline-block;
                box-shadow: 0 $rem*0.1 $rem*0.15 0 rgba(163, 65, 0, 0.30);
                width: $rem*0.8;
                height: $rem*0.8;
                border-radius: 50%;
                position: absolute;

                &:nth-child(odd) {
                    background-image: linear-gradient(255deg, #DA4503 2%, #FF924A 100%);
                }

                &:nth-child(even) {
                    background-image: linear-gradient(265deg, #FFDE7F 0%, #FFF3D3 100%);
                }

                &:nth-child(1) {
                    top: 0.5*$rem;
                    left: 2.35*$rem;
                }

                &:nth-child(2) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+3.5*$rem;
                }

                &:nth-child(3) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+2*3.5*$rem;
                }

                &:nth-child(4) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+3*3.5*$rem;
                }

                &:nth-child(5) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+4*3.5*$rem;
                }

                &:nth-child(6) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+5*3.5*$rem;
                }

                &:nth-child(7) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+6*3.5*$rem;
                }

                &:nth-child(8) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+7*3.5*$rem;
                }

                &:nth-child(9) {
                    top: 0.5*$rem;
                    left: 2.35*$rem+8*3.5*$rem;
                }

                &:nth-child(10) {
                    top: 2.6*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(11) {
                    top: 2.6*$rem+3.5*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(12) {
                    top: 2.6*$rem+2*3.5*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(13) {
                    top: 2.6*$rem+3*3.5*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(14) {
                    top: 2.6*$rem+4*3.5*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(15) {
                    top: 2.6*$rem+5*3.5*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(16) {
                    top: 2.6*$rem+6*3.5*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(17) {
                    top: 2.6*$rem+7*3.5*$rem;
                    left: 32.2*$rem;
                }

                &:nth-child(18) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+8*3.5*$rem;
                }

                &:nth-child(19) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+7*3.5*$rem;
                }

                &:nth-child(20) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+6*3.5*$rem;
                }

                &:nth-child(21) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+5*3.5*$rem;
                }

                &:nth-child(22) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+4*3.5*$rem;
                }

                &:nth-child(23) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+3*3.5*$rem;
                }

                &:nth-child(24) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+2*3.5*$rem;
                }

                &:nth-child(25) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+1*3.5*$rem;
                }

                &:nth-child(26) {
                    top: 29.2*$rem;
                    left: 2.35*$rem+0*3.5*$rem;
                }

                &:nth-child(34) {
                    top: 2.6*$rem;
                    left: 0.5*$rem;
                }

                &:nth-child(33) {
                    top: 2.6*$rem+3.5*$rem;
                    left: 0.5*$rem;
                }

                &:nth-child(32) {
                    top: 2.6*$rem+2*3.5*$rem;
                    left: 0.5*$rem;
                }

                &:nth-child(31) {
                    top: 2.6*$rem+3*3.5*$rem;
                    left: 0.5*$rem;
                }

                &:nth-child(30) {
                    top: 2.6*$rem+4*3.5*$rem;
                    left: 0.5*$rem;
                }

                &:nth-child(29) {
                    top: 2.6*$rem+5*3.5*$rem;
                    left: 0.5*$rem;
                }

                &:nth-child(28) {
                    top: 2.6*$rem+6*3.5*$rem;
                    left: 0.5*$rem;
                }

                &:nth-child(27) {
                    top: 2.6*$rem+7*3.5*$rem;
                    left: 0.5*$rem;
                }
            }

            .drawMask {
                position: absolute;
                top: $rem*1.8;
                left: $rem*1.8;
                right: $rem*1.8;
                bottom: $rem*2;
                background: rgba(0, 0, 0, 0.5);
                z-index: 3;
                border-radius: $rem;
            }

            .drawStart {
                width: $rem*9.5;
                height: $rem*8;
                display: block;
                background-image: linear-gradient(180deg, #FFAD79 0%, #FF8356 88%);
                box-shadow: 0 0.5*$rem 0 0 #EE7141,
                    inset 0 0 0.3*$rem 0 rgba(255, 242, 210, 0.30);
                border-radius: $rem;
                margin-bottom: $rem*0.8;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                position: relative;

                h2 {
                    font-size: 1.8*$rem;
                    color: #5F350F;
                    line-height: 2.1*$rem;
                    font-weight: 600;

                }

                p {
                    font-size: 1.3*$rem;
                    color: #C56027;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 2*$rem;
                    background: rgba(255, 255, 255, 0.2);
                    border-radius: $rem;
                    width: $rem*6;
                    height: $rem*2;
                    margin-top: $rem*0.5;
                }
            }

            .drawButton {
                width: $rem*9.5;
                height: $rem*8;
                display: block;
                background: #FFF5D9;
                box-shadow: 0 0.5*$rem 0 0 #EBCC75;
                border-radius: $rem;
                flex-shrink: 1;
                margin-bottom: $rem*0.8;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &.active {
                    z-index: 4;
                }

                p {
                    font-size: 1.2*$rem;
                    color: #C56027;
                    line-height: 1.9*$rem;
                    margin-top: $rem*0.205;
                }

                img {
                    width: $rem*4.4;
                    height: $rem*4.4;
                }
            }
        }
    }

    .contentStyle {
        height: '160px';
        color: '#fff';
        line-height: '160px';
        text-align: 'center';
        background: '#364d79';
    }

    // 轮播图样式
    .swiperWrap {
        position: absolute;
        left: 50%;
        transform: translate(-50%);
        margin-top: -2.3*$rem;
    }

    .messageColor {
        text-align: center;
        height: 20 * $rem;
        width: 22.6* $rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.3*$rem;
        line-height: 1.4*$rem;
        ;
        color: #FFF5D9;
    }


    .footer {
        display: flex;
        justify-content: space-between;
        padding: 0 3.7*$rem;

        button {
            width: 13*$rem;
            height: 4*$rem;
            line-height: 4*$rem;
            background-image: linear-gradient(180deg, #FFD166 0%, #FFAC1E 100%);
            box-shadow: inset 0 -0.3*$rem 0.8*$rem 0 #F2832A;
            border-radius: 2*$rem;
            font-size: 1.6*$rem;
            color: #FA6400;
        }
    }
}