@import 'scss/variables.scss';

.container {
  width: 100%;
  min-height: 100vh;
  background: #ECEEF0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5 * $rem;

  .item {
    margin-bottom: 1.5 * $rem;
    width: 33.5 * $rem;
    height: 7.75 * $rem;
    background: #FFFFFF;
    border-radius: $rem;
    padding: 0 1.5 * $rem;
    flex-direction: row;
    display: flex;
    align-items: center;

    .labelContent {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      height: 4.75 * $rem;

      .label1 {
        font-size: 1.3 * $rem;
        font-weight: 400;
        color: #999999;
        line-height: 1.85 * $rem;
      }

      .label2 {
        font-size: 1.4 * $rem;
        font-weight: 400;
        color: #333333;
        line-height: 2 * $rem;
      }
    }

    .btn1 {
      width: 6.65 * $rem;
      height: 2.8 * $rem;
      line-height: 2.8 * $rem;
      background: #641F6D;
      border-radius: 1.4 * $rem;
      color: #FFFFFF;
      font-size: 1.2 * $rem;
      margin-right: 0.8 * $rem;
    }

    .btn2 {
      width: 6.65 * $rem;
      height: 2.8 * $rem;
      line-height: 2.8 * $rem;
      background: #FFFFFF;
      color: #641F6D;
      font-size: 1.2 * $rem;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        border: 0.1 * $rem solid #641F6D;
        border-radius: 2.8 * $rem;
        display: block;
        box-sizing: border-box;
        width: 200%;
        height: 200%;
        top: 0;
        left: 0;
        transform: scale(0.5);
        transform-origin: 0 0;
      }
    }

    .btn3 {
      width: 6.65 * $rem;
      height: 2.8 * $rem;
      line-height: 2.8 * $rem;
      background: #999999;
      border-radius: 1.4 * $rem;
      color: #FFFFFF;
      font-size: 1.2 * $rem;
    }
  }
}