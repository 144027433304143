@import 'scss/variables.scss';

.share_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .share_container_content {
    width: 74vw;
    height: 20 *$rem;
    background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
    border-radius: 12px;
    border: 1px solid #5AF8AB;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 1.5 *$rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #8EF4B1;
      margin-top: 2.7 *$rem;
    }

    .little_title {
      width: 80%;
      height: 40%;
      margin-top: 1.1 *$rem;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        font-size: 1.1  *$rem;
        color: #8EF4B1;
        line-height: 22px;
      }
    }

    .btn_father {
      width: 10 *$rem;
      height: 2.5 *$rem;
      margin-top: 0.7 *$rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid #8EF4B1;

      .count {
        font-size: 1.5 *$rem;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 300;
        color: #8EF4B1;
      }

      img {
        width: 3 *$rem;
        height: 100%;

      }
    }

    .cancel_sure_father {
      width: 80%;
      height: 3 *$rem;
      //background-color: red;
      margin-top: 2.8 *$rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .cancle_btn_father {
        width: 9 *$rem;
        height: 2.9 *$rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: yellow;

        img {

        }

        .text_btn_father {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .text_btn_cancel {
          font-size: 1.3 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
          color: #8EF4B1;
        }

        .text_btn_sure {
          font-size: 1.3 *$rem;
          font-family: RuiZiZhenYan;
          font-weight: 400;
          color: #081C0D;
        }
      }
    }
  }
}




