@import 'scss/variables.scss';

.signSuccessModal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  .signModalContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20.3*$rem;
    height: 21.4*$rem;
    background: url('../imgs/sign-sucess-modal-bg.png') no-repeat;
    background-size: 100% 100%;
    color: #EA0000;
    .signModalTitle {
      margin-top: 3*$rem;
      font-size: 1.75*$rem;
    }
    .signGetPoints {
      margin-top: 6*$rem;
      margin-bottom: 0.55*$rem;
      font-size: 1.5*$rem;
      .addFlag {
        margin: 0 0.5*$rem;
        color: #7E5B23;
        font-size: 1.2*$rem;
      }
      span {
        font-size: 2.2*$rem;
        font-weight: bold;
        color: #EA0000;
        i {
          font-size: 1.2*$rem;
          font-weight: bold;
        }
      }
    }
    .continuitySign {
      font-size: 1.1*$rem;
      color: #7E5B23;
    }
    .signConfirmBtn {
      margin-top: 0.8*$rem;
      width: 9.4*$rem;
      height: 2.5*$rem;
      line-height: 2.5*$rem;
      text-align: center;
      border-radius: 1.25*$rem;
      background: linear-gradient(105deg, #664A01 0%, #0B0800 72%);
      color: #FFECBB;
      font-size: 1.4*$rem;
    }
    img {
      margin-top: 3.5*$rem;
      width: 1.8*$rem;
      height: 1.8*$rem;
    }
  }
}