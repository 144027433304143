@import 'scss/variables.scss';

.container {
    padding: 2*$rem;

    h1 {
        font-size: 1.6*$rem;
        font-weight: bold;
        color: $deepGray;
        line-height: 3*$rem;
    }

    p {
        font-size: 1.4*$rem;
        color: $deepGray;
        line-height: 3*$rem;
    }

    button {
        display: block;
        @include lightButton($rem*33, $rem*4, $rem*1.4);
        margin-bottom: 1.4*$rem;

        &:active {
            @include deepButton($rem*33, $rem*4, $rem*1.4);
        }
    }

    .modal {
        background: rgba(0, 0, 0, 0.7);
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: $rem;
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        // justify-content: center;

        button {
            margin-top: 2*$rem;
            @include deepButton($rem*10, $rem*3.3, $rem*1.6);
        }
    }

    .canvasExample {
        background: white;
        padding: $rem*2;
    }
}