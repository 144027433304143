@import 'scss/variables.scss';
.containerBg {
  position: absolute;
  top: 0;
  left: 0;
  //height: 100vh;
  width: 100vw;
  background-color: #453B8D;
  //overflow-x: hidden;
  //overflow-y: scroll;
  //-webkit-overflow-scrolling: touch;
  //body::-webkit-scrollbar {
  //  display: none;
  //}
}
.container {
  width: 37.5*$rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    z-index: 1;
    width: 100%;
    height: 31.65*$rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    .backBtn {
      position: absolute;
      top: 6*$rem;
      left: 1.65*$rem;
      width: 3*$rem;
      height: 2*$rem;
      .img {
        width: 0.75*$rem;
        height: 1.5*$rem;
        background: url("../../../images/icon/back-svg-white.svg");
        background-size: 100% 100%;
      }
    }
    .ruleBg {
      position: absolute;
      top: 6.5*$rem;
      right: 0;
      width: 6.24*$rem;
      height: 2.7*$rem;
      background: rgba(77, 72, 171, 1);
      box-shadow: inset 0 0.05*$rem 1.45*$rem 0 #2A2681;
      border-radius: 5*$rem 0 0 5*$rem;

      text-align: center;
      font-size: 1.2*$rem;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 2.7*$rem;
    }
    .topBg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 50.3*$rem;
      background: url("images/topBg.png");
      background-size: 100% 100%;
    }

    .dateBg {
      margin-bottom: 1.2*$rem;
      width: 23.45*$rem;
      height: 2.4*$rem;
      background: linear-gradient(180deg, #DAA0DA 0%, #766BBC 100%);
      box-shadow: inset 0 0.3*$rem 1.55*$rem 0 #FFFFFF;
      border-radius: 1.9*$rem;
      opacity: 0.8;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .dateText {
        font-size: 1.2*$rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.65*$rem;
      }
    }

  }
  .btnArea {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0 4.9*$rem 0 3.35*$rem;
    height: 4.3*$rem;

    .btn {
      font-size: 1.4*$rem;
      color: #FFFFFF;
      line-height: 2*$rem;
      text-align: center;
      text-decoration:underline;
    }
  }

  .bottom {
    width: 36.9*$rem;
    height: 39.1*$rem;
    background: url("images/bottomBg.png");
    background-size: 100% 100%;
    position: relative;
    .btn1 {
      position: absolute;
      left: 4.7*$rem;
      bottom: 8.1*$rem;
      width: 11.35*$rem;
      height: 3.65*$rem;
      background: url("images/btnGo.png");
      background-size: 100% 100%;
    }
    .btn2 {
      position: absolute;
      right: 4.2*$rem;
      bottom: 8.1*$rem;
      width: 11.35*$rem;
      height: 3.65*$rem;
      background: url("images/btnGo.png");
      background-size: 100% 100%;
    }
  }

  .slogan {
    width: 14.9*$rem;
    height: 2.5*$rem;
    background: url("images/slogan.png");
    background-size: 100% 100%;
    margin-bottom: 2.65*$rem;
    margin-top: 3.35*$rem;
  }
}

.ruleContainer {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 104;
  touch-action: none;
  user-select: none;

  .mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);

    .modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: $rem*26.9;
      height: 37.15*$rem;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 1.2*$rem;
      padding: 0 1.5*$rem;

      .modalTitle {
        font-size: 2.2*$rem;
        color: $deepGray;
        margin: 1.5*$rem auto;
        text-align: center;
        line-height: 3.1*$rem;
      }

      .modalContent {
        color: $lightGray;
        line-height: 1.6*$rem;
        font-size: 1.1*$rem;
        flex: 1;
        overflow-y: auto;
        white-space: pre-wrap;
      }

      .modalFooter {
        height: $rem*7;
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          width: 15.6*$rem;
          height: 3.35*$rem;
          background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
          box-shadow: 0.05*$rem 0.15*$rem 0.4*$rem 0px #FFBE92;
          border-radius: 2.2*$rem;
          color: white;
          font-size: 1.4*$rem;
        }
      }
    }
  }
}
