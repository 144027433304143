@import 'scss/variables.scss';

.go_aptmt_container {
  width: 100%;
  height: 100%;
  //position: relative;
  background-color: #F7F7F7;
  //

  .pddBottom {
    padding-bottom: 11.5 *$rem;
  }

  .go_aptmt_bottom_container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 11 *$rem;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;

    .go_aptmt_bottom_des {
      font-family: Source Han Sans CN;
      font-size: 1.2 *$rem;
      font-weight: normal;
      letter-spacing: 0em;
      color: #9E9E9E;
      margin-top: 1 *$rem;
    }

    .go_aptmt_bottom_btn {
      width: 90%;
      height: 4.5 *$rem;
      border-radius: 45px;
      margin-top: 1 *$rem;
      background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFangSC-Medium;
      font-size: 1.8 *$rem;
      letter-spacing: 0px;
      color: #614D1A;
    }
  }
}

.rules_iframe {
  width: 100%;
  height: 100%;
  background-color: red;
}

.dialog_yy_alert_container {

}
