@import 'scss/variables.scss';
.container{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.hide {
    visibility: hidden;
  }

  .giftWrap {
    display: flex;
    flex-direction: column;
    width: 32*$rem;
    align-items: center;
    background-color: #ffffff;
    border-radius: 1.2*$rem;

    .des {
      font-size: 1.4*$rem;
      color: #666666;
      line-height: 2*$rem;
      margin-top: 0.4*$rem;
    }
    .title {
      font-size: 2*$rem;
      color: #333333;
      margin-top: 2.7*$rem;
      margin-bottom: 0.4*$rem;
    }

    .img {
      width: 30*$rem;
      height: 14*$rem;
      margin-top: 1.05*$rem;
      margin-bottom: $rem;
    }
    .text2 {
      font-size: 1.4*$rem;
      color: #333333;
      line-height: 2*$rem;
      text-align: center;
      width: 23.8*$rem;
      white-space: pre-wrap;
    }

    .btnBg {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      margin: 3.4*$rem 0 2.5*$rem;
      padding: 0 3.2*$rem;
      width: 100%;
      height: 3.6*$rem;
      .button {
        width: 11.4*$rem;
        height: 3.6*$rem;
        text-align: center;
        line-height: 3.6*$rem;
        color: #ffffff;
        font-size: 1.6*$rem;
        &.icon1 {
          background: url("images/btnIcon.png");
          background-size: 100% 100%;
        }

        &.icon2 {
          background: url("images/btnIcon2.png");
          background-size: 100% 100%;
        }
      }

    }


  }
}
