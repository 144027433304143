@import 'scss/variables.scss';

.container {
  background-color: #F7F7F7;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow:hidden;
  .nav {
    height: 4.4*$rem;
    overflow-x: scroll;
    overflow-y: hidden;
    width: 100%;
    background-color: #ffffff;
    &::-webkit-scrollbar{
      display: none;
    }
    .tabs {
      display: flex;
      height: 100%;
      align-items: center;
      .item {
        height: 100%;
        text-align: center;
        margin: 0 1.25*$rem;
        position: relative;
        .text {
          margin-top: 1.5*$rem;
          display: block;
          color: #999999;
          font-size: 1.4*$rem;
          white-space: nowrap;
          &.active {
            font-size: 1.6 * $rem;
            font-weight: bold;
            color: #333333;
          }
        }
        .activeBar {
          width: 3.2 * $rem;
          height: 0.4 * $rem;
          background: #D4BA77;
          border-radius: 0.2 * $rem;
          position: absolute;
          left: 0;
          bottom:0;

        }
      }
    }
  }

  .swiper {
    flex: 1;
    width: 100%;
    margin: auto auto 5 * $rem;
    height: 10px;
    position: relative;

    .wrap {
      padding: 1.0 * $rem 1.5*$rem 0;
      width: 100%;
      position: relative;
      height: 100%;
      overflow-y:scroll;
      .cell {
        width: 34.5*$rem;
        height: 6.75 * $rem;
        background-color: #ffffff;
        margin-bottom: 1.0 *$rem;
        border-radius: 0.6 * $rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        padding-left: 1.5 * $rem;
        padding-right: 2 * $rem;
        .cellContent {
          display: flex;
          flex-direction: column;
          justify-content: center;
          .title {
            font-size: 1.4 * $rem;
            font-weight: 600;
            color: #333333;
            line-height: 1.4 * $rem;
            margin-bottom: 0.7 * $rem;
          }
          .date {
            font-size: 1 * $rem;
            font-weight: 400;
            color: #333333;
            line-height: 1.1 * $rem;
          }
        }
        .fill {
          flex:1;
        }
        .num {
          font-size: 2.2 * $rem;
          font-weight: 500;
          color: #D4BA77;
          line-height: 2.65 * $rem;
          &.minus {
            color: #EA0000;
          }
        }
      }
    }
  }

  .button {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5 * $rem;
    background: #FFFFFF;
    font-size: 1.6 * $rem;
    color: #333333;
    line-height: 5 * $rem;
    text-align: center;
  }
}