@import 'scss/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    background-color: #F5BD23;

    &.fullScreen {
        .inviterWrapper {
            padding-bottom: 2.5*$rem;
            height: 8.8*$rem;
        }

        .voteList {
            padding-bottom: $rem*2;
        }
    }

    .header {
        width: 100%;
    }

    .headImg {
        width: 100%;
        height: 46.3 * $rem;
    }

    .ruleButton {
        width: 7.95* $rem;
        height: 3.65* $rem;
        position: absolute;
        top: 4.85* $rem;
        right: 0;
        font-size: 1.6* $rem;
        color: #333;
        text-align: center;
        line-height: 3.65* $rem;
        background: url('./images/ruleButton.png') no-repeat;
        background-size: 100% 100%;
    }

    .voteTime {
        position: absolute;
        left: 9* $rem;
        top: 40.95* $rem;
        color: #fff;
        font-size: 1.2*$rem;
        line-height: 1.65*$rem;
    }

    .voteWrapper {
        width: 34.5*$rem;
        display: flex;
        flex-direction: column;
        align-self: center;
        margin-top: 2.6* $rem;
        box-shadow: 0.25*$rem 0.1*$rem 0.1*$rem 0 #F5E4AE;
        margin-left: -0.25*$rem;
    }

    .voteContent {
        width: 33.5*$rem;
        background-color: #fff;
        margin-left: $rem;
    }

    .voteTit {
        width: 34.5*$rem;
        height: 7.7* $rem;
        background: url("./images/bg_head@2x.png") no-repeat;
        background-size: 100% 100%;

    }

    .voteTit p {
        padding-left: 1.9* $rem;
        font-size: 2.5*$rem;
        padding-top: 3.6* $rem;
        color: #fff;
    }

    .voteList {
        margin-top: 1.7* $rem;
    }

    .sloganItem {
        margin-left: 0.85*$rem;
        margin-bottom: 1.9*$rem;
        position: relative;
    }

    .voteList .sloganItem:last-child {
        margin-bottom: 10*$rem;
    }

    .itemContent {
        width: 31.5*$rem;
        min-height: 6.95*$rem;
        background: #FFF8E3;
        box-shadow: 0.2*$rem 0.2*$rem 0 0 #333333;
        border-radius: 2*$rem;
        border: 0.5*$rem solid #FBCA48;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 1.5*$rem;
    }

    .sloganContent {
        width: 19.4* $rem;
        margin-top: 1.3* $rem;
    }

    .sloganText {
        width: 19.4* $rem;
        line-height: 2* $rem;
        font-size: 1.6* $rem;
        margin-bottom: 0.8* $rem;
    }

    .voteNum {
        height: 1.65* $rem;
        font-size: 1.2* $rem;
        font-weight: 400;
        color: #9A9A9A;
        line-height: 1.65* $rem;
        margin-bottom: 0.95* $rem;
    }

    .voteButton {
        width: 6.9* $rem;
        height: 3.65* $rem;
        background: url("./images/voteButton.png") no-repeat;
        background-size: 100% 100%;
        color: #333;
        font-size: 1.6* $rem;
        text-align: center;
        line-height: 3.65* $rem;
        align-self: center;
    }

    .voteButtonGray {
        filter: Gray;
        -webkit-filter: grayscale(100%);
    }


    .flag {
        width: 3.3* $rem;
        height: 2.9* $rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: -1.2* $rem;
        left: -1.4* $rem;
    }

    .crownOne {
        background-image: url("./images/crowna@2x.png");
    }

    .crownTwo {
        background-image: url("./images/crownb@2x.png");
    }

    .crownThree {
        background-image: url("./images/crownc@2x.png");
    }

    .inviterWrapper {
        width: 100%;
        height: 6.6*$rem;
        background: #FFFFFF;
        box-shadow: -3.5*$rem 0 0.8*$rem 0 #F5BD23;
        position: fixed;
        bottom: 0;
        left: 0;
    }



    .inviteButton {
        width: 35.5* $rem;
        height: 5.4* $rem;
        background: url("./images/button.png") no-repeat;
        background-size: 100% 100%;
        color: #684500;
        font-size: 1.6* $rem;
        text-align: center;
        line-height: 5.4* $rem;
        margin-top: 0.6* $rem;
        margin-left: $rem;
    }
}

//活动规则
.rulesContainer {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
    background: #FFFBF2;


    .ruleContent {
        height: 34.5* $rem;
        padding: 69* $rem;
        padding: 1.5* $rem;
        font-size: 1.3* $rem;
        font-weight: 400;
        color: #000;
    }

    .ruleContent p span {
        line-height: 2 * $rem;
    }
}