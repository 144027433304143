@import 'scss/variables.scss';

.container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    .mask {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);

        .modal {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: $rem*33.5;
            // height: 39.5*$rem;
            display: flex;
            flex-direction: column;
            background: white;
            border-radius: 1.2*$rem 1.2*$rem 1.2*$rem 1.2*$rem;
            overflow: hidden;
            padding-bottom: 3*$rem;

            .modalTitle {
                width: 100%;
                position: relative;

                h1 {
                    line-height: 3*$rem;
                    font-size: 2*$rem;
                    font-weight: bold;
                    color: #7E5B23;
                    position: absolute;
                    top: $rem*2.8;
                    text-align: center;
                    width: 100%;
                }
            }

            .modalContent {
                width: 15*$rem;
                margin: 2*$rem auto 0;
            }

            .modalFooter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 1.5*$rem;

                button {
                    width: 14*$rem;
                    height: 3.5*$rem;
                    background: linear-gradient(99deg, #D4BA77 4%, #FFEAB4 98%);
                    border-radius: 1.75*$rem;
                    color: #614D1A;
                    margin-top: 1*$rem;
                    font-size: 1.75*$rem;
                    font-weight: bold;

                    &.disable {
                        background: #D8D8D8;
                        color: #808080;
                    }
                }
            }
        }
    }
}