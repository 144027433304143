@import 'scss/variables.scss';

.marquee-container {
  width: 100%;
  .marquee-wrapper {
    height: $rem * 14.5;
    overflow: hidden;
    .marquee-content {
      &.animate {
        transition: all .5s;
      }
      margin-top: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 100%;
      .marquee-content-itm {
        padding-bottom: 1 * $rem;
        p {
          color: rgba(242, 50, 228, 1);
          font-size: 1.3 * $rem;
          line-height: 2 * $rem;
          max-height: $rem * 4;
          text-overflow: ellipsis;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
