@import 'scss/variables.scss';

.world-cup-progress {
  .progress-top {
    margin-bottom: 0.35*$rem;
    display: flex;
    justify-content: space-between;
    font-size: 1*$rem;
    color: #FFFFFF;
    .common-style {
      padding: 0 0.5*$rem;
      text-align: center;
      line-height: 1.5*$rem;
      height: 1.5*$rem;
      min-width: 5*$rem;
      border-radius: 1.5*$rem;
    }
    .success {
      background: rgba(0, 67, 158, 0.7);
    }
    .flat {
      background: rgba(61, 212, 48, 0.7);
    }
    .fail {
      background: rgba(212, 12, 12, 0.7);
    }
  }
  .popularity-progress {
    display: flex;
    width: 100%;
    height: 1*$rem;
    overflow: hidden;
    background: #CDCDCD;
    .progress-success {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      background: #00439E;
    }
    .right-sanjiao {
      height:0;
      width:0;
      border-color: transparent #D40C0C transparent  transparent;
      border-style: solid;
      border-width: 1*$rem 1*$rem 0 0;
      transform-origin: 0 0;
      transform: rotateZ(180edg);
    }
    .progress-flat {
      display: flex;
      justify-content: space-between;
      height: 100%;
      background: #3DD430;
      .left-sanjiao {
        height:0;
        width:0;
        border-color: #00439E transparent transparent transparent;
        border-style: solid;
        border-width: 1*$rem 1*$rem 0  0;
        transform-origin: 0 0;
        transform: rotateZ(180edg);
      } 
    }
    .progress-fail {
      height: 100%;
      background: #D40C0C;
    }
  }
}