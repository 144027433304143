@import './../../scss/variables.scss';
.container{
  display: flex;
  width: 100%;
  flex-direction: column;
  .topImage{
    width: 100%;
    height: 18 * $rem;
  }
  .tabs{
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    display: flex;
    width: 100%;
    height: 5 * $rem;
    padding-top: 1.6 * $rem;
    padding-bottom: 1.6 * $rem;
    align-items: center;
    justify-content: center;
    background-color: white;
    .text{
      font-size: 2 * $rem;
      color: #333333;
      line-height: 2.8 * $rem;
    }
    .gap{
      margin-left: 5 * $rem;
      margin-right: 5 * $rem;
    }
    .selected{
      font-weight: 600;
      font-size: 2 * $rem;
    }
    .unSelected{
      color: #333333;
      font-weight: 400;
      font-size: 1.6 * $rem;
    }
  }
  .content{
    display: flex;
    flex-direction: column;
    padding-left: 1.5 * $rem;
    padding-right: 1.5 * $rem;
    .life{
      display: flex;
      flex-direction: column;
      img{
        margin-top: 2 * $rem;
        width: 100%;
        height: 17.25 * $rem;
      }
      p{
        font-size: 1.4 * $rem;
        color: #33363A;
        line-height: 2.1 * $rem;
        letter-spacing: 0.1 * $rem;
        margin-top: 2 * $rem;
      }
    }
    .travel{
      display: flex;
      flex-direction: column;
      .imgOne{
        width: 100%;
        height: 17.25 * $rem;
        margin-top: 2 * $rem;
        margin-bottom: 2* $rem;
      }
      p{
        font-size: 1.4 * $rem;
        color: #33363A;
        line-height: 2.1 * $rem;
        letter-spacing: 0.1 * $rem;
      }
      .imgTwo{
        width: 100%;
        height: 20 * $rem;
        margin-top: 2 * $rem;
        margin-bottom: 1* $rem;
      }
      .title{
        font-size: 1.8 * $rem;
        color: #333333;
        line-height: 2.5 * $rem;
        margin-bottom: 0.5 * $rem;
      }
      .titleOne{
        font-size: 1.8 * $rem;
        color: #333333;
        line-height: 2.5 * $rem;
      }
    }
    .sign{
      display: flex;
      flex-direction: column;
      div>div{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5 * $rem;
        justify-content: flex-start;
      }
      .imgOne{
        width: 100%;
        height: 17.25 * $rem;
        margin-top: 2 * $rem;
        margin-bottom: 2* $rem;
      }
      .label{
        font-size: 1.4 * $rem;
        color: #33363A;
        line-height: 2.1 * $rem;
        letter-spacing: 0.1 * $rem;
        align-self: center;
        margin-bottom: $rem;
      }
      .images{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-self: center;
      }
      .text{
        font-size: 1.2 * $rem;
        color: #33363A;
        line-height: 2 * $rem;
        margin-top: $rem;
      }
      .img{
        width: 11.1 * $rem;
        height: 6.55 * $rem;
      }
    }
  }
}
