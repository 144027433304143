@import 'scss/variables.scss';

.mask_container {
  display: flex;
  justify-content: center;
  align-items: center;
  .modal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80vw;
    .title {
      display: flex;
      justify-content: flex-start;
      width: 59.3*$rem;
      >img {
        width: 16.4*$rem;
        height: 4*$rem;
      }
    }
    .content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 59.3*$rem;
      height: 29*$rem;
      background: url('../imgs/share-bg.png') no-repeat;
      background-size: 100% 100%;
      .close {
        position: absolute;
        right: 1.5*$rem;
        top: 0.6*$rem;
        width: 2.5*$rem;
        height: 2.5*$rem;
      }
      .share_left {
        position: relative;
        margin-right: 6*$rem;
        .prize_name {
          position: absolute;
          left: 6.2*$rem;
          top: 0.6*$rem;
          font-family: PangMenZhengDao;
          font-size: 1.8*$rem;
          color: #FFFFFF;
          width: 13*$rem;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box; 
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;  
        }
        .prize_img {
          position: absolute;
          top: 7.6*$rem;
          left: 5.5*$rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 14*$rem;
          height: 8.5*$rem;
          > img {
            border-radius: 0.5*$rem;
            height: 8.5*$rem;
            max-width: 14*$rem;
          }
        }
        .btn_box {
          position: absolute;
          top: 19*$rem;
          left: 2.4*$rem;
          display: flex;
          align-items: center;
          >img {
            &:nth-child(1) {
              width: 2.3*$rem;
              height: 1.85*$rem;
            }
            &:nth-child(2) {
              margin: 0 0.5*$rem;
              width: 15*$rem;
              height: 3.2*$rem;
            }
            &:nth-child(3) {
              width: 2.3*$rem;
              height: 1.85*$rem;
            }
          }
        }
        .game_bg {
          width: 26.5*$rem;
          height: 27.8*$rem;
        }
      }
      .share_right {
        display: flex;
        align-items: center;
        flex-direction: column;
        .code_box {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 1*$rem;
          width: 14.5*$rem;
          height: 14.5*$rem;
          background: #FFFFFF;
        }
        >span {
          margin: 1.3*$rem 0;
          font-size: 1.2*$rem;
          color: #FFFFFF;
        }
        .share_type {
          display: flex;
          .type_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #FFFFFF;
            font-size: 1.1*$rem;
            >img {
              margin-bottom: 0.2*$rem;
              width: 3.2*$rem;
              height: 3.2*$rem;
            }
          }
        }
      }
    }
  }
}