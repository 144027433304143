@import 'scss/variables.scss';

.book_boardroom {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url('./img/bg.png') no-repeat;
  background-size: 100% 100%;
  .back {
    position: fixed;
    top: 4*$rem;
    left: 1*$rem;
    width: 3*$rem;
    height: 2.5*$rem;
  }
  .share {
    position: fixed;
    top: 4*$rem;
    right: 1*$rem;
    width: 2.4*$rem;
    height: 2.4*$rem;
  }
  .title {
    margin-top: 7*$rem;
    margin-left: 3*$rem;
    width: 28.9*$rem;
    height: 13.2*$rem;
  }
  .before_sub {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5*$rem;
    padding-bottom: 5*$rem;
    .form_title {
      margin-bottom: 3*$rem;
      color: #45FFF8;
      font-size: 2.5*$rem;
      font-weight: 600;
    }
    .form_style :global(.adm-list-body) {
      background: none;
      border: none;
    }
    .input_color :global(.adm-input-element) {
      color: #FFFFFF;
      ::placeholder {
        color: rgba(255, 255, 255, 0.47);
      }
    }
    .form_item {
      display: flex;
      flex-direction: column;
      margin-bottom: 2.5*$rem;
      .label {
        margin-bottom: 0.9*$rem;
        color: #FFFFFF;
        font-size: 1.5*$rem;
      }
      .input {
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 1*$rem;
        width: 28*$rem;
        height: 4*$rem;
        border-radius: 0.6*$rem;
        background: rgba(255, 255, 255, 0.3);
      }
      .date {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .code_box {
        display: flex;
        margin-top: 1.45*$rem;
        .code_input {
          display: flex;
          align-items: center;
          margin-right: 1.4*$rem;
          padding-left: 1*$rem;
          height: 3.1*$rem;
          width: 16*$rem;
          border-radius: 0.6*$rem;
          background: rgba(255, 255, 255, 0.3);
        }
        .init {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10.8*$rem;
          height: 3.1*$rem;
          color: #000000;
          font-size: 1.3*$rem;
          background: #FFFFFF;
          border-radius: 0.6*$rem;
        }
        .disable {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10.8*$rem;
          height: 3.1*$rem;
          font-size: 1.3*$rem;
          background: #FFFFFF;
          color: #BFBFBF;
          border-radius: 0.6*$rem;
        }
      }
    }
    .sub_btn {
      margin-top: 3*$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12.5*$rem;
      height: 3.4*$rem;
      background: #2ED6D0;
      font-size: 2.4*$rem;
      font-weight: 500;
      color: #FFFFFF;
      border-radius: 0.85*$rem;
    }
  }
  .after_sub {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10*$rem;
    font-size: 1.5*$rem;
    color: #FFFFFF;
    .form_title {
      color: #45FFF8;
      font-size: 2.5*$rem;
      font-weight: 600;
    }
    .tip {
      margin-top: 0.5*$rem;
      margin-bottom: 4*$rem;
      line-height: 2.5*$rem;
    }
    .success_item {
      display: flex;
      margin-bottom: 2.5*$rem;
      width: 28*$rem;
    }
  }
}
.shareInvite {
  width: 31.5 * $rem;
  height: 42.75 * $rem;
}