@import 'scss/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    position: relative;

    &.background1 {
        background: linear-gradient(270deg, #3444B5 0%, #7495FF 49%, #4053C5 100%);
    }

    .blurBg {
        width: 27.15*$rem;
        position: absolute;
        top: 0;
        left: 5.175*$rem;
        bottom: 0;
        background: #7D9CFF;
        filter: blur(50px);
    }

    .content {
        width: 100%;
        // height: 50.35 * $rem;
        flex: 1;
        overflow-y: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .subTitle {
        height: 3.1* $rem;
        font-size: 2.2* $rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 3.1* $rem;
        margin-top: 35.7* $rem;
    }

    .time {
        margin-top: 39.6* $rem;
        height: 2.3* $rem;
        font-size: 1.2* $rem;
        font-weight: 400;
        color: #F9F9F9;
        line-height: 2.3* $rem;
    }

    .tel {
        width: 28.4* $rem;
        height: 4 * $rem;
        display: block;
        background: transparent;
        margin-top: 48* $rem;
        position: relative;
        z-index: 100;

        .telInput {
            height: 4*$rem;
            font-size: 1.5* $rem;
            color: #4C290A;
            line-height: 4* $rem;
            width: 100%;
            text-align: center;

            &::placeholder {
                color: #4C290A;
            }
        }

        &:after {
            content: '';
            width: 200%;
            height: 200%;
            border-bottom: 1px solid #4C290A;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0.5);
            transform-origin: 0 0;
            display: block;
            box-sizing: border-box;
        }


    }

    .safe {
        width: 100%;
        position: relative;
        z-index: 10;
        background: #fff;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }

    .btnWrapper {
        width: 100%;
        text-align: center;
        color: #fff;
    }

    .inviteButton {
        height: 4.4*$rem;
        width: 22.2 * $rem;
        line-height: 4.4*$rem;
        font-size: 1.8*$rem;
        margin: 0.8* $rem auto;
        background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
        border-radius: 2.2* $rem;
        box-shadow: 0.05* $rem 0.15* $rem 0.4* $rem 0 #FFBE92;
    }

    .successBg {
        width: 100%;
        flex: 1;
        padding-bottom: 5*$rem;
    }
}