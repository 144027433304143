@import 'scss/variables.scss';

.guess-record-item {
  position: relative;
  margin: 2.2*$rem 1.6*$rem 0;
  background: #FFFFFF;
  border-radius: 1*$rem;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  .guess-result-tag {
    position: absolute;
    top: -2*$rem;
    right: -2*$rem;
    img {
      width: 8.6*$rem;
      height: 8.6*$rem;
    }
  }
  &-title {
    display: flex;
    justify-content: space-between;
    padding: 1.6*$rem 1.5*$rem 1*$rem;
    font-size: 1.2*$rem;
    color: #9E9E9E;
  }
  .shengli-left-tag {
    position: absolute;
    top: 4.54*$rem;
    left: 2.71*$rem;
    width: 9.55*$rem;
    height: 9.55*$rem;
  }
  .android-shengli-left-tag {
    position: absolute;
    top: 4.6*$rem;
    left: 2.7*$rem;
    width: 9.55*$rem;
    height: 9.55*$rem;
  }
  .shengli-right-tag {
    position: absolute;
    top: 4.54*$rem;
    right: 1.8*$rem;
    width: 9.5*$rem;
    height: 9.5*$rem;
  }
  .android-shengli-right-tag {
    position: absolute;
    top: 4.65*$rem;
    right: 1.8*$rem;
    width: 9.5*$rem;
    height: 9.5*$rem;
  }
  .battle-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1*$rem 3*$rem 1.7*$rem;
    .country-flag {
      margin-top: 0.55*$rem;
      width: 6*$rem;
      height: 6*$rem;
      border-radius: 50%;
    }
    &-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: 1.5*$rem;
      color: #000000;
      width: 8*$rem;
    }
    &-middle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 1.9*$rem;
      &-score {
        display: flex;
        align-items: center;
        font-size: 2.7*$rem;
        color: #3D3D3D;
        img {
          margin: 0 2*$rem;
          width: 3*$rem;
          height: 4.5*$rem;
        }
      }
    }
    .routine-time-score {
      position: absolute;
      left: 50%;
      bottom: 0.8*$rem;
      transform: translateX(-50%);
      font-size: 1*$rem;
      color: #3D3D3D;
    }
  }
  .pop-progress {
    padding: 0 2.3*$rem;
  }
  .guess-points-box {
    margin-top: 1.8*$rem;
    padding: 0.75*$rem 1.5*$rem;
    background: #CDE1FD;
    border-radius: 0 0 1*$rem 1*$rem;
    .guess-points-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75*$rem 0;
      color: #000000;
      .my-guess-name {
        font-size: 1.2*$rem;
      }
      &-right {
        display: flex;
        align-items: center;
        span {
          font-size: 1*$rem;
          i {
            color: #FF5100;
          }
        }
        .close-arrow-img {
          margin-left: 1.1*$rem;
          height: 1.2*$rem;
          width: 0.7*$rem;
          transform: rotateZ(90deg);
        }
        .open-arrow-img {
          margin-left: 1.1*$rem;
          width: 1.2*$rem;
          height: 0.7*$rem;
          transform: rotateZ(-180deg);
        }
      }
    }
    .guess-points-content {
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.6*$rem 0;
        font-size: 1.2*$rem;
        color: #9E9E9E;
      }
      &.points-detail-show {
        display: block;
      }
      &.points-detail-hide {
        display: none;
      }
    }
  }
}
.invite-container {
  padding: 1.5*$rem 1.75*$rem 1.5*$rem;
  border-radius: 1*$rem 1*$rem 0 0;
  .invite-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 1.8*$rem;
    img {
      position: absolute;
      top: -0.5*$rem;
      right: -0.6*$rem;
      width: 3*$rem;
      height: 3*$rem;
    }
  }
  .current-match-info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.35*$rem 0 1.75*$rem;
    color: #3D3D3D;
    font-size: 1.5*$rem;
    .left-span {
      flex: 1;
      text-align: right;
    }
    .vs-img {
      flex: 1;
      text-align: center;
    }
    .right-span {
      flex: 1;
      text-align: left;
    }
    img {
      margin: 0 2.5*$rem;
      width: 3*$rem;
      height: 4*$rem;
    }
  }
  .invite-counts {
    padding-bottom: 1*$rem;
    text-align: center;
    color: #FF5100;
    font-size: 1.4*$rem;
  }
  .invite-user-info {
    height: 30*$rem;
    overflow: auto;
    .invite-info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.75*$rem;
      font-size: 1.2*$rem;
      color: #000000;
      height: 3.5*$rem;
      .info-item-left {
        img {
          margin-right: 1*$rem;
          width: 3.5*$rem;
          height: 3.5*$rem;
        }
      }
    }
  }
}