@import 'scss/variables.scss';

.container {
  padding: 1.5*$rem;
  background: #F7F7F7;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .reward {
    margin-bottom: 1*$rem;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    padding: 2*$rem 1.5*$rem;
    box-shadow: 0 0.25*$rem 0.7*$rem 0 rgba(0, 0, 0, 0.06);
    border-radius: 0.6*$rem;
    background: #FFFFFF;
    .content {
      flex: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;

      position: relative;

      .title {
        color: #333333;
        font-size: 1.6*$rem;
        font-weight: bold;
      }

      .mobile {
        color: #333333;
        font-size: 1.2*$rem;
      }

      .date {
        font-size: $rem;
        color: #999999;
        margin-top: 1.2*$rem;
      }


    }
    .num {
      font-size: 1.9*$rem;
      font-weight: bold;
      color: #FF8030;
    }

  }
}
