@import 'scss/variables.scss';
.container{
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(360deg, #BFC7F9 0%, #90B1EE 100%);
  .topImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 37.5 * $rem;
    height: 17.3 * $rem;
  }

  .content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    position: relative;
    .title {
      margin:8.9 * $rem 0 3.1*$rem;
      font-size: 2.5 * $rem;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 3.5 *$rem;
      text-shadow: 0 0.1 * $rem 1.5 * $rem rgba(19, 56, 129, 0.5);
    }
    .inputWrap {
      height: 4 * $rem;
      border-radius: 0.5 * $rem;
      background-color: rgba(255, 255, 255, 0.7);
      width: 33.5 * $rem;
      align-items: center;
      display: flex;
      flex-direction: row;
      padding-left: 1.25 * $rem;
      margin-bottom: 1.5 * $rem;
      input {
        height: 4 * $rem;
        width: 28 * $rem;
      }
      .icon {
        width: 1.5 * $rem;
        height: 1.2 * $rem;
        margin-right: 2.5 * $rem;
      }
      .icon2 {
        width: 1.5 * $rem;
        height: 1.5 * $rem;
        margin-right: 2.5 * $rem;
      }
      .icon3{
        width: 1.5 * $rem;
        height: 1.4 * $rem;
        margin-right: 2.5 * $rem;
      }
      input {
        color: #333;
        font-size: 1.4 * $rem;
      }
      input::-webkit-input-placeholder{
        color:#666;
      }
      input::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:#666;
      }
      input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:#666;
      }
      input:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color:#666;
      }
    }

    .btnDate {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #FFFFFF;
      width: 33.5 * $rem;
      height: 4 * $rem;
      margin-top: 3.6 * $rem;
      border-radius: 0.5 * $rem;
      padding-left: 1.25 * $rem;
      padding-right: 1.5 * $rem;
      .des {
        font-size: 1.4 * $rem;
        color:#666;
        flex:1;
        &.active {
          color:#333;
        }
      }
      .arrow {
        width: 0.75 * $rem;
        height: 0.75 * $rem;
      }
    }
    .commit {
      margin-top: 2*$rem;
      bottom: $rem;
      width: 33.6 * $rem;
      height: 4.4 * $rem;
      background: #641F6D;
      border-radius: 0.5 * $rem;
      opacity: 0.6;
      color: #fff;
      font-size: 1.6 * $rem;
      &.active {
        opacity: 1;
      }
    }
    .record {
      margin-top: 2*$rem;
      font-size: 1.4 * $rem;
      color: #333333;
      line-height: 1.6 *$rem;
    }
  }

  .slogan {
    width: 15.05*$rem;
    position: absolute;
    bottom:3 * $rem;
    left:11.225 * $rem;
  }
}
