@import './../../scss/variables.scss';
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 15.85 * $rem;
    .slognImg{
        width: 27 * $rem;
        margin-top: 3.5 *  $rem;
    }
}
