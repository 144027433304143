@import 'scss/variables.scss';

.container {
  width: 100vw;
  height: 60vh;
  background: #4E52FF linear-gradient(180deg, #05512E 0%, #064124 28%, #09170A 100%);
  border-radius: 20px 20px 0px 0px;
  border: 1px solid #8EF4B1;
  border-bottom: none;

  overflow-y: scroll;
  position: relative;
  //background-color: red;

  .header_father {
    width: 99%;
    height: 7 *$rem;
    margin-left: 0.5%;
    position: fixed;
    background: #074728;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 1;
    //background-color: red;

    .header_title_father {
      width: 100%;
      height: 3 *$rem;
      margin-top: 1.6 *$rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      position: relative;

      span {
        font-size: 1.6 *$rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        letter-spacing: 2px;
      }

      img {
        width: 1.5 *$rem;
        height: 1.5 *$rem;
        margin-right: 0.8 *$rem;
        position: absolute;
        right: 1 *$rem;
      }
    }

    .count {
      width: 100%;
      text-align: center;
      margin-top: 0.5 *$rem;
      font-size: 1.2 *$rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      position: relative;
    }
  }

  .list_father {
    //background-color: purple;
    margin-top: 7 *$rem;
    //height: 60vh;
  }

  .item_container {
    width: 100%;
    height: 6 *$rem;
    position: relative;
    display: flex;
    flex-direction: row;
    //background-color: red;

    .item_left_container {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;


      .item_title {
        margin-top: 1.3 *$rem;
        margin-left: 2.0 *$rem;

        font-size: 1.3 *$rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;

      }

      .item_title1 {
        margin-top: 0.8 *$rem;
        margin-left: 2.0 *$rem;
        font-size: 1.3 *$rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }

    .item_right_container {
      width: 26%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      //background-color: purple;

      span {
        font-size: 1.3 *$rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ACF898;
      }
    }

    .line {
      width: 100%;
      height: 0.01 *$rem;
      background-color: #2A2681;
      position: absolute;
      opacity: 0.2;
      bottom: 0;
      right: 0;
    }
  }
}
