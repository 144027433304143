@import 'scss/variables.scss';
.container {
  min-height: 100vh;
  .top {
    overflow: hidden;
    position: relative;
    width: 100%;
    .bg {
      float: left;
      width: 100%;
    }
    .rule_bt {
      position: absolute;
      right: 0;
      width: 27%;
      top: 7.75%;
    }
    .share {
      position: fixed;
      right: 0px;
      width: 7.4*$rem;
      top: 29.6%;
      z-index: 999;
    }
    .airdrop_icon {
      position: fixed;
      right: 0px;
      width: 9*$rem;
      top: 46.6%;
      z-index: 999;
    }
  }
  .bg_wrap {
    background: url(./images/as_bg.png) no-repeat 0 0;
    background-size: cover;
    position: relative;
    padding-bottom: 4.4*$rem;
    .bts_wrap {
      width: 86.4%;
      margin: 0 auto;
      overflow: hidden;
      img {
        width: 48.1%;
        float: left;
      }
      img + img {
        float: right;
      }
    }
    .as_text {
      width: 86.4%;
      margin: 3.2*$rem auto;
      overflow: hidden;
      img {
        width: 100%;
        float: left;
      }
      .date {
        float: left;
        width: 100%;
        font-size: 1.75*$rem;
        font-weight: 500;
        color: #D2E9FF;
        line-height: 2.95*$rem;
        text-align: center;
        margin-top: 0.65*$rem;
      }
    }
    .lists {
      .item {
        width: 80.5%;
        margin: 0 auto 3.2*$rem;
        .item_bg {
          overflow: hidden;
          position: relative;
          width: 100%;
          img {
            width: 100%;
            float: left;
          }
          .item_count {
            position: absolute;
            right: 0;
            top: 0;
            width: 15*$rem;
            height: 2.5*$rem;
            background: #3E0C8D;
            border-radius: 1.3*$rem 0px 0px 1.3*$rem;
            opacity: 0.9;
            font-size: 1.25*$rem;
            color: #DFA4FA;
            line-height: 2.5*$rem;
            text-align: center;
          }
        }
        .item_bottom {
          margin-top: 1.5*$rem;
          display: flex;
          align-items: flex-end;
          .item_left {
            flex: 1;
            .item_title {
              overflow: hidden;
              img {
                height: 1.65*$rem;
                float: left;
              }
            }
            .item_content {
              overflow: hidden;
              margin-top: 0.95*$rem;
              img {
                width: 100%;
                float: left;
              }
            }
          }
          .item_right {
            flex: 0 0 12.1*$rem;
            overflow: hidden;
            margin-left: 0.65*$rem;
            img {
              float: left;
              height: 4.5*$rem;
            }
          }
        }
      }
    }
    .logo {
      width: 39.6%;
      margin: 5*$rem auto 0;
      overflow: hidden;
      img {
        float: left;
      }
    }
  }
  .mask {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    .content {
      width: 89.86%;
      overflow: hidden;
      .ad {
        width: 100%;
        float: left;
        margin-bottom: 1.6*$rem;
      }
      .close {
        width: 3.2*$rem;
        display: block;
        margin: 0 auto;
      }
    }
    
  }
  .mask_rule {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    .content {
      width: 75.7%;
      background-color: #ffffff;
      border-radius: 1.2*$rem;
      padding: 1.5*$rem;
      .title {
        font-size: 2.2*$rem;
        font-weight: bold;
        color: #333333;
        text-align: center;
        margin-bottom: 1.5*$rem;
      }
      .text {
        height: 34vh;
        overflow: scroll;
        font-size: 1.1*$rem;
        color: #8A8A8A;
        line-height: 1.6*$rem;
        white-space: pre-wrap;
      }
      .close {
        width: 58.2%;
        overflow: hidden;
        margin: 2*$rem auto 0;
        img {
          float: left;
        }
      }
    }
    
  }
  .pro_wrap {
    margin: 0.95*$rem 0;
    .pro_left {
      width: 65%;
      .pro_pa {
        background-color: #6C1DB9;
        border-radius: 0.55*$rem;
        height: 11px;
        width: 100%;
        .pro_chil {
          background-color: #DFA4FA;
          height: 100%;
          border-radius: 0.55*$rem;
          text-align: center;
          position: relative;
          min-width: 30%;
          line-height: 9px;
          span {
            color: #7E1EC2;
            font-size: 0.8*$rem;
            // line-height: 1.1*$rem;
          }
          .img_wrap {
            width: 1.7*$rem;
            height: 1.7*$rem;
            border-radius: 50%;
            position: absolute;
            right: -0.9*$rem;
            top: 50%;
            transform: translateY(-50%);
            background-color: #CF77F7;
            overflow: hidden;
            img {
              height: 1.7*$rem;
              width: 0.9*$rem;
            }
          }
        }
      }
    }
  }
}
