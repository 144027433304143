@import 'scss/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    align-items: center;
    position: relative;
    &.background1 {
        background:#FFD7BE;
    }
    &.background2 {
        background:#57379E;
    }
    .content {
        width: 35.5*$rem;
        height: 50.35 * $rem;
        margin-top: 0.55*$rem;
        margin-bottom: 6*$rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
            width: 35.5*$rem;
            height: 50.35 * $rem;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .subTitle {
        height: 3.1* $rem;
        font-size: 2.2* $rem;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 3.1* $rem;
        margin-top: 35.7* $rem;
    }

    .time {
        margin-top: 39.6* $rem;
        height: 2.3* $rem;
        font-size: 1.2* $rem;
        font-weight: 400;
        color: #F9F9F9;
        line-height: 2.3* $rem;
    }

    .tel {
        width: 28.4* $rem;
        height: 4 * $rem;
        display: block;
        background: transparent;
        margin-top: 42.35* $rem;
        position: relative;
        z-index: 100;

        .telInput {
            height: 4*$rem;
            font-size: 1.5* $rem;
            color: #fff;
            line-height: 4* $rem;
            width: 100%;
            text-align: center;

            &::placeholder {
                color: #fff;
            }
        }

        &:after {
            content: '';
            width: 200%;
            height: 200%;
            border: 1px solid #ffffff;
            border-radius: 0.8* $rem;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0.5);
            transform-origin: 0 0;
            display: block;
            box-sizing: border-box;
        }


    }

    .safe {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        background: #fff;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
    }

    .btnWrapper {
        width: 100%;
        text-align: center;
        color: #fff;
    }

    .inviteButton {
        height: 4.4*$rem;
        width: 22.2 * $rem;
        line-height: 4.4*$rem;
        font-size: 1.8*$rem;
        margin: 0.8* $rem auto;
        background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
        border-radius: 2.2* $rem;
        box-shadow: 0.05* $rem 0.15* $rem 0.4* $rem 0 #FFBE92;
    }

    .successBg2 {
        width: 35.5* $rem;
        height: 67.5* $rem;
        margin: 3.1* $rem auto 10* $rem;
        background: url('./images/successBg.png') no-repeat;
        background-size: 100% 100%;
    }

    .successBg {
        width: 35.5* $rem;
        height: 67.5* $rem;
        margin: 3.1* $rem auto 10* $rem;
        background: url('./images/successBg2.png') no-repeat;
        background-size: 100% 100%;
    }
}