@import 'scss/variables.scss';

.online_aptmt_container {
  background-color: #F7F7F7;
  width: 100%;
  min-height: 100%;
  position: relative;
}

.online_aptmt_me {
  margin: 0 4vw;
  opacity: 0.8;
  font-family: PingFangSC-Semibold;
  font-size: 1.2 *$rem;
  line-height: 5 *$rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #000000;
}

.online_aptmt_time_container {
  width: 92vw;
  height: 7 *$rem;
  margin-left: 4vw;
  background-color: white;
  border-radius: 10px;

  > p {
    letter-spacing: 0px;
    margin-left: 1 *$rem;
    color: #000000;
  }

  :nth-child(1) {
    opacity: 0.8;
    font-family: PingFangSC-Semibold;
    font-size: 1.4 *$rem;
    font-weight: normal;
    line-height: 44.8px;
  }

  :nth-child(2) {
    opacity: 0.7;
    font-family: PingFangSC-Semibold;
    font-size: 1.2 *$rem;
    font-weight: normal;
  }
}

.online_aptmt_partner_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 92vw;
  //height: 6 *$rem;
  margin-left: 4vw;
  margin-top: 2 *$rem;

  p {
    opacity: 0.8;
    font-family: PingFangSC-Semibold;
    font-size: 1.4 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #000000;
  }
}

.online_aptmt_partner_right_container {
  width: 2.7 *$rem;
  height: 1.5 *$rem;
  margin-right: 1 *$rem;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.online_aptmt_list_container{
  padding-bottom: 9.5 *$rem;
}
.online_aptmt_partner_list_container {
  width: 92vw;
  margin-left: 4vw;
  height: 14 *$rem;
  border-radius: 10px;
  border: 0.1*$rem solid white;
  background: white;
  margin-top: 15px;

  p {
    margin-top: 1.5 *$rem;
    margin-left: 1.5 *$rem;
    opacity: 0.8;
    font-family: PingFang SC;
    font-size: 1.4 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #000000;
  }

  .online_aptmt_partner_list_inputGroup {
    margin: 1.5 *$rem 2*$rem 0 1 *$rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3 *$rem;
    position: relative;
    //background: red;

    label {
      width: 8 *$rem;
      font-size: 1.4*$rem;
      color: #000000;
      line-height: 100%;
      text-align: right;
      opacity: 0.8;
      display: inline-block;
    }

    .inputLine {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      position: relative;
      margin-left: 2 *$rem;
      font-size: 1.4*$rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      //background: red;

      input[type='text'],
      input[type='tel'] {
        color: #3D3D3D;
        flex: 1;
        width: 1px;
        position: relative;
        z-index: 1;
        opacity: 1;

        &::placeholder {
          color: #3D3D3D;
          opacity: 0.5;
        }
      }
    }
  }
}

.online_aptmt_bottom_container {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  width: 100%;
  height: 9 *$rem;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;

  .online_aptmt_submit {
    width: 90%;
    height: 4.5 *$rem;
    border-radius: 45px;
    opacity: 1;
    margin-left: 5%;
    margin-top: 1 *$rem;
    background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: PingFangSC-Medium;
    font-size: 1.6 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #614D1A;
  }
}

