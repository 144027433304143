@import 'scss/variables.scss';

.container {
  width: 37.5*$rem;
  height: 179.1*$rem;
  background: url("img/nftBg.jpg");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pic1 {
    width: 33.6*$rem;
    height: 44.85*$rem;
    background: url("img/nftPic.png");
    background-size: 100% 100%;
    margin-top: 3.1*$rem;
  }

  .btn {
    margin-top: 1.6*$rem;
    margin-bottom: 5.45*$rem;
    width: 30.45*$rem;
    height: 5.4*$rem;
    background: url("img/nftBtn.png");
    background-size: 100% 100%;
  }

  .pic2 {
    width: 34.8*$rem;
    height: 109.95*$rem;
    background: url("img/nftPic2.png");
    background-size: 100% 100%;
  }
}
