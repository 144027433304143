@import 'scss/variables.scss';

.container {
  touch-action: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;

  .contentBg {
    width: 37.5 * $rem;
    height: 54.2 * $rem;
    background: url("./imgs/exchange-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    border-radius: 1*$rem 1*$rem 0 0;
    .btnClose {
      width: 2.2 * $rem;
      height: 2.2 * $rem;
      background: url("./imgs/icon_quxiao.png") no-repeat;
      background-size: 100%;
      position: absolute;
      right: 1.2 * $rem;
      top: 0.85 * $rem;
    }

    .wrap {
      touch-action: none;
      width: 37.5 * $rem;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .topTitle {
        margin-bottom: 0.5 * $rem;
        width: 21.7 * $rem;
        height: 6 * $rem;
        background: url("./imgs/pic_tanchuang.png") no-repeat;
        background-size: contain;
        text-align: center;
        line-height: 4 * $rem;
        font-size: 2.3 * $rem;
        color: #7E5B23;
      }

      .debris {
        width: 31 * $rem;
        height: 3 * $rem;
        background: linear-gradient(270deg, rgba(249,202,132,0.10) 0%, #F1B150 50%, rgba(249,202,132,0.10) 100%);
        font-size: 1.35 * $rem;
        font-weight: 500;
        color: #7E5B23;
        line-height: 3 * $rem;
        text-align: center;
      }

      .tip {
        height: 1.65 * $rem;
        font-size: 1.2 * $rem;
        font-weight: 500;
        color: #E63535;
        line-height: 1.65 * $rem;
        margin-top: 0.3 * $rem;
        margin-bottom: 0.4 * $rem;
      }

      .list {
        //flex:1;
        width: 36.25 * $rem;
        max-height: 44.45 * $rem;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);

        &.disableScroll {
          touch-action: none;
        }

        .itemWrap {
          width: 32%;
          height: 18 * $rem;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-bottom: 1.5 * $rem;
          
          .item {
            width: 10.1 * $rem;
            height: 18 * $rem;
            border-radius: $rem;
            background: #ffffff;
            overflow: hidden;
            background: linear-gradient(331deg, rgba(255,255,255,0.87) 0%, rgba(255,255,255,0.49) 99%);
            .imgBg {
              width: 100%;
              height: 7.8 * $rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #F6F6F6;

              .img {
                // width: 5.05 * $rem;
                // width: 10.1*$rem;
                height: 7.8 * $rem;
              }
            }

            .text {
              font-size: 1.2 * $rem;
              height: 2.75*$rem;
              font-weight: 500;
              color: #7E5B23;
              line-height: 1.4 * $rem;
              margin: 0.9 * $rem 0.8 * $rem 0.6*$rem;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            .textNumBG {
              display: flex;
              align-items: center;
              height: 1.5 * $rem;
              margin-left: 0.8 *$rem;
              margin-bottom: 1.1 * $rem;

              .textNum {
                font-size: 1.4 * $rem;
                font-weight: 500;
                color: #ffab2d;
                line-height: 1.5 * $rem;
              }

              .textUnit {
                margin-left: 0.5 * $rem;
                font-size: 1.2 * $rem;
                font-weight: 500;
                color: #9F9F9F;
                line-height: 1.5 * $rem;
              }
            }

            .btn {
              width: 8 *$rem;
              height: 2.5 * $rem;
              background: linear-gradient(101deg, #D4BA77 4%, #FFEAB4 98%);
              border-radius: 1.25 * $rem;
              font-size: 1.3 * $rem;
              font-weight: 500;
              color: #7E5B23;
              line-height: 2.5 * $rem;
              text-align: center;
              margin-left: 0.8 * $rem;
            }
          }

          &:nth-child(3n) {
            width: 34%;
            padding-right: 0.725 * $rem;
          }

          &:nth-child(3n+1) {
            width: 34%;
            padding-left: 0.725 * $rem;
          }
        }
      }
    }
  }
}