@import 'scss/variables.scss';

.vip_card_container {
  width: 100%;
  min-height: 100vh;
  background-color: #757983;
  background-repeat: no-repeat;
  background-size: 100% ;
  //padding-bottom: 7 *$rem;
}

.vip_card_header {

  .vip_card_swiper_container {
    width: 100%;
    min-height: 12 *$rem;
    //background: red;
    //opacity: 0.3;

    //.vip_card_swiper_img {
    //  width: 15 *$rem;
    //  height: 3.4 *$rem;
    //  background: yellow;
    //  margin-left: 2 *$rem;
    //  margin-top: 10 *$rem;
    //}
  }
}


.vip_card_top_container {
  position: fixed;
  top: 4.2*$rem;
  width: 100%;
  height: 3 *$rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  //background: purple;

  .back_btn {
    width: 1.75*$rem;
    height: 1.75*$rem;
    background: url(../../../images/icon/back@2x.png) no-repeat;
    background-size: 100%;
    margin-left: 2 *$rem;
  }
 
  .rule_btn {
    margin-right: 2 *$rem;
    font-family: PingFangSC-Regular;
    font-size: 1.3 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #FFFFFF;
  }
  .share_btn {
    width: 2.4*$rem;
    height: 2.4*$rem;
    margin-right: 2 *$rem;
    &:active {
      opacity: 0.8;
    }
  }
}

.content {
  position: relative;
  color: #ffffff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 48px;
  user-select: none;
  margin-top: 16 *$rem;
  width: 94%;
  margin-left: 2*$rem;
  height: 16.1 *$rem;
  padding: 0.1px;

  &.content_bg {
    background: url(./images/icon_kuang.png);
    background-size: 100%;
  }

  .vip_card_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .logo_container {
    position: absolute;
    left: 2px;
    top: 2px;
    width: 9 *$rem;
    height: 2.3 *$rem;
    opacity: 0.9;
    background: linear-gradient(90deg, #52967F 0%, #94CFBB 100%);
    box-sizing: border-box;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 30px;
    border-right: 0.1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;

    &.hz_boder {
      border-right: 0.1px solid #1D1F23;
    }

    img {
      width: 9px;
      height: 10px;
      object-fit: cover;
    }

    .logo_suo_name {
      font-family: PingFang HK;
      font-size: 1.2 *$rem;
      font-weight: normal;
      letter-spacing: 0.1em;
      color: #E8CEA0;
      margin-left: 0.5 *$rem;
    }
  }

  .vip_time {
    position: absolute;
    left: 2 *$rem;
    bottom: 3 *$rem;
    opacity: 0.6;
    font-family: PingFang SC;
    font-size: 1.2 *$rem;
    font-weight: normal;
    letter-spacing: 0em;
    color: #F8DC9E;
  }
}

.customIndicator {
  position: absolute;
  top: 6px;
  right: 6px;
  background: rgba(0, 0, 0, 0.3);
  padding: 4px 8px;
  color: #ffffff;
  border-radius: 4px;
  user-select: none;
}

.botBtn {
  position: fixed;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  bottom: 3*$rem;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  margin-top: 3.15*$rem;
  width: 34.5*$rem;
  height: 4.4*$rem;
  font-size: 1.8*$rem;
  text-align: center;
  line-height: 4.4*$rem;
  background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
  border-radius: 2.2*$rem;
  font-family: PingFang SC;
  font-weight: bold;
  letter-spacing: 0em;
  color: #624C16;
}
