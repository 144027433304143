@import 'scss/variables.scss';

.container {
    width: 100%;
    position: relative;

    .header {
        width: 100%;
        height: 39.75 * $rem;
        background: rgb(235, 230, 230);
    }

    .headerImg {
        width: 100%;
        height: 100%;
    }

    .content {
        width: 100%;
        background: #2C3148;
        padding: 0 1 * $rem;
        display: flex;
        flex-direction: column;
    }

    .contentText {
        width: 100%;
        padding-top: 1.65 * $rem;
        padding-bottom: 1.6 * $rem;
    }

    .greenText {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4 * $rem;
        color: #fff;
    }

    .round {
        border-radius: 50%;
        width: 0.95 * $rem;
        height: 0.95 * $rem;
        background: #DD9E60;
        margin: 0.3 * $rem;
    }

    .lineBox {
        display: flex;
        justify-content: center;
        padding-top: 0.6 * $rem;
    }

    .line {
        width: 6.5 * $rem;
        border: 1px solid #000;
    }

    .iconView {
        width: 100%;
        height: 11 * $rem;
        display: flex;
        flex-direction: column;
        background: url('./images/toTop.png') no-repeat;
        background-size: 100% 100%;
        margin-top: 0.5 * $rem;
    }

    .iconView:nth-child(1) {
        margin: 0;
    }

    .textLine {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.15 * $rem;
    }

    .threeLine {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1.15 * $rem;
    }

    .iconTitle {
        color: #FFDCBA;
        font-size: 1.3 * $rem;
    }

    .icon {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 0.5 * $rem;
    }

    .imgBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .imgIcon {
        width: 4 * $rem;
        height: 4 * $rem;
    }

    .imgFont {
        font-size: 1.2 * $rem;
        color: #FFDCBA;
        margin-top: 0.8 * $rem;
    }

    .toRight {
        margin-right: 0.5 * $rem;
        width: 0.85 * $rem;
        height: 0.1 * $rem;
        background: linear-gradient(to right, #24263C, #F2B47D);
    }

    .toLeft {
        width: 0.85 * $rem;
        height: 0.1 * $rem;
        background: linear-gradient(to left, #24263C, #F2B47D);
        margin-left: 0.5 * $rem;
    }

    .titleCenter {
        display: flex;
        align-items: center;
        margin-left: 0.3 * $rem;
        text-align: center;
    }

    .iconBox {
        float: 1;
        background: #24263C;
        padding: 1 * $rem 0.5* $rem;
    }

    .threeTop {
        width: 100%;
        height: 11 * $rem;
        display: flex;
        flex-direction: column;
        background: url('./images/threeTop.png') no-repeat;
        background-size: 100% 100%;
        margin-top: 0.5 * $rem;
    }

    .wellBox {
        width: 33%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .wellLine {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 1.45 * $rem;
    }

    .shadow {
        width: 100%;
        height: 2.5 * $rem;
        background: #2C3148;
    }

    .greenLand {
        width: 100%;
        height: 13.5 * $rem;
        background: #2C3148;
    }

    .greenBox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.5 * $rem;
    }

    .greenLandImg {
        width: 18.45 * $rem;
        height: 3.25 * $rem;
    }

    .gy {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2.5 * $rem;
    }

    .gyText {
        color: #7B7B7B;
        font-size: 0.9 * $rem;
    }

    .vip {
        width: 100%;
        height: 68.5 * $rem;
    }

    .vipExplain {
        width: 100%;
        height: 100%;
    }
}