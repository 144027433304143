@import 'scss/variables.scss';

#root,html {
  height: 100%;
}

.invite-record-page {
  height: 100%;
  background: #f1f0f5;
  .adm-pull-to-refresh {
    height: 100%;
    .adm-pull-to-refresh-content {
      height: 100%;
    }
  }

  .empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    img {
      margin-top: 15*$rem;
      width: 18.5*$rem;
      height: 18.5*$rem;
    }
    span {
      font-size: 1.4*$rem;
      color: #999999;
    }
  }
 
  .record-list {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    height: 100%;
    background: #f1f0f5;
    
    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1.5*$rem auto 0;
      padding: 1*$rem 1.5*$rem;
      width: 34.5*$rem;
      height: 6.5*$rem;
      background: #FFFFFF;
      border-radius: 0.6*$rem;
      .item-left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        span {
          color: #333333;
          &:nth-child(1) {
            margin-bottom: 0.6*$rem;
            font-size: 1.4*$rem;
            font-weight: bold;
          }
          &:nth-child(2) {
            font-size: 1*$rem;
          }
        }
      }
      .item-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        span {
          color: #EA5148;
          &:nth-child(1) {
            font-size: 1.5*$rem;
            font-weight: bold;
          }
          &:nth-child(2) {
            margin-top: 0.6*$rem;
            font-size: 1.2*$rem;
          }
        }
      }
    }
  }
}