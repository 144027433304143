@import 'scss/variables.scss';


.apty_yuyue_container {
  width: 100%;
  margin-top: 9vh;
  height: 91vh;
  overflow: scroll;
}

.aptmt_acty_selection_list_item {
  width: 94%;
  margin-left: 3%;
  padding: 1 *$rem 0;
  border-radius: 10px;
  border: 0.1*$rem solid white;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px 0px rgba(143, 143, 143, 0.3);
  margin-top: 1.5 *$rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  img {
    width: 6.5 *$rem;
    height: 6.5 *$rem;
    object-fit: cover;
    background-color: #D8D8D8;
    margin-left: 1 *$rem;
    border-radius: 5px;
  }
}



.aptmt_acty_selection_list_item_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 5 *$rem;
  flex: 1;
  margin-left: 0.6 *$rem;

  :nth-child(1) {
    font-family: PingFangSC-Semibold;
    font-size: 1.6 *$rem;
    font-weight: normal;
    letter-spacing: 0px;
    color: #000000;
  }

  :nth-child(2) {
    font-family: PingFang SC;
    font-size: 1.2 *$rem;
    font-weight: normal;
    letter-spacing: 0px;

    color: #9E9E9E;
  }
}


.aptmt_acty_selection_list_item_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1 *$rem;
  margin-left: 1 *$rem;
  width: 6.4 *$rem;
  height: 3.3 *$rem;
  border-radius: 3 *$rem;
  font-family: PingFang SC;
  font-size: 1.2 *$rem;
  font-weight: normal;
  letter-spacing: 0em;
  background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
  color: #624C16;
}

.aptmt_old_acty {
  width: 100%;


  .aptmt_old_acty_des {
    font-family: PingFang SC;
    font-size: 1.7 *$rem;
    color: #000000;
    margin-top: 2 *$rem;
    margin-left: 3%;
    font-weight: bold;
  }
}


