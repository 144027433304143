@import 'scss/variables.scss';

.mask {
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 601 / 20*$rem;
        height: 623 / 20*$rem;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .imgBg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            right: 0;
        }

        .headTitle {
            position: absolute;
            top: 120 / 20*$rem;
            color: #FEE9CB;
            font-size: 35 / 20*$rem;
            font-weight: bold;
            letter-spacing: 0.1*$rem;
            text-shadow: 0 0.1*$rem 0.2*$rem rgba(0, 0, 0, 0.9);
        }

        .icon {
            position: absolute;
            top: 220 / 20*$rem;
            width: 130 / 20*$rem;
            height: 120 / 20*$rem;
            object-fit: contain;
        }

        .title {
            position: absolute;
            top: 356 / 20*$rem;
            color: #960002;
            font-size: 22 / 20*$rem;
            font-weight: bold;
        }

        .subTitle {
            position: absolute;
            top: 390 / 20*$rem;
            color: #E05C38;
            font-size: 20 / 20*$rem;
            line-height: 28/ 20*$rem;
            width: 300 / 20*$rem;
            text-align: center;
            text-overflow: ellipsis;
            word-break: break-all;
            white-space: pre-line;
        }

        .leftBtn {
            position: absolute;
            bottom: 46 / 20*$rem;
            left: 100 / 20*$rem;
            width: 180 / 20*$rem;
            height: 60 / 20*$rem;
            line-height: 60 / 20*$rem;
            color: #960002;
            font-size: 25 / 20*$rem;
            font-weight: bold;
            text-align: center;
            transform: rotate(3deg);
        }

        .rightBtn {
            position: absolute;
            bottom: 46 / 20*$rem;
            right: 100 / 20*$rem;
            width: 180 / 20*$rem;
            height: 60 / 20*$rem;
            line-height: 60 / 20*$rem;
            color: #960002;
            font-size: 25 / 20*$rem;
            font-weight: bold;
            text-align: center;
            transform: rotate(-3deg);
        }
    }
}