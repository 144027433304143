@import 'scss/variables.scss';
.wrap {
  .top {
    overflow: hidden;
    position: relative;
    img {
      float: left;
      width: 100%;
    }
  }
  .bottom {
    position: relative;
    overflow: hidden;
    width: 100%;
    .bg {
      float: left;
      width: 100%;
    }
    .content {
      position: absolute;
      top: 50%;
      width: 100%;
      .bts_wrap {
        display: flex;
        justify-content: space-around;
        font-weight: 500;
        font-size: 1.5*$rem;
        .bt {
          background: linear-gradient(242deg, #FF40FB 0%, #FFFED3 100%);
          border-radius: 2.15*$rem;
          height: 4.3*$rem;
          line-height: 4.3*$rem;
          padding: 0 3*$rem;
          color: #092AB4;
        }
      }
    }
  }
}
