@import 'scss/variables.scss';

.yy_dialog_container {
  width: 100%;
  height: 100vh;
  display: flex;
  //align-items: center;
  justify-content: center;

  .yy_dialog_content {
    width: 78%;
    height: 19 *$rem;
    margin-top: 50%;
    border-radius: 12px;
    border: 0.1px solid transparent;
    opacity: 1;
    background: #FFFFFF;
    padding: 0 2 *$rem;

    .yy_dialog_title {
      font-family: PingFangSC-Medium;
      font-size: 1.6 *$rem;
      font-weight: normal;
      line-height: 2 *$rem;
      color: #333;
      margin-top: 1.5 *$rem;
      //margin: 1.5 *$rem 5vw;
    }

    .yy_dialog_line {
      width: 100%;
      line-height: 1.8 *$rem;
      color: #333333;
      margin-top: 1.5 *$rem;
      font-size: 1.4 *$rem;
      padding: 1.5*$rem 0;
      border-top: #D8D8D8 solid 0.5px;
      white-space: pre-line;
    }

    .yy_dialog_content_btns_container {
      width: 100%;
      height: 4 *$rem;
      margin-top: 1.5 *$rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .btn {
        width: 9.8 *$rem;
        height: 90%;
        border-radius:40px ;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Regular;
        font-size: 1.6 *$rem;
        font-weight: normal;
        letter-spacing: 1px;

        &.cancel {
          color: #CDAC5B;
          border: 1px solid #DFCFAB;
        }
        &.sure {
          color: #624C16;
          background: linear-gradient(90deg, #CCBD7B -8%, #F9ECB9 102%);
        }
      }
    }
  }
}
