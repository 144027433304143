@import 'scss/variables.scss';

.modal {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    touch-action: none;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 20;

    &.hide {
        visibility: hidden;
    }

    &.show {
        visibility: visible;
    }

    .arr {
        width: 1.2*$rem;
        height: auto;
        transition: 0.3s transform;
        transform: rotate(180deg);

        &.active {
            transform: rotate(0);
        }
    }

    .close {
        position: absolute;
        right: 1.2*$rem;
        top: 1.2*$rem;
        width: 1.6*$rem;
    }

    .majorModal {
        transition: 0.3s transform ease-out;
        background: white;
        position: absolute;
        border-radius: $rem $rem 0 0;
        bottom: 0;
        width: 100%;
        height: 25*$rem;
        padding: $rem;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.show {
            transform: translate3d(0, 0, 0);
        }

        &.hide {
            transform: translate3d(0, 100%, 0);
        }

        .close {
            top: 3*$rem;
        }

        .footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: $rem 1.8*$rem;

            button {
                @include lightButton($rem*15, $rem*4, 1.6*$rem);
                color: $deepGray;
                border-color: #979797;

                &:last-child {
                    background: linear-gradient(180deg, #FFEFA9 0%, #DEBB57 100%);
                    border: none 0px;
                    color: #6F5B20;
                }
            }
        }

        .nav {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                background: #F7F7F7;
                border-radius: 0.5*$rem;
                height: 3.6*$rem;
                width: 11*$rem;
                text-align: center;
                line-height: 3.6*$rem;
                color: $deepGray;
                font-size: 1.4*$rem;

                &.active {
                    background: #FFF7E0;
                    position: relative;
                    color: #8B5A19;
                }
            }
        }

        h2 {
            padding: 2*$rem 0;
            font-weight: bold;
            font-size: 1.6*$rem;
            color: $deepGray;
        }
    }

    .modalWrap {
        width: 32.5*$rem;
        background: white;
        border-radius: $rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        display: flex;
        flex-direction: column;
        padding: 0 2.4*$rem;
        padding-bottom: 2*$rem;

        h2 {
            padding: 2*$rem 0;
            text-align: center;
            font-weight: bold;
            font-size: 2*$rem;
            color: $deepGray;
        }

        .warnning {
            background: #FFF2C5;
            color: $deepGray;
            line-height: 1.4*$rem;
            padding: 0.6*$rem;
            text-align: center;
            font-size: $rem;
            margin:0 -0.6*$rem;
        }

        .line {
            display: flex;
            align-items: center;
            height: 4.2*$rem;
            border-bottom: 1px solid #E2E2E2;
            position: relative;

            &>div {
                flex: 1;
                height: 4.2*$rem;
                display: flex;
                align-items: center;
            }

            &>div.collegesSugget {
                position: absolute;
                top: 4*$rem;
                height: auto;
                width: 100%;
                right: 0;
                background: #F6F9FE;
                border: 1px solid #E4EAFF;
                z-index: 3;
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                p {
                    width: 20*$rem;
                    border-bottom: 1px solid #E2E2E2;
                    padding: 0 $rem;
                    color: $deepGray;
                    line-height: 3.4*$rem;
                    font-size: 1.2*$rem;

                    &:last-child {
                        border-bottom: 0px none;
                    }
                }
            }
        }

        .submit {
            @include deepButton(27*$rem, 4*$rem, 1.6*$rem);
            background: linear-gradient(180deg, #FFEFA9 0%, #DEBB57 100%);
            margin-top: 1.5*$rem;
            color: #6F5B20;
        }

        label {
            width: 8*$rem;
            font-size: 1.2*$rem;
            color: $deepGray;

            .red {
                color: red;
            }
        }

        input {
            width: $rem;
            flex: 1;
            color: $deepGray;
            text-overflow: ellipsis;
            font-size: 1.2*$rem;
        }

        .send {
            @include lightButton(auto, auto, 1.2*$rem);
            padding: 0.65*$rem 1.3*$rem;
            border-radius: 0.5*$rem;

            &.disable {
                color: $deepGray;
                border: 1px solid #999999;
            }

        }
    }

}