@import 'scss/variables.scss';
.container{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &.hide {
    visibility: hidden;
  }

 .contentBackground {
   z-index: 1;
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: -3*$rem;
   height: 21.5*$rem;
   width: 26.9*$rem;

    .contentFirst {
      background: #7146D3;
      margin-left: 1*$rem;
      margin-right: 1*$rem;
      border-radius: 1.3*$rem;
      box-sizing: border-box;
      padding-top: 1*$rem;
      padding-bottom: 1*$rem;
    }
   .modalButton {
     margin:1.5*$rem 0;
     display: flex;
     padding: 0 1.2*$rem;
     justify-content: space-between;
     .cancel {
       width: 10.5 *$rem;
       height: 3*$rem;
       border: 0.1 * $rem solid #BBBBBB;
       background:#BBBBBB;
       font-size: 1.4 * $rem;
       color: #FFFFFF;
       margin-right: 2.4*$rem;
       border-radius: 2 * $rem;
     }
     .confirm {
       height: 3*$rem;
       width: 10.5 *$rem;
       background-image: -webkit-linear-gradient(top, #FFC159, #FF7228);
       font-size: 1.4 * $rem;
       color: #ffffff;
       border-radius: 2 * $rem;
     }
   }

    .contentSecond {
      background: #9977FF;
      height: 100%;
      border-radius: 1.3*$rem;
      box-sizing: border-box;
      padding-top: 3*$rem;
      position: relative;

      .secondWord {
        position: absolute;
        left: 35%;
        top: 1.6*$rem;
        color: #000000;

        span {
          display: inline-block;
          margin-right: 1.6*$rem;
        }
      }
    }

    .contentline {
      margin-left: 1*$rem;
      margin-right: 1*$rem;
      height: 1*$rem;
      background: #3B489F;
      border-radius: 0.5*$rem;
    }

    .contentblank {
      position: relative;
      margin-left: 1.5*$rem;
      margin-right: 1.5*$rem;
      height: 13.2*$rem;
      background: #FFFFFF;
      top: -0.5*$rem;
      box-shadow: 0px 5px 0px #6851ad;
    }

    .contentInner {
      margin-left: 1.5*$rem;
      padding-top: 5.8*$rem;
    }

    .contentInvite {
        padding-top: 3.5*$rem;
        text-align: center;
    }

    .contentElement {
      display: flex;
      justify-content: center;
      padding-top: 3*$rem;
    }

    .elementFirst {

      font-size: 1.5*$rem;
      color: #252525;
      font-weight: bolder;
      margin-bottom: 2*$rem;
    }
   .elementSecond{
     font-size: 1.8*$rem;
     font-weight: bolder;
     color: #252525;
   }
  }
  .titleBG {
    width: 22*$rem;
    height: 5.85 * $rem;
    background: url("../images/row@2x.png") no-repeat;
    display: flex;
    flex-direction: column;
    background-size: 100% 100%;
    align-items: center;
    position: absolute;
    z-index: 100;
    top: -3*$rem;

    .title {
      margin-top: 2 * $rem;
      height: 2.8 * $rem;
      font-size: 1.8*$rem;
      font-weight: 800;
      color: #77000E;
      text-shadow: 0 0.1*$rem 0.15*$rem #F3AC59;
    }

    .des {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 37.5*$rem;
      height: 1.4*$rem;
      text-align: center;
      line-height: 1.4*$rem;
      font-size: $rem;
      color: #ffffff;
    }
  }


  .button{
    margin: 3.2*$rem auto 0 auto;
    display: flex;
    justify-content: center;
    width: 10.5*$rem;
    height: 3.1*$rem;
    background-image: -webkit-linear-gradient(top, #FFC159, #FF7228);
    line-height: 3.1*$rem;
    font-size: 1.4*$rem;
    color: #FFFFFF;
    border-radius: 1.6*$rem;
    .confirm {
      height: 4*$rem;
      width: 10.8 *$rem;
      background-image: -webkit-linear-gradient(top, #FFC159, #FF7228);
      font-size: 1.4 * $rem;
      color: #ffffff;
      border-radius: 2 * $rem;
    }
  }

}
