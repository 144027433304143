@import 'scss/variables.scss';

.share_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .share_container_content {
    width: 80vw;
    height: 36 *$rem;
    background: linear-gradient(180deg, #05512E 0%, #09170A 100%);
    border-radius: 12px;
    border: 1px solid #5AF8AB;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      margin-top: 1.8 *$rem;
      font-size: 2.2 *$rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }

    .styles_content_father {
      width: 86%;
      height: 23 *$rem;
      overflow: auto;
      margin-top: 10px;
      &::-webkit-scrollbar {
        display: flex;
      }

      img {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .content {
      width: 100%;
      //height:100%;
      font-size: 1.1 *$rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8EF4B1;
      line-height: 1.6 *$rem;
      letter-spacing: 1px;
      white-space: pre-line;
    }

    .content1 {
      width: 82%;
      height: 23 *$rem;
      font-size: 1.1 *$rem;
      overflow: auto;
      &::-webkit-scrollbar {
        display: flex;
      }

      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #8EF4B1;
      line-height: 1.6 *$rem;
      letter-spacing: 1px;
      margin-top: 10px;
      white-space: pre-line;
    }

    .sure_btn_father {
      width: 80%;
      height: 4 *$rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 1.5 *$rem;

      img {
        width: 100%;
        height: 100%;
      }


      .sure_btn_txt {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        //background-color: red;
        margin-top: -0.1 *$rem;

        span {
          font-size: 2.2 *$rem;
          font-weight: 400;
          color: #000000;
          font-family: RuiZiZhenYan;
        }
      }
    }
  }
}




