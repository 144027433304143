@import 'scss/variables.scss';

.share_container {
  width: 100%;
  min-height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .share_body {
    width: 33*$rem;
    margin-top: 9 *$rem;
    position: relative;
    border-radius: 2 *$rem;
    overflow: hidden;
    .share_content {
      width: 100%;
      .share_img_box {
        display: flex;
        justify-content: center;
        align-items: center;
        .share_avatar {
          width: 100%;
          height: 46.2*$rem;
        }
      }
      .share_detail {
        position: absolute;
        right: 3%;
        bottom: 2%;
        width: 7.5*$rem;
        height: 7.5*$rem;
        border-radius: 0.8*$rem;
        display: flex;
        justify-content: center;
        align-items: center;
        canvas {
          max-width: 6.8*$rem;
          max-height: 6.8*$rem;
        }
      }
    }
  }
  .opt {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80vw;
    margin-top: 3 *$rem;
    img {
      width: 4.6 *$rem;
      height: 4.6 *$rem;
    }
    .wechat,
    .share,
    .copy,
    .download {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.2 *$rem;
      color: #fff;
      span {
        margin-top: 0.8 *$rem;
      }
    }
  }
}

.cancel_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80vw;
  height: 5 *$rem;
  overflow: hidden;
  margin-top: 3 *$rem;
  border-radius: 2.5 *$rem;
  background-color: #262626;;
  font-size: 1.6 *$rem;
  font-weight: bold;
  color: #C1C1C1;
}
