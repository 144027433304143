@import 'scss/variables.scss';
.container{
  width: 100%;
  height: 100vh;
  background: #ECEEF0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img {
    margin-top: 3.6 * $rem;
    margin-bottom: 0.85 * $rem;
    width: 4 * $rem;
    height: 4 *$rem;
  }
  .label1 {
    font-size: 1.6 * $rem;
    font-weight: 600;
    color: #333333;
    line-height: 2.25 * $rem;
    margin-bottom: 3 * $rem;
  }

  .contentBG {
    width: 33.5 * $rem;
    height: 28.9 * $rem;
    background: #FFFFFF;
    border-radius: $rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      width: 27 * $rem;
      height: 16.3 * $rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-bottom: 0.05 * $rem dashed #D9D9D9;
      padding: 1.5 * $rem 0 2 *$rem;
      .rowWrap {
        flex-direction: row;
        display: flex;
        align-items: center;
        .title {
          width: 7 * $rem;
          font-size: 1.4 * $rem;
          color: #666666;
          line-height: 2 * $rem;
        }
        .des {
          margin-left: 1.2 *$rem;
          font-size: 1.4 * $rem;
          color: #333333;
          line-height: 2 * $rem;
        }
      }
    }
    .contentTip {
      width: 30.25 * $rem;
      height: 9.15 * $rem;
      border-radius: 0.5 * $rem;
      background: rgba(100, 31, 109, 0.06);
      display: flex;
      flex-direction: column;
      margin-top: 1.55 * $rem;
      padding: 1.05 * $rem 1.8 * $rem 1.5 * $rem;
      .row1 {
        width: 20 * $rem;
        height: 2 * $rem;
        display: flex;
        align-items: center;
        .img2 {
          width: 1.3 * $rem;
          height: 1.65 * $rem;
        }
        .title2 {
          font-size: 1.4 * $rem;
          font-weight: 400;
          color: #641F6D;
          line-height: 2 * $rem;
          margin-left: 0.8 * $rem;
        }
      }
      .text {
        margin-top: 0.75 * $rem;
        font-size: 1.4*$rem;
        color: #333333;
        line-height: 2*$rem;
      }
      .text2 {
        color: #641F6D;
      }
    }
  }


  .commit {
    margin-top: 2*$rem;
    bottom: $rem;
    width: 33.6 * $rem;
    height: 4.4 * $rem;
    background: #641F6D;
    border-radius: 0.5 * $rem;
    color: #fff;
    font-size: 1.6 * $rem;

  }
}
