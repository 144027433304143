$dpr: 1;
$rem: 0.5rem/$dpr;
$gray:#666;
$orange:#FA6400;
$typeColor:#CAA846;
$deepGray:#333;
$lightGray:#999;
$white:#FFF;
$background:rgba(0, 0, 0, 0.8);
$assetUrl:"https://icon.gkewang.com/gu/weapp";
$assetVersion : "20200916144304";

$device_width: 375;
$vw: (100vw / $device_width);

@mixin backgroundImage($url) {
    background-image: url($assetUrl+$url+'?version='+$assetVersion);
}

@mixin button($width, $height, $fontSize) {
    border-radius: $height/2;
    border-radius: $height/2;
    width: $width;
    height: $height;
    line-height: $height;
    font-size: $fontSize;
    box-sizing: content-box;
    text-align: center;
}

@mixin lightButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background-image: #FFF;
    border: 1px solid #DFCFAB;
    color: #CDAC5B;
    box-sizing: border-box;
}

@mixin deepButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background-image: linear-gradient(120deg, #D4BA77 0%, #FFEAB4 100%);
    border: none 0px;
    color: #624C16;
}

@mixin disableButton($width, $height, $fontSize) {
    @include button($width, $height, $fontSize);
    background: #ccc;
    border: none 0px;
    color: $lightGray;
}
