@import 'scss/variables.scss';

.goddess-receive-modal {
  .goddess-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1.65*$rem 2.05*$rem;
    width: 27.9*$rem;
    background: #FFFFFF;
    border-radius: 1*$rem;
    .goddess-modal-title {
      color: #333333;
      font-size: 1.6*$rem;
      font-weight: bold;
    }
    .receive-status {
      padding: 1.5*$rem 0 2*$rem;
      text-align: center;
      color: #333333;
      font-size: 1.4*$rem;
    }
    .wenxin-tip {
      text-align: center;
      color: #333333;
      font-size: 1.4*$rem;
      line-height: 1.8*$rem;
    }
    .goddess-receive-btn {
      margin-top: 1.4*$rem;
      width: 11.45*$rem;
      height: 3.5*$rem;
      border-radius: 3.5*$rem;
      background: #ED3B70;
      color: #FFFFFF;
      font-size: 1.5*$rem;
      line-height: 3.5*$rem;
      text-align: center;
    }
  }
}