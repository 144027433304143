@import 'scss/variables.scss';
$midBlod: 600;
$gray:#666;
$deepGray:#333;
$lightGray:#999;
$white:#FFF;
$background:rgba(0, 0, 0, 0.8);
$red:#FF5757;
$gold:#DFCFAB;
$lightGold:#CDAC5B;

.button {
    @include deepButton($rem*28, $rem*4.4, $rem*1.2);
    border-radius: 3.65 * $rem;
    background: linear-gradient(121deg, #D4BA77 0%, #FFEAB4 100%);
    color: #624C16;
    font-weight: 400;
    line-height: $rem*4.4;
    font-size: 1.8 * $rem;
    margin-top: 6* $rem;
}

.rulesContainer {
    position: absolute;
    height: 100%;
    top:0;
    left: 0;
    overflow-y: auto;
    background: #FFFBF2;
    

    .ruleContent {
        height: 34.5* $rem;
        padding: 69* $rem;
        padding: 1.5* $rem;
        font-size: 1.3* $rem;
        font-weight: 400;
        color: #000;

    }

    .ruleContent p span {
        line-height: 2 * $rem;
    }
}

.endContainer {
    background-color: #F5F6F8;
    height: 100vh;
    text-align: center;

    .endImage {
        margin-top: 9.15 *$rem;
        width: 20* $rem;
        height: 13.5* $rem;
    }

    .endTip {
        // width: 19.75*$rem;
        height: 2*$rem;
        font-size: 1.4*$rem;
        font-weight: 400;
        color: #999999;
        line-height: 2*$rem;
    }
}

.tabs__link {
    color: #7E5B23;
}

.tabs__link__text {
    padding-bottom: 1.2 * $rem;
    border-bottom: 2px solid #7E5B23;
}

.tabs {
    height: 5* $rem;
    background: linear-gradient(180deg, #FEFBF2 0%, #F5E8C9 100%);
    z-index: 9999;
    position: fixed;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    white-space: nowrap;
    top: 0;
    visibility: hidden;
}

.tabs_visibility {
    visibility: visible;
}

.tabItem {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 4.2*$rem;
    height: 2*$rem;
    font-size: 1.4*$rem;
    font-weight: $midBlod;
    color: #666;
    line-height: 2 * $rem;
}

.container {
    width: 37.5* $rem;
    background-color: #F5F6F8;
    position: relative;
    font-size: 0;

    .banner {
        height: 45 * $rem;
        background-image: url("./images/kv@2x.png");
        background-size: 100% 100%;
        position: relative;
    }

    .imgTitle {

        height: 2.5*$rem;
        font-size: 2*$rem;
        font-weight: 500;
        color: #FFF8E3;
        line-height: 2.5*$rem;
        letter-spacing: 0.3*$rem;
        text-shadow: 0 0.1*$rem 0.2*$rem rgba(0, 0, 0, 0.5);
    }

    .tripBanner {
        // position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35.5 * $rem;
        margin-left: $rem;
        margin-top: $rem;
        // background-image: url("./images/jzh@2x.png");
        background-size: 35.5 * $rem 8* $rem;
        height: 8 * $rem;

    }

    // .tripBanner:not(:first-child) {
    //     margin-top: 2* $rem;
    // }

    .footLog {
        text-align: center;

        .footerImage {
            margin: 6.6*$rem 0 8.2*$rem 0;
            width: 13.45 * $rem;
            height: 2.7 * $rem;
        }
    }

    .activitieRule {
        position: absolute;
        right: 1.5 * $rem;
        top: 1.2* $rem;
        width: 6 * $rem;
        height: 1.9 * $rem;
        background: #000000;
        border-radius: 0.9* $rem;
        opacity: 0.3;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 1.2* $rem;
    }


    //taro样式
    .TourCell {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .TourCellWrap {
        // float: left;
        margin-top: $rem;
        margin-left: $rem;
        width: 17.3 * $rem;
        height: 27.1 * $rem;
        background: white;
        border-radius: 0.6*$rem;
        padding-bottom: 1*$rem;
        overflow: hidden;
        // float: left;

        .content {
            padding: 0.5*$rem 0.75*$rem 0;
        }


        .subTitle {
            font-size: 1.1*$rem;
            color: $lightGray;
            line-height: 1.4*$rem;
            display: block;
            margin-top: $rem*0.7;
            margin-bottom: $rem*0.6;
        }


        .tagWrap {
            position: relative;
            bottom: 0.2* $rem;
            height: 2.5*$rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .tag {
                display: flex;
                height: $rem*1.5;
                padding: 0 0.5*$rem;
                position: relative;
                margin-right: 0.3*$rem;
                align-items: center;
                justify-content: center;

                &:after {
                    position: absolute;
                    content: '';
                    border: 1PX solid $gold;
                    border-radius: 0.2*$rem;
                    display: block;
                    box-sizing: border-box;
                    width: 200%;
                    height: 200%;
                    top: 0;
                    left: 0;
                    transform: scale(0.5);
                    transform-origin: 0 0;
                }

                .tagText {
                    white-space: nowrap;
                    line-height: 1.5*$rem;
                    color: $lightGold;
                    font-size: 0.9*$rem;
                }

                &.full {
                    &::after {
                        content: none;
                    }

                    border-radius: 0.2*$rem;
                    background:#F5EDD6;

                    .tagText {
                        color: #8E6D29;
                    }
                }
            }
        }


        .thumbnail {

            position: relative;
            overflow: hidden;
            width: $rem*17.3;
            height: $rem*17.3;
            background-size: $rem*17.3 $rem*17.3;

            .addressWrap {
                position: absolute;
                background: rgba(0, 0, 0, 0.3);
                height: 1.8*$rem;
                border-radius: 0.9*$rem;
                padding: 0 0.75*$rem;
                bottom: $rem;
                left: $rem;
                display: flex;
                align-items: center;

                .addressIcon {
                    width: 0.8*$rem;
                    height: 0.95*$rem;
                    margin-right: 0.2*$rem;
                }

                .address {
                    font-size: $rem;
                    color: white;
                    line-height: $rem;
                }
            }

        }

        .title {
            height: 3.8* $rem;
            font-size: 1.4*$rem;
            color: $deepGray;
            font-weight: bold;
            line-height: 1.9*$rem;
            display: block;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;


            // overflow: hidden;
            // // text-overflow: ellipsis;
            // white-space: nowrap;
            // font-size: 1.4*$rem;
            // color: $deepGray;
            // font-weight: bold;
            // line-height: 1.9*$rem;
            // // text-overflow: -o-ellipsis-lastline;

            // display: block;
            // text-overflow: -o-ellipsis-lastline;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // display: -webkit-box;
            // -webkit-line-clamp: 2;
            // -webkit-box-orient: vertical;

        }

        .moneyWrap {
            margin-top: 1.2 *$rem;
            // position: po;
            // bottom: -0.9 * $rem;
            height: 1.9*$rem;
            // margin-top: 1.9*$rem;
            padding-bottom: 0.9*$rem;
            display: flex;
            align-items: flex-end;

            .money {
                font-size: 1.9*$rem;
                line-height: 1.9*$rem;
                color: $red;
                font-weight: bold;
            }

            .rmb {
                font-size: 0.9*$rem;
                font-weight: normal;
                width: $rem*0.95;
                color: $red;
                display: inline-block;
                ;
            }

            .begin {
                color: $lightGray;
                font-size: $rem;
                font-weight: normal;
                margin-left: $rem*0.2;
            }
        }
    }

}