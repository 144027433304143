@import 'scss/variables.scss';

.root {
  width: 100%;
  height: 100%;

  .backBtn {
    position: fixed;
    top: 4 * $rem;
    left: 1.5 *$rem;
    width: 1.8*$rem;
    height: 1.8*$rem;
    background: url(~images/icon/back@2x.png) no-repeat;
    background-size: 100%;
    z-index: 99;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #C40103;
    padding-bottom: 100 / 20*$rem;

    .topBg {
      position: absolute;
      left: 0;
      right: 0;
    }

    
    .tipRight1Btn {
      position: absolute;
      right: 0;
      top: 78 / 20*$rem;
      background-color: #FEE9CB;
      color: #C70003;
      padding: 0.8 * $rem;
      padding-left: 1.1 * $rem;
      font-weight: bold;
      border-radius: 5 * $rem 0 0 5 * $rem;
      opacity: 0.8;
      font-size: 24  / 20*$rem;
    }
    .tipRight2Btn {
      position: absolute;
      right: 0;
      top: 150 / 20*$rem;
      background-color: #FEE9CB;
      color: #C70003;
      padding: 0.8 * $rem;
      padding-left: 1.1 * $rem;
      font-weight: bold;
      border-radius: 5 * $rem 0 0 5 * $rem;
      opacity: 0.8;
      font-size: 24  / 20*$rem;
    }

    .metaBtn {
      position: absolute;
      right: 0;
      top: 550 / 20*$rem;

      .metaBtnImg {
        width: 130 / 20*$rem;
        height: 130 / 20*$rem;
      }
    }

    .flexContainer {
      flex: 1;
      width: 100%;
      margin-top: 881 / 20 * $rem;
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-items: center;

      .inviteContainer {
        flex: 1;
        width: 100%;
        padding: 0 26/20*$rem 0 26/20*$rem;
        display: flex;
        flex-direction: column;
        justify-self: center;
        align-items: center;

        .inviteBtn {
          width: 100%;
          height: 147/20 *$rem;
          position: relative;

          .inviteBtnBg {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }

        .inviteRecordBtn {
          z-index: 9;
          margin-top: (32/20 - 1) *$rem;
          font-size: 28/20 *$rem;
          color: #FEE9CB;
          justify-self: center;
          align-items: center;
          padding: 1* $rem;
        }
      }

      .wishContainer {
        margin: 50 /20*$rem 45/20*$rem 0 45/20*$rem;
        padding: 0 30 / 20*$rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .wishBg {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 0;
        }

        .wishTitle {
          z-index: 10;
          margin-top: 20/20*$rem;
          font-size: 35/20 * $rem;
          color: #FEE9CB;
          font-weight: 700;
        }

        .wishInput {
          z-index: 10;
          width: 100%;
          height: 60 / 20*$rem;
          margin-top: 80/20*$rem;
          color: #FEE9CB99;
          font-size: 26/20*$rem;
          border-bottom: 1px solid #FEE9CB99;

          &.wishInputSelected {
            color: #FEE9CB;
            font-weight: bold;
          }
        }

        .wishSelectTitle {
          z-index: 10;
          margin-top: 42/20*$rem;
          font-size: 35/20 * $rem;
          color: #FEE9CB;
          font-weight: 700;
        }

        .wishSelectContainer {
          z-index: 10;
          width: 100%;
          margin-top: (37-25)/20*$rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: space-between;
          justify-content: space-between;

          .wishSelectCell {
            z-index: 10;
            width: 28%;
            line-height: 60 / 20 *$rem;
            text-align: center;
            font-size: 26 / 20 *$rem;
            margin-top: 25 / 20 *$rem;
            color: #960002;
            background-color: #FFC089;
            border-radius: 999rem;
          }
        }

        .wishBtn {
          z-index: 10;
          width: 100%;
          height: 90 / 20 *$rem;
          margin-top: 42 / 20 *$rem;
          border-radius: 999rem;
          background: linear-gradient(90deg, #FEEED6 1%, #FBD093 100%);
          display: flex;
          flex-direction: column;
          justify-self: center;
          align-items: center;

          .wishBtnTitle {
            z-index: 10;
            margin-top: 2 / 20 *$rem;
            color: #960002;
            font-size: 35 / 20*$rem;
            font-weight: bold;
            line-height: 51 / 20*$rem;
          }

          .wishBtnSubTitle {
            z-index: 10;
            color: #C70003;
            font-size: 26 / 20*$rem;
            line-height: 38 / 20*$rem;
          }
        }
      }



      .myWishContainer {
        z-index: 9;
        margin-top: 30 / 20*$rem;
        padding: 0 45 / 20*$rem;
        width: 100%;
        position: relative;
        .myWishListContent {
          background-color: #960002;
          height: 370 / 20*$rem;
          width: 100%;
          border-radius: 30 / 20*$rem;
          padding: 40 / 20*$rem;
          display: flex;
          flex-direction: column;
          position: relative;

          .myWishListHeaderContainer {
            display: flex;
            flex-direction: row;
            align-items: center;

            .myWishListHeaderIcon {
              width: 80 / 20*$rem;
              height: 80 / 20*$rem;
              background-color: #D8D8D8;
              border-radius: 999px;
            }

            .myWishListHeaderTitle {
              margin-left: 20 / 20*$rem;
              color: #FEE9CB;
              font-size: 30 / 20*$rem;
              font-weight: bold;
            }

            .myWishListHeaderSubTitle {
              margin-left: 20 / 20*$rem;
              color: #FFFFFF;
              font-size: 20 / 20*$rem;
              font-weight: bold;
            }
          }

          .myWishList {
            z-index: 9;
            margin-top: 25 / 20*$rem;
            flex:1;
            .myWishListCell {
              display: flex;
              flex-direction: row;
              align-items: center;
              
              .myWishListCellTitle {
                font-size: 20 / 20*$rem;
                color: #FFFFFF;
                min-width: 142  / 20*$rem;
              }

              .myWishListCellSubTitle {
                font-size: 20 / 20*$rem;
                color: #FEE9CB;
              }
            }
          }
        }
        .myWishListMask {
          z-index: 10;
          position: absolute;
          left: 45 / 20*$rem;
          right: 45 / 20*$rem;
          bottom: 30 / 20*$rem;
          height: 60 / 20*$rem;
          background: linear-gradient(to bottom, #96000200, #960002);
          // background-color: blue;
        }
      }


      .drawBtnsContainer {
        margin: 24 / 20*$rem 40 / 20*$rem 0 40 / 20*$rem ;
        padding: 0 60 / 20*$rem;
        width: 100%;
        height: 80 / 20*$rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .drawBtn {
          background-color: #FFC500;
          color: #960002;
          font-size: 28 / 20*$rem;
          font-weight: bold;
          // flex: 1;
          width: 42%;
          line-height: 80 / 20*$rem;
          border-radius: 999rem;
          text-align: center;
        }
      }

      .winnerListContainer {
        padding: 50 / 20*$rem 45 / 20*$rem 0 45 / 20*$rem;
        width: 100%;

        .winnerListContent {
          background-color: #FBF3EF;
          height: 370 / 20*$rem;
          width: 100%;
          border-radius: 30 / 20*$rem;
          padding: 40 / 20*$rem;
          display: flex;
          flex-direction: column;
          position: relative;

          .winnerListTitle {
            font-size: 30 / 20*$rem;
            color: #960002;
            font-weight: bold;
          }

          .winnerListMask {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 30 / 20*$rem;
            height: 60 / 20*$rem;
            border-radius: 30 / 20*$rem;
            background: linear-gradient(to bottom, #FBF3EF00, #FBF3EF);
          }

          .winnerList {
            flex:1;
            margin-top: 25 / 20*$rem;

            .winnerListCell {
              display: flex;
              flex-direction: row;
              align-items: center;

              .winnerListCellTitle {
                font-size: 20 / 20*$rem;
                color: #9E9E9E;
                min-width: 142  / 20*$rem;
              }

              .winnerListCellSubTitle {
                font-size: 20 / 20*$rem;
                color: #C2553F;
              }
            }
          }
        }
      }

      .logo {
        margin-top: 40 / 20*$rem;
        height: 60 / 20*$rem;
      }
    }
  }
}






.inviteRecordContainer {
  border-top-left-radius: $rem;
  border-top-right-radius: $rem;
  max-height: 62%;
  padding: 35 / 20*$rem 35 / 20*$rem 100 / 20*$rem 35 / 20*$rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;

  .inviteRecordTitle {
    color: #000000;
    font-size: 36 / 20*$rem;
    line-height: 50 / 20*$rem;
  }

  .inviteRecordSubTitle {
    margin-top: 28 / 20*$rem;
    color: #960002;
    font-size: 28 / 20*$rem;
    line-height: 40 / 20*$rem;
  }

  .inviteRecordListContainer {
    margin-top: 40 / 20*$rem;
    overflow: scroll;
    width: 100%;

    .inviteRecordListItemContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 12 / 20*$rem;

      .inviteRecordListItemIcon {
        width: 72 / 20*$rem;
        height: 72 / 20*$rem;
        border-radius: 50%;
        background-color: #D8D8D8;
      }

      .inviteRecordListItemPhone {
        margin-left: 20 / 20*$rem;
        color: #000000;
        font-size: 24 / 20*$rem;
      }

      .inviteRecordListItemFlex {
        flex: 1
      }

      .inviteRecordListItemTime {
        margin-left: 20 / 20*$rem;
        color: #000000;
        font-size: 24 / 20*$rem;
      }
    }
  }
}