@import 'scss/variables.scss';

.integral_rule_wrap {
    flex: 1;
    height: 100vh;
    background: url('./img/rule_background.png') bottom center no-repeat;
    //background-size: 100%;
    padding: 1.5*$rem;

    p {
        margin-bottom: 1.5*$rem;
        font-size: $rem*1.4;
        color: #5F350F;
        line-height: $rem*2.1;
    }
}
