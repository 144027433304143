@import 'scss/variables.scss';

.container {
  padding: 1.5*$rem;
  background: #F7F7F7;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .reward {
    margin-bottom: 1*$rem;

    position: relative;

    .content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 2*$rem 1.5*$rem;
      box-shadow: 0 0.25*$rem 0.7*$rem 0 rgba(0, 0, 0, 0.06);
      border-radius: 0.6*$rem;
      background: #FFFFFF;
      position: relative;


      .title {
        color: #333333;
        font-size: 1.6*$rem;
        font-weight: bold;
      }

      .date {
        font-size: $rem;
        color: #999999;
        margin-top: 1.2*$rem;
      }

      .editAddressBg {
        width: 31.5*$rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .line {
          width: 31.5*$rem;
          height: 0.05*$rem;
          background: #E6E6E6;
          margin-top: $rem;
        }
        .text {
          margin-top: 2*$rem;
          font-size: 1.6*$rem;
          color: #B89969;
          line-height: 2.25*$rem;
          text-decoration:underline;
        }
      }



    }

  }
}
