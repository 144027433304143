@import 'scss/variables.scss';

.world-cup-settle-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  .world-cup-settle-modal {
    position: relative;
    padding: 0 1.6*$rem 9.9*$rem;
    width: 32*$rem;
    height: 40.8*$rem;
    border-radius: 1*$rem;
    background: #FFFFFF;
    .world-cup-settle-title {
      padding: 1.4*$rem 0 1.6*$rem;
      font-size: 1.75*$rem;
      color: #3D3D3D;
      font-weight: bold;
      text-align: center;
    }
    .world-cup-settle-content {
      .points-detail-box {
        height: 20*$rem;
        overflow: auto;
        width: 100%;
      }
      .settle-modal-item {
        background: #FFFFFF;
        .common-flex {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .item-top {
          margin: 0 0.2*$rem 0.5*$rem;
          padding-bottom: 1.6*$rem;
          border-bottom: 0.05*$rem solid #D8D8D8;
          span {
            &:nth-child(1) {
              font-size: 1.2*$rem;
              color: #999999;
            }
            &:nth-child(2) {
              font-size: 1.8*$rem;
              color: #000000;
            }
          }
        }
        .points-detail-box {
          padding-bottom: 0.5*$rem;
          border-bottom: 0.05*$rem solid #D8D8D8;
        }
        .item-bottom {
          align-items: center;
          padding-top: 1.5*$rem;
          font-size: 1.2*$rem;
          color: #000000;
          i {
            font-size: 1.8*$rem;
            color: #FF5100;
          }
        }
        .field-points {
          padding: 1*$rem 0;
          font-size: 1.2*$rem;
          color: #000000;
          .field-points-title {
            &-right {
              display: flex;
              align-items: center;
              padding-right: 0.5*$rem;
              .close-arrow-img {
                margin-left: 0.9*$rem;
                height: 0.85*$rem;
                width: 0.45*$rem;
                transform: rotateZ(90deg);
              }
              .open-arrow-img {
                margin-left: 0.9*$rem;
                width: 0.85*$rem;
                height: 0.45*$rem;
                transform: rotateZ(-180deg);
              }
            }
          }
          .field-points-detail {
            font-size: 1.2*$rem;
            color: #9E9E9E;
            &-item {
              padding: 1*$rem 1.6*$rem 0;
            }
            &.points-detail-show {
              display: block;
            }
            &.points-detail-hide {
              display: none;
            }
          }
        }
      }
    }
    .settle-modal-btn {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 1.6*$rem 2*$rem;
      .btn-common {
        width: 20*$rem;
        height: 4*$rem;
        text-align: center;
        line-height: 4*$rem;
        background: #000;
        border-radius: 2*$rem;
        color: #FFFFFF;
        font-size: 1.8*$rem;
      }
      .cancel-settle {
        margin-right: 0.8*$rem;
        background: rgba(0, 0, 0, 0.4);
      }
      .confirm-settle {
        background: #3662EC;
      }
    }
  }
}