@import 'scss/variables.scss';

.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    .mask {
        position: relative;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .close {
            margin-top: 3.9*$rem;
            width: 2.2*$rem;
            height: 2.2*$rem;
            background: url("./images/close2.png") no-repeat;
            background-size: 100% 100%;
        }
        .modal {
            width: $rem*30;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: white;
            border-radius: 1.2*$rem;
            background: url("./imgs/bg_fenxiang4.png") no-repeat;
            background-size: 100% 100%;
            padding-bottom: 2*$rem;

            .topTitle {
                margin-bottom: 0.5 * $rem;
                width: 25 * $rem;
                height: 4.75 * $rem;
                background-size: 100%;
                text-align: center;
                line-height: 4.75 * $rem;
                font-size: 1.6 * $rem;
                color: #7E5B23;
            }

            .modalContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                .tip {
                    width: 100%;
                    color: #7E5B23;
                    font-size: 1.35*$rem;
                    line-height: 3*$rem;
                    text-align: center;
                    margin-bottom: 0.8*$rem;
                }
                .img {
                    margin-top: 10*$rem;
                    width: 28.05*$rem;
                    height: 24.05*$rem;
                }

            }

            .modalFooter {
                margin-top: 1.2*$rem;
                display: flex;
                align-items: center;
                justify-content: center;
                .button {
                    width: 21*$rem;
                    height: 4*$rem;
                    background: linear-gradient(98deg, #D4BA77 4%, #FFEAB4 99%);
                    box-shadow: 0px 0px 10px 0px rgba(96, 76, 26, 0.76);
                    border-radius: 2.5*$rem;
                    color: #7E5B23;
                    font-size: 1.8*$rem;
                    letter-spacing: 1*$rem;
                    font-weight: bold;
                }
            }
        }
    }
}
