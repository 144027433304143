@import 'scss/variables.scss';

.helpCenter{
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    font-size: 1.4*$rem;
    .helpCenterWrap li:last-child{
        border-bottom: none;
    }
    .helpItem{
        line-height: 4*$rem;
        border-bottom: 1px solid #eee;
        padding-left: 2*$rem;
    } 
    .itemIcon{
        height: .5*$rem;
        width: .5*$rem;
        background-color: #ccc;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-right: 1*$rem;
    }  
}