@import 'scss/variables.scss';


.back_rule {
    top: 0;
    position: fixed;
    z-index: 20;
    width: 100%;

    .activietyRules {
        font-size: 1.6* $rem;
        color: $deepGray;
        font-weight: bold;
        text-align: center;
        flex: 1;
        margin-right: 5*$rem;
        // padding-top: 2* $rem;
    }

    .back_icon {
        margin-left: 0.9 * $rem;
        width: 2 * $rem;
        height: 2 * $rem;
    }

    .back_area {
        width: 5* $rem;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.backWrap {
    height: 4.4*$rem;
    display: flex;
    align-items: center;
}