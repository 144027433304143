@import './reset.scss';
@import './variables.scss';
@import './font/iconfont.css';

html {
    // overflow: hidden;
}

[disabled] {
    // background: #ddd !important;
    // border-color: #ddd;
    // color: $lightGray !important;
    filter: grayscale(100%);
}

body {
    position: relative;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

}

#root{
    position: relative;
    width: 100%;
    min-height: 100%;
}

pre {
    font-family: "PingFang SC",
        "HanHei SC",
        "microsoft yahei",
        Arial !important;
}

.react-swipeable-view-container {
    position: relative;
    height: 100%;

    div {
        position: relative;
        height: 100%;
    }
}


.fullScreen footer {
    padding-bottom: $rem*3.4;
}
