@import 'scss/variables.scss';

.container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;

    .mask {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);

        .modal {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: $rem*29.5;
            height: 33.4*$rem;
            display: flex;
            flex-direction: column;
            background: white;
            border-radius: 1.2*$rem;
            padding: 0 1.5*$rem;

            .modalTitle {
                position: absolute;
                top: -4*$rem;
                font-size: 2.2*$rem;
                color: $deepGray;
                text-align: center;
                left: 6.5*$rem;
                width: 16.4*$rem;
                height: 9.2*$rem;
            }

            .modalContent {
                display: flex;
                flex-direction: column;
                align-items: center;

                h1 {
                    line-height: 2.25*$rem;
                    font-size: 1.6*$rem;
                    margin-top: $rem*5.55;
                    color: #FF581E;
                }

                p {
                    color: $deepGray;
                    font-size: 1.6*$rem;
                    line-height: 2.25*$rem;
                    margin: 1.45*$rem auto 2.95*$rem;
                }

                .rewardInfo {
                    img {
                        vertical-align: middle;
                        width: 3.55*$rem;
                        margin-right: $rem;
                    }

                    color:#B64C24;
                    font-size:1.6*$rem;
                }

                .rewardFuture {
                    width: 24.4*$rem;
                    background: rgba(255, 126, 47, 0.1);
                    border-radius: 2*$rem;
                    font-size: 1.4*$rem;
                    text-align: center;
                    height: 4.15*$rem;
                    line-height: 4.15*$rem;
                    color: #B64C24;
                    font-weight: bold;
                    margin-top: 1.65*$rem;

                    img {
                        width: 2.15*$rem;
                    }
                }
            }

            .modalFooter {
                height: $rem*7;
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    width: 15.8*$rem;
                    height: 4.4*$rem;
                    background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
                    box-shadow: 0.05*$rem 0.15*$rem 0.4*$rem 0px #FFBE92;
                    border-radius: 2.2*$rem;
                    color: white;
                    margin-top: 2.9*$rem;
                    font-size: 1.8*$rem;
                }
            }
        }
    }
}