@import 'scss/variables.scss';

.container {
  //background-color: purple;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title_des {
  margin-top: 3.7 *$rem;
  font-size: 2 *$rem;
  font-family: RuiZiZhenYan;
  font-weight: 400;
  color: #8EF4B1;
  line-height: 2 *$rem;
  letter-spacing: 3px;
}

.title_des1 {
  font-size: 1.2 *$rem;
  color: #8EF4B1;
  margin-top: 0.8 *$rem;
}

.choujuan_count_father {
  flex-direction: row;
  width: 100%;
  height: 2 *$rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.8 *$rem;

  .choujuan_count {
    font-size: 1.7 *$rem;
    font-weight: 600;
    color: #FF4E00;
    letter-spacing: 2px;
  }

  .choujuan_count_des {
    font-size: 1.3 *$rem;
    //font-family: RuiZiZhenYan;
    font-weight: 100;
    color: #8EF4B1;
    letter-spacing: 1px;
  }

  img {
    width: 0.6 *$rem;
    height: 1.1 *$rem;
  }
}

.btn_baoming_father {
  width: 27.5 *$rem;
  height: 4.45 *$rem;
  margin-top: 1 *$rem;
  position: relative;
  //background-color: skyblue;

  .btn_baoming_title_father {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    //background-color: red;
    margin-top: -0.1 *$rem;

    .btn_baoming_title0 {
      font-size: 1.7 *$rem;
      font-family: RuiZiZhenYan;
      font-weight: 300;
      color: #081C0D;
      letter-spacing: 10px;
    }

    .btn_baoming_title0_hui {
      font-size: 1.7 *$rem;
      font-family: RuiZiZhenYan;
      font-weight: 300;
      color: #55745F;
      letter-spacing: 10px;

    }

    .btn_baoming_title1 {
      margin-top: 0.4 *$rem;
      font-size: 1.0 *$rem;
      color: #081C0D;
    }

    .btn_baoming_title1_hui {
      margin-top: 0.4 *$rem;
      font-size: 1.0 *$rem;
      color: #55745F;
    }
  }
}

.go_login_des {
  width: 100%;
  height: 37px;
  margin-top: 0.8 *$rem;
  text-align: center;
  font-size: 1.3 *$rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #5AF8AB;
  //line-height: 37px;
  letter-spacing: 2px;
}

.not_baoming_des {
  width: 100%;

  margin-top: 0.8 *$rem;
  text-align: center;
  font-size: 1.3 *$rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FF4E00;
  letter-spacing: 2px;
}

.btn_baoming_zhongqianCount {
  width: 100%;
  height: 1.5 *$rem;
  font-size: 1.3 *$rem;
  color: #FF4E00;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 8px;
}

.countdown_time_father {
  width: 100%;
  height: 1.8 *$rem;
  //background-color: yellow;
  margin-top: 0.3 *$rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .time_span {
    color: #FF4E00;
    font-size: 1.3 *$rem;
    letter-spacing: 2px;
  }

  .time_span_des {
    color: #8EF4B1;
    font-size: 1.3 *$rem;
    letter-spacing: 2px;
  }
}
