@import 'scss/variables.scss';

.container {
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .headers {
        width:$rem*37.5;
        height:$rem*17.8
    }

    .agreeWrap {
        margin-top: $rem*1.85;
        color: $lightGray;
        display: flex;
        align-items: flex-start;

        .agreeIcon {
            display: block;
            width: $rem*1.3;
            height: $rem*1.3;
            border-radius: $rem*1.3;
            border: 1px solid #D4BA77;
            background: #D4BA77 url('images/login-protocol-selected@2x.png') no-repeat;
            background-size: 100% 100%;
            margin-top: $rem*0.2;

            &.notAgreeIcon {
                background-color: white;
                border: 1px solid #D4BA77;
            }
        }

        p {
            margin-left: $rem*0.3;
            line-height: 1.7*$rem;
            font-size: 1.2*$rem;

            flex: 1;
        }
        .a {
            color: #999999;
        }
        .active {
            color: #CDAC5B;
        }
    }
}

.sms {
    @include lightButton($width:$rem*8,
        $height:$rem*2.7,
        $fontSize:$rem*1.2);

}
.sms2 {
    @include lightButton($width:$rem*10.4,
    $height:$rem*2.7,
    $fontSize:$rem*1.2);

}

.button {
    @include deepButton($width:$rem*32.5,
        $height:$rem*4.5,
        $fontSize:$rem*1.8);
    margin-top: $rem*4.15;
    &.disable {
        opacity: 0.5;
    }
}

.form {
    padding: 0.5*$rem $rem*2.45;
}

.inputWrap {
    padding: $rem*3 0 $rem*0.85;
    border-bottom: $rem*0.05 solid #DBDBDB;

    p {
        line-height: 1.65;

        label {
            color: $lightGray;
            font-size: $rem*1.2;
        }
    }

    .inputFlex {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.45*$rem;

        .input {
            flex: 1;
            display: flex;

            input {
                font-size: $rem*1.9;
                flex: 1;
                color: $deepGray;
                font-weight: bold;
            }
        }

    }


}
