@import 'scss/variables.scss';

.container {
  width: 100%;
  min-height: 100%;
  background-color: #092010;
  padding-bottom: 3 *$rem;
  //box-sizing: border-box;


  .header_component {
    //background-color: #FFF2C5;
    position: relative;
    //background-color: red;

    .img_bg {
      width: 100%;
      height: 100%;
    }

    .back_share_father {
      position: fixed;
      top: 3.8*$rem;
      width: 100%;
      height: 3 *$rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      .back_btn {
        width: 1.75*$rem;
        height: 1.75*$rem;
        background: url(../../../images/icon/back@2x.png) no-repeat;
        background-size: 100%;
        margin-left: 1.5 *$rem;
      }

      .share {
        width: 2.2*$rem;
        height: 2.2*$rem;
        background-size: 100%;
        margin-right: 1.8 *$rem;
      }
    }

    .rule_shiming_father {
      position: absolute;
      top: 7.3*$rem;
      width: 100%;
      height: 4 *$rem;
      //background-color: purple;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .huodong_father {
        //background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 7 *$rem;
        height: 100%;
        position: relative;

        img {
          width: 100%;
          height: 3 *$rem;
        }

        .title {
          position: absolute;
          width: 100%;
          height: 3 *$rem;
          line-height: 3 *$rem;
          font-size: 1.2 *$rem;
          text-align: center;
          margin-left: -0.3 *$rem;
          margin-top: 0.1 *$rem;
          vertical-align: center;

        }

        .title_shiming {
          position: absolute;
          width: 100%;
          height: 3 *$rem;
          line-height: 3 *$rem;
          font-size: 1.2 *$rem;
          text-align: center;
          margin-left: 0.3 *$rem;
          margin-top: 0.1 *$rem;
          vertical-align: center;
        }
      }
    }
  }

  .fashou_time {
    width: 100%;
    height: 2 *$rem;
    display: block;
    font-family: PingFangSC-Semibold;
    font-size: 1.5 *$rem;
    font-weight: normal;
    margin-top: -1.8*$rem;
    letter-spacing: 0em;
    color: #F38E00;
    text-align: center;
  }

  .content_father {
    width: 100%;
    //background-color: purple;
    margin-top: -0.5 *$rem;

    .item_container {
      position: relative;
      margin-top: 1.2 *$rem;
      width: 44vw;
      height: 24.5 *$rem;
      margin-left: 4vw;
      background: #0D5037;
      border-radius: 6px;
      overflow: hidden;
      display: inline-block;
      .item_img{
        width: 38vw;
        height: 14 *$rem;
        background-color: white;
        margin-left: 3vw;
        margin-top: 2vw;
        padding-left: 1.5 *$rem;
        padding-right: 1.5 *$rem;
        object-fit: contain;
      }
      .item_name{
        width: 90%;
        height: 1.8 *$rem;
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        font-weight: normal;
        color: #8EF4B1;
        margin-top: 1.2 *$rem;
        margin-left: 3vw;
      }
      .item_money{
        width: 90%;
        font-family: PingFangSC-Regular;
        font-size: 1.2 *$rem;
        color: #FF5E28;
        margin-top: 0.2 *$rem;
        margin-left: 3vw;
      }
    }
    .btn_father{
      width: 100%;
      height: 3.2 *$rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.2 *$rem;

      .btn_father0{
        position: relative;
        //width: 14 *$rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        //background-color: red;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }


        .goBuy_title_father{
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .goBuy_title_liang{
          font-family: RuiZiZhenYan;
          font-size: 1.5 *$rem;
          font-weight: normal;
          letter-spacing: 0.15em;
          color: #081C0D;
        }
        .goBuy_title_hui{
          font-family: RuiZiZhenYan;
          font-size: 1.3 *$rem;
          font-weight: normal;
          letter-spacing: 0.15em;
          color: #55745F;
        }
      }
    }
  }

  .tag_father{
    position: absolute;
    top: 2 *$rem;
    left: 3vw;
    width: 38vw;
    height: 2 *$rem;
    z-index: 1;
    display: flex;
    align-items: center;

    img{
      width: 6 *$rem;
      height: 1.65 *$rem;
    }
    span{
      position: absolute;
      font-family: SourceHanSansCN-Bold;
      font-size: 1 *$rem;
      font-weight: bold;
      letter-spacing: 0.05em;
      color: #3D3D3D;
      margin-left: 0.8 *$rem;
    }
  }

  .logo_father {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6.7 *$rem;
  }

  .egg_logo {
    width: 15 *$rem;
    height: 2.5 *$rem;
  }
}


