@import 'scss/variables.scss';
.container {
  width: 100%;
  background: #303EAF;
  position: relative;
  z-index: 1;
  .content {
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    .icon {
      width: 37.5*$rem;
      height: 37.8*$rem;
      background: url("./images/home_top.png");
      background-size: 100% 100%;
    }
    .swiper {
      width: 34 * $rem;
      height: 18.7 * $rem;
      overflow-x: visible !important;
      .imgBg {
        width: 34 * $rem;
        height: 18.7 *$rem;
        display: flex;
        justify-content: center;
        position: relative;
      }
      .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 34 * $rem;
        height: 18.7 *$rem;
      }
      .level {
        z-index: 2;
        margin-top: 0.9*$rem;
        width: 8.9*$rem;
        height: 2.1*$rem;
      }
    }
    .dotContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 34.5 * $rem;
      height: 0.5 *$rem;
      .dot {
        width: 0.5 *$rem;
        height: 0.5 *$rem;
        background: rgba(255,255,255,0.6);
        margin-right: 0.5 *$rem;
        border-radius: 0.25 *$rem;
        &.active {
          background: #ffffff;
          width: $rem;
        }
      }
    }

    .icon7 {
      width: 35.2 *$rem;
      height: 10.8 *$rem;
      background:url("./images/icon7.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      .ruleText {
        font-size: 1.2*$rem;
        height: 1.65*$rem;
        position: absolute;
        right: 1.25*$rem;
        bottom: 0.5*$rem;
        color: #ffffff;
      }
    }

    .part1Bg {
      width: 35.2*$rem;
      background: #851209;
      border-radius: 0.55*$rem;
      padding-top: 0.7*$rem;
      .part1 {
        width: 35.2*$rem;
        background: linear-gradient(180deg, #FF7A6D 0%, #F76159 100%);
        border-radius: 0.55*$rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 2*$rem;

        .dateTime {
          margin-top: 0.4*$rem;
          font-size: 1.2*$rem;
          color: #FFFFFF;
          line-height: 1.65*$rem;
          text-shadow: 0 0.1*$rem 0.2*$rem rgba(0, 0, 0, 0.5);
        }

        .couponList {
          margin-top: 0.75*$rem;
          width: 100%;
          height: 13.95*$rem;
          display: flex;
          overflow-x: scroll;
          padding-right: 0.5*$rem;
          &::-webkit-scrollbar {
            display: none;
          }
          .img {
            width: 9.8*$rem;
            height: 13.95*$rem;
            margin-left: 0.5*$rem;
          }
        }
        .icon8 {
          margin-top: 0.95*$rem;
          width: 34.3 *$rem;
          height: 20.6*$rem;
          background: url("./images/icon8.png") no-repeat;
          background-size: 100% 100%;
        }
        .goods {
          margin-top: 0.9*$rem;
          width: 34.1*$rem;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .item {
            width: 16.6*$rem;
            height: 19.7*$rem;
            margin-bottom: $rem;
          }
        }
        .button {
          margin-top: 0.5*$rem;
          width: 14*$rem;
          height: 3.8*$rem;
          background: linear-gradient(180deg, #FFFBD5 0%, #FFD300 100%);
          box-shadow: 0 0.2*$rem 0 0 #FAA500;
          border-radius: 2*$rem;
          font-size: 1.8*$rem;
          font-weight: bold;
          color: #F34F00;
          line-height: 2.65*$rem;
          text-shadow: 0 0.05*$rem 0 #FFFFFF;
        }
      }
    }

    .icon9 {
      margin-top: $rem;
      width: 35.2*$rem;
      height: 10.8*$rem;
      background: url("./images/icon9.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .btnMyGift {
        font-size: 1.2*$rem;
        height: 1.65*$rem;
        position: absolute;
        right: 7.6*$rem;
        bottom: 0.5*$rem;
        color: #ffffff;
      }

      .btnRule {
        font-size: 1.2*$rem;
        height: 1.65*$rem;
        position: absolute;
        right: 1.3*$rem;
        bottom: 0.5*$rem;
        color: #ffffff;
      }
    }

    .part2Bg {
      width: 35.2*$rem;
      background: #48087D;
      border-radius: 0.55*$rem;
      padding-top: 0.7*$rem;
      .part2 {
        width: 35.2*$rem;
        border-radius: 0.55*$rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 1.8*$rem;
        background: linear-gradient(180deg, #B752F8 0%, #763FE9 100%);
        box-shadow: 0 0.25*$rem 0.15*$rem 0 #DE77F9;

        .dateTime {
          margin-top: 0.5*$rem;
          margin-bottom: 0.2*$rem;
          font-size: 1.2*$rem;
          color: #FFFFFF;
          line-height: 1.65*$rem;
          text-shadow: 0 0.1*$rem 0.2*$rem rgba(0, 0, 0, 0.5);
        }

        .blindBox {
          width: 35.2*$rem;
          height: 41.35*$rem;
          position: relative;
          background: url("./images/icon10.png");
          background-size: 100% 100%;
          .title {
            position: absolute;
            left: 0;
            width: 100%;
            height: 1.7*$rem;
            top: 2.2*$rem;
            color: #ffffff;
            font-size: 1.15*$rem;
            font-weight: bold;
            line-height: 1.7*$rem;
            text-align: center;
          }

          .giftBg {
            width: 24.5*$rem;
            height: 10.3*$rem;
            position: absolute;
            left: 4.65*$rem;
            top: 7.8*$rem;
            overflow: hidden;
            @keyframes blindBox {
              from{ margin-left:0;}
              to {margin-left:-8.25*$rem;}
            }
            .gift {
              display: flex;
              width: 40*$rem;
              height: 10.3*$rem;
              animation: blindBox 1s infinite linear;
            }
            .giftItem {
              width: 8.25*$rem;
              height: 10.3*$rem;
              background: url("./images/box.png") no-repeat;
              background-size: 100% 100%;
            }
          }

          .btnOpenBg {
            position: absolute;
            left: 7.7*$rem;
            width: 18.8*$rem;
            height: 5*$rem;
            top: 19.55*$rem;
            overflow: hidden;
          }

          @keyframes btnAnimated {
            0%{ margin-top:0;}
            50% {margin-top: 0.9*$rem;}
            100% {margin-top:0;}
          }

          .btnOpen {
            width: 18.8*$rem;
            height: 5.9*$rem;
            background: url("./images/box_button.png") no-repeat;
            background-size: 100% 100%;
            animation: btnAnimated 0.5s infinite linear;
            &.disable {
              animation: none;
            }
            .boxText {
              width: 18.8*$rem;
              height: 5.9*$rem;
              color: #ffffff;
              font-size: 1.85*$rem;
              font-weight: 500;
              line-height: 3.5*$rem;
              text-align: center;
              transform: rotate3d(1,0,0,30deg);
            }
          }
          .img {
            width: 35.2*$rem;
            height: 41.35*$rem;
          }

          .title2 {
            position: absolute;
            top: 29.05*$rem;
            left: 0;
            width: 35.2*$rem;
            height: 2.1*$rem;
            font-size: 1.6*$rem;
            font-weight: 500;
            color: #4C290A;
            line-height: 2.1*$rem;
            text-align: center;
          }
          .giftListBg {
            position: absolute;
            top: 32.3*$rem;
            left: 2.45*$rem;
            width: 28.8*$rem;
            height: 7.15*$rem;
            .giftList {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              .itemImg {
                width: 6.75*$rem;
                height: 7.15*$rem;
                margin-right: 0.6*$rem;
                &.last {
                  margin-right: 0;
                }
              }
            }

          }
        }
        .inviteBg {
          margin-top: 2.8*$rem;
          width: 32.5*$rem;
          background: linear-gradient(181deg, #FFFEFF 0%, #E8CBFF 100%);
          box-shadow: 0 0.25*$rem 0 0 #A36FCA;
          border-radius: 0.6*$rem;
          overflow: visible;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            position: absolute;
            top: -1.4*$rem;
            left: 2.15*$rem;
            background: url("./images/icon12.png");
            background-size: 100% 100%;
            width: 27.25*$rem;
            height: 2.8*$rem;
            text-align: center;
            font-size: 1.5*$rem;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 2.8*$rem;
          }
          .text {
            margin-top: 2.1*$rem;
            margin-bottom: 0.5*$rem;
            font-size: 1.1*$rem;
            color: #9B9B9B;
            line-height: 1.5*$rem;
          }
          .text2 {
            font-size: 1.2*$rem;
            color: #545454;
            font-weight: bold;
            line-height: 1.65*$rem;
            margin-bottom: $rem;
          }

          .inviteView {
            width: 100%;
            height: 6*$rem;
            display: flex;
            align-items: center;
            .item {
              flex:1;
              display: flex;
              flex-direction: column;
              align-items: center;
              .circle {
                width: 4*$rem;
                height: 4*$rem;
                background: #FFFFFF;
                border: 0.1*$rem dotted #FF8030;
                border-radius: 2*$rem;
                font-size: 1.4*$rem;
                text-align: center;
                line-height: 3.5*$rem;
                color: #FF933D;
              }
              .textPhone {
                margin-top: 0.5*$rem;
                font-size: 0.9*$rem;
                color: #9B9B9B;
                line-height: 1.25*$rem;
                &.phone {
                  color: #3a3a3a;
                }
              }
              .headImg {
                width: 4*$rem;
                height: 4*$rem;
                border-radius: 2*$rem;
                background:red;
              }
            }
          }
          .buttonInvite {
            margin-top: $rem;
            margin-bottom: 2.5*$rem;
            width: 16.4*$rem;
            height: 3.7*$rem;
            background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
            box-shadow: 0 0.1*$rem 0.4*$rem 0 #E87E53;
            border-radius: 1.85*$rem;
            font-size: 1.6*$rem;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 3.6*$rem;
          }
          .bottomIcon {
            width: 32.5*$rem;
            height: 6.6*$rem;
            background: url("./images/icon13.png");
            background-size: 100% 100%;
          }

          .viewBg {
            margin-top: 2.85*$rem;
            margin-bottom: 2.4*$rem;
            display: flex;
            width: 100%;
            height: 10.65*$rem;
            padding:0 0.75*$rem;
            align-items: center;
            justify-content: space-between;
            .item1 {
              width: 15.05*$rem;
              height: 10.65*$rem;
              background: url("./images/icon14.png");
              background-size: 100% 100%;
              position: relative;
            }

            .item2 {
              width: 15.05*$rem;
              height: 10.65*$rem;
              background: url("./images/icon15.png");
              background-size: 100% 100%;
              position: relative;
            }

            .button1 {
              position: absolute;
              left: 1.25*$rem;
              bottom: 1.1*$rem;
              width: 5.45*$rem;
              height: 1.7*$rem;
              background: linear-gradient(180deg, #FFC159 0%, #FF7228 100%);
              box-shadow: 0.05*$rem 0.15*$rem 0.4*$rem 0 #FFBE92;
              border-radius: 1.6*$rem;

              font-size: $rem;
              color: #FFFFFF;
              line-height: 1.4*$rem;
            }

            .button2 {
              position: absolute;
              right: 1.25*$rem;
              bottom: 1.1*$rem;
              width: 5.45*$rem;
              height: 1.7*$rem;
              background: linear-gradient(178deg, #D369EE 0%, #8E31E0 100%);
              box-shadow: 0.05*$rem 0.15*$rem 0.4*$rem 0 #AD7AFF;
              border-radius: 1.6*$rem;
              font-size: $rem;
              color: #FFFFFF;
              line-height: 1.4*$rem;
            }
          }
        }
      }
    }

    .part3Bg {
      margin-top: 1.1*$rem;
      width: 35.2*$rem;
      height: 57.3*$rem;
      background: url("./images/icon16.png");
      background-size: 100% 100%;
      position: relative;

      .rule3 {
        width: 4.75*$rem;
        height: 1.65*$rem;
        position: absolute;
        right: 1.3*$rem;
        top: 8.4*$rem;
      }

      .dateTime3 {
        position: absolute;
        top: 11.75*$rem;
        left: 0;
        width: 100%;
        height: 1.65*$rem;
        font-size: 1.2*$rem;
        color: #FFFFFF;
        text-shadow: 0 0.1*$rem 0.2*$rem rgba(0, 0, 0, 0.5);
        line-height: 1.65*$rem;
        text-align: center;
      }
    }

    .exchangeTitle {
      width: 100%;
      height: 2.5*$rem;
      margin: 2* $rem 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .line1 {
        width: 7.8*$rem;
        height: 0.1*$rem;
        background: url("./images/icon17.png");
        background-size: 100% 100%;
      }
      .line2 {
        width: 7.8*$rem;
        height: 0.1*$rem;
        background: url("./images/icon18.png");
        background-size: 100% 100%;
      }

      .title {
        margin: 0 1.6*$rem;
        font-size: 1.8*$rem;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 2.5*$rem;
      }
    }

    .exchangeItemWrap {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: 0.85*$rem;
    }

    .productWrap:nth-child(even) {
      margin-left: 0.8 *$rem;
    }

    .productWrap {
      margin-top: 0.8 *$rem;
      width: 17.5*$rem;
      height: 23.9*$rem;
      background: white;
      border-radius: 0.4 * $rem;
      overflow: hidden;

      .thumbnail {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #EFEBE6;
        height: 17.5*$rem;
        text-align: center;
        position: relative;

        .soldOut {
          position: absolute;
          top: 0;
          left: 0;
          width: $rem*5.5;
          height: $rem*5.5;
        }

        .img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .ItemContent {
        padding: $rem;

        .title {
          color: $deepGray;
          font-size: 1.4*$rem !important;
          font-weight: 500;
          line-height: 1.7*$rem;
          width: 15.5 * $rem;
          height: 1.7 * $rem;
          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .bottomView {
          display: flex;
          align-items: center;
          margin-top: $rem;
          height: 1.7*$rem;

          .text1 {
            font-size: 1.4*$rem;
            font-weight: bold;
            color: #FA6400;
          }

          .unit {
            font-size: $rem;
            margin-left: 0.1*$rem;
            color: #FA6400;
          }

          .text2 {
            margin-left: 0.5*$rem;
            font-size: 1.4*$rem;
            color: #999999;
            text-decoration: line-through;
          }
        }
      }
    }

  }
  .blurBg {
    width: 27.15*$rem;
    position: absolute;
    top: 0;
    left: 5.175*$rem;
    bottom: 0;
    background: #7D9CFF;
    filter: blur(2.5*$rem);
  }

  .gray {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
}
