@import 'scss/variables.scss';

.vip_card_content_container {
  width: 100%;
  margin-top: 2 *$rem;
  padding: 0 4vw 90px;
  //background: red;


  .vip_card_icon {
    width: 94%;
    height: 100%;
    margin-top: 4 *$rem;
  }

  .vip_card_content_offline {
    margin-top: 4 *$rem;
  }

  .vip_card_content_offline_top {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .vip_card_content_line0 {
    width: 5px;
    height: 17px;
    border-radius: 308px;
    opacity: 1;
    /* 水晶icon1 */
    background: linear-gradient(180deg, #D8A348 0%, #E4CBB2 100%);
  }

  .vip_card_content_des { //线下活动 /至尊服务的title
    font-family: PingFang SC;
    font-size: 1.7 *$rem;
    font-weight: normal;
    letter-spacing: 0em;
    margin-left: 8px;
    color: #B2FFF0;

    &.hz {
      color: #F8DC9E;
    }

    &.bj {
      color: #5876A5;
    }

    &.sj {
      color: #CDEEFF;
    }
  }

  .vip_card_content_offline_bottom {
    width: 100%;
    height: 8.5 *$rem;
    margin-top: 2.0*$rem;
    border-radius: 10px;
    //border: 0.001px solid transparent;
    opacity: 1;
    background: linear-gradient(270deg, #9BDDD0 0%, #CBE7E0 69%);

    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    .vip_card_content_offline_logo {
      position: absolute;
      width: 14.0 *$rem;
      height: 9.0 *$rem;
      //background-color: grey;
      object-fit: cover;
      left: 0 *$rem;
      bottom: 0;

    }

    > p {
      margin-left: 10 *$rem;
      font-family: PingFang SC;
      font-size: 1.1 *$rem;
      line-height: 1.8 *$rem;
      font-weight: normal;
      letter-spacing: 0em;
      color: #fff;
      white-space: pre-wrap;
    }

    .vip_card_content_offline_right_btn {
      position: absolute;
      width: 6 *$rem;
      height: 3 *$rem;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.4 *$rem;
      right: 1 *$rem;
      background: linear-gradient(106deg, #D4BA77 5%, #FFEAB4 96%);
      color: #5F4124;
    }
  }

  //至尊服务
  .vip_card_content_server {
    margin-top: 2.5 *$rem;

    .vip_card_content_server_container {
      margin-top: 0.8 *$rem;
      min-height: 5 *$rem;
      //background-color: #fff;
      border-radius: 10px;
      border: 0.1px solid transparent;
      display: flex;
      flex-wrap: wrap;

      .vip_card_content_server_item {
        width: 43.6vw;
        height: 8.8 *$rem;
        margin-top: 1.2  *$rem;
        border-radius: 10px;
        border: 0.1px solid transparent;
        //background: linear-gradient(270deg, #00332C 0%, #007469 85%);
        position: relative;

        .vip_card_server_item_img {
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }

        .vip_card_content{
          width:100%;
          height:100%;
          position:absolute;
          left:0;
          top:0;
        }

        .vip_card_server_item_title {
          width: 55%;
          margin-left: 1 *$rem;
          margin-top: 1.3 *$rem;
          font-family: PingFang SC;
          font-size: 1.3 *$rem;
          line-height: 1.5 *$rem;
          font-weight: normal;
          color: #FFFFFF;
        }

        .vip_card_server_item_des {
          width: 55%;
          opacity: 0.7;
          margin-left: 1 *$rem;
          margin-top: 0.8 *$rem;
          font-family: PingFang SC;
          font-size: 1 *$rem;
          font-weight: normal;
          line-height: 1.4 *$rem;
          letter-spacing: 0em;
          color: #FFFFFF;
        }
      }
    }
  }

  //其他
  .vip_card_content_other {
    margin-top: 2.5 *$rem;

    .vip_card_content_other_container {
      margin-top: 1.5 *$rem;
      min-height: 5 *$rem;
      //background-color: #fff;
      border-radius: 10px;
      border: 0.1px solid transparent;
      display: flex;
      flex-wrap: wrap;

      .vip_card_content_other_item_container {
        width: 43.6vw;
        //height: 12 *$rem;
        margin-top: 1.2  *$rem;
        //background-color: purple;
        display: flex;
        flex-direction: column;
        align-items: center;

        .vip_card_content_other_item_img {
          width: 100%;
          height: 8.8 *$rem;
          border-radius: 10px;
          object-fit: cover;
        }

        > p {
          font-family: PingFang SC;
          font-size: 1.2 *$rem;
          font-weight: normal;
          margin-top: 0.7 *$rem;
          color: #FFFFFF;
        }
      }
    }
  }
}
