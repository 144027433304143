@import 'scss/variables.scss';
.activity_page {
    height: 100vh;
    position: relative;
    img {
        width: 100%;
        //height: 100%;
    }
    .btn_box {
        position: fixed;
        left: 50%;
        bottom: 9*$rem;
        transform: translateX(-50%);
        height: 50px;
        width: 308px;
    }
    .mask {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background: rgba(0, 0, 0, 0.5);
    }
}
