@import 'scss/variables.scss';

.root {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #F8F8F8;

  .container {
    width: 100%;
    flex: 1;

    .listContainer {
      margin: 30 / 20*$rem ;

      .itemContainer {
        margin-bottom: 20 / 20*$rem ;
        background-color: #FFFFFF;
        border-radius: 16 / 20*$rem;
        padding: 30 / 20*$rem;
        min-height: 130 / 20*$rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .itemLeft {
          display: flex;
          flex-direction: column;

          .itemTop {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .itemTopTitle {
              color: #333333;
              font-size: 28 / 20*$rem;
              line-height: 48 / 20*$rem;
              font-weight: bold;
            }

            .itemTopSubTitle {
              margin-left: 12  / 20*$rem;
              color: #5D5D5D;
              font-size: 20 / 20*$rem;
              line-height: 40 / 20*$rem;
            }
          }

          .itemTime {
            color: #333333;
            font-size: 20 / 20*$rem;
            line-height: 40 / 20*$rem;
          }
        }

        .itemRight {
          color: #C70003;
          font-size: 50 / 20*$rem;
          line-height: 70 / 20*$rem;
          font-weight: 500;
        }
      }
    }
  }
}