@import 'scss/variables.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0,0,0,0.7);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  .contentBg {
    width: 37.5 * $rem;
    height: 23.75 * $rem;
    background: #ffffff;
    position: relative;
    .btnClose {
      width: 2.2 * $rem;
      height: 2.2 * $rem;
      background: url("./imgs/icon_quxiao.png") no-repeat;
      background-size: 100%;
      position: absolute;
      right: 1.2 * $rem;
      top: 0.85 * $rem;
    }

    .wrap {
      width: 37.5 * $rem;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .gift {
        margin-top: 3*$rem;
        width: 6.65*$rem;
        height: 7.3*$rem;
        background: url("./imgs/icon_duihuan.png") no-repeat;
        background-size: 100% 100%;
      }
      .text {
        font-size: 1.75*$rem;
        font-weight: bold;
        color: #1A1A1A;
        line-height: 2.5*$rem;
        margin-top: 0.5*$rem;
        margin-bottom: 2.75*$rem;
      }

      .bottom {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 0 2*$rem;
        .btn1 {
          width: 15 *$rem;
          height: 3.5*$rem;
          background: linear-gradient(98deg, #D4BA77 4%, #FFEAB4 99%);
          font-size: 1.5 * $rem;
          color: #7E5B23;
          border-radius: 1.75 * $rem;
          font-weight: bold;
        }
        .btn2 {
          height: 3.5*$rem;
          width: 15 *$rem;
          font-size: 1.5 * $rem;
          color: #7E5B23;
          font-weight: bold;
          background: linear-gradient(98deg, #D4BA77 4%, #FFEAB4 99%);
          border-radius: 1.75*$rem;
        }
      }
    }
  }
}
