@import 'scss/variables.scss';
.container{
  width: 33.5 * $rem;
  background: #FFFFFF;
  border-radius: 0.5 * $rem;
  padding-bottom: $rem;
  .header {
    width: 100%;
    height: 7 * $rem;
    border-bottom: 0.05 * $rem solid #D1D1D1;
    .monthHeader {
      width: 100%;
      height: 4.7 * $rem;
      display: flex;
      align-items: center;
      padding: 0 1.5 * $rem;
      .btnPrevious {
        display: flex;
        align-items: center;
        .imgBack {
          width: 0.7 * $rem;
          height: 0.7 * $rem;
          margin-right: 0.7 * $rem;
        }
      }
      .btnNext {
        display: flex;
        align-items: center;
        .imgNext {
          width: 0.7 * $rem;
          height: 0.7 * $rem;
          margin-left: 0.7 * $rem;
        }
      }
      .btnText {
        font-size: 1.2 * $rem;
        color: #333333;
        &.disable {
          color: #999999
        }
      }

      .monthText {
        flex:1;
        font-size: 1.4 * $rem;
        color: #333333;
        text-align: center;
      }

    }
    .weekHeader {
      width: 100%;
      height: 2.3 * $rem;
      display: flex;
      .weekText {
        font-size: 1.2 * $rem;
        color: #333333;
        flex:1;
        text-align: center;
        &.disable {
          color: #999999;
        }
      }
    }
  }
  .dateText {
    font-size: 1.2 * $rem;
    color: #999999;
    margin-top: 0.8 * $rem;
    margin-left: 1.5 * $rem;
  }

  .dayWrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    .day {
      width: 14%;
      height: 4.1 * $rem;
      margin-top: 0.7 * $rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        width: 2.9 * $rem;
        height: 2.9 * $rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .dayText {
          font-size: 1.6 * $rem;
          color: #999999;
          &.select {
            color: #ffffff;
            font-weight: 600;
          }
          &.enable {
            color: #333333;
            font-weight: 600;
          }
        }
        &.select {
          border-radius: 1.45* $rem;
          background: #641F6D;
        }
        &.enable {
          border-radius: 1.45* $rem;
          background: rgba(100, 31, 109, 0.1);
        }
        &.disable {
          border-radius: 1.45* $rem;
          background: rgba(180, 180, 180, 0.1);
        }
      }

      .tip {
        margin-top: 0.2 * $rem;
        font-size: $rem;
        color: #333333;
      }
      &:nth-child(7n) {
        width: 15%;
      }
      &:nth-child(7n+1) {
        width: 15%;
      }
    }
  }
}
